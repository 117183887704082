import React from "react";
import { NavLink } from "react-router-dom";
import ProfileImage from "../../Assets/Images/User-avatar.png";
import { useAuth } from "../../authentication";
import { baseURL } from "../../util/Api";
const ProfileDropdown = () => {
  const { userSignOut, authUser } = useAuth();
  return (
    <>
      <ul className="navbar-nav ms-auto  align-items-center">
        <li className="nav-item dropdown ">
          <NavLink
            className="nav-link  dropdown-toggle"
            // to="javascript:void();"
            data-bs-toggle="dropdown"
          >
            {" "}
            {authUser && authUser?.profile?.src !== "" ? (
              <img
                src={baseURL + "files/get-profile/" + authUser?.profile?.src}
                className="profile-img"
                alt="img"
              />
            ) : (
              <img src={ProfileImage} className="profile-img" alt="img" />
            )}
            <span className="d-none d-lg-inline-block">
              Hi, {authUser && authUser?.firstName}
            </span>{" "}
          </NavLink>
          <ul className="dropdown-menu fade-up dropdown-menu-end">
            <li>
              <NavLink className="dropdown-item" to="/profile">
                Profile
              </NavLink>
            </li>
            {authUser?.role === "Crew Member" ||
            (authUser?.role === "Club Member" &&
              authUser?.role === "member") ? (
              <li>
                <NavLink className="dropdown-item" to="/vendor-benefits">
                  Member Benefits
                </NavLink>
              </li>
            ) : null}

            <li>
              <NavLink className="dropdown-item" to="/liked-vehicles">
                My watchlist
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/listed-vehicle">
                Listed Vehicles
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" onClick={userSignOut}>
                Logout
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
};

export default ProfileDropdown;
