import React, { useState } from "react";
import { Modal, Form, Input, Button, Divider, Spin, message } from "antd";
import { PatternFormat } from "react-number-format";
import { useAuth } from "../../authentication";

function ReSubscribeModal({ visible, onCancel, onOk, membershipAmount }) {
  const [loading, setLoading] = useState(false);
  const [cardLength, setCardLength] = useState(16);
  const [form] = Form.useForm();
  const { authUser } = useAuth();
  function clearForm() {
    form.resetFields();
  }
  const laodingFunctions = {
    start: () => {
      setLoading(true);
    },
    success: () => {
      setLoading(false);
    },
    error: (error) => {
      setLoading(false);
      message.error("Please use valid payment source.");
    },
  };

  const validatePostalCode = (value, country) => {
    if (country === "Canada") {
      return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value); // Canadian postal code format
    } else if (country === "United States") {
      return /^\d{5}(-\d{4})?$/.test(value); // U.S. ZIP code format
    }
    return true; // For other countries
  };

  const handleOk = async () => {
    try {
      await form.validateFields().then((values) => {
        if (
          (authUser?.country === "Canada" || values.country === "Canada") &&
          values.zip.length === 7
        ) {
          const digits = values.zip.replace(/\D/g, "");

          if (digits.length === 3) {
            const modifiedZip = `${digits}00`;
            values.zip = modifiedZip;
          }
        }
        onOk(values, clearForm, laodingFunctions);
      });
    } catch (errorInfo) {}
  };

  return (
    <Spin tip="Loading..." spinning={loading} delay={200} size="large">
      <Modal
        title="Card Payment"
        visible={visible}
        onCancel={onCancel}
        onOk={handleOk}
        footer={null}
      >
        {membershipAmount && (
          <div>
            <b>Amount: </b>$ {membershipAmount}
          </div>
        )}
        <Form form={form} layout="vertical">
          <Form.Item
            label="Cardholder Name"
            name="cardholder"
            rules={[
              {
                required: true,
                message: "Please enter the cardholder name",
              },
            ]}
          >
            <Input placeholder="Card Holder Name" />
          </Form.Item>
          <Form.Item
            label="Card Number"
            name="cardNumber"
            rules={[
              { required: true, message: "Please enter the card number" },
              {
                validator: (_, value) => {
                  const cardNumber = value.replace(/\s+/g, ""); // Remove spaces

                  if (cardNumber.length === 15 || cardNumber.length === 16) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Card number must be 15 or 16 digits")
                  );
                },
              },
            ]}
          >
            <PatternFormat
              placeholder="0000 0000 0000 0000"
              type="tel"
              className="ant-input"
              format="#### #### #### ####"
              // mask="_"
              onValueChange={(values) => {
                const { value } = values;
                const digitsOnly = value.replace(/\D/g, "");

                // Update card length state
                setCardLength(digitsOnly.length);

                // Prevent input beyond 16 digits
                if (digitsOnly.length > 16) {
                  const inputField = document.querySelector(
                    'input[name="cardNumber"]'
                  );
                  inputField.value = inputField.value.slice(0, 19); // Adjust to trim any extra input
                }
              }}
            />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              style={{ width: "48%" }}
              label="Expiration Date"
              name="expiry"
              rules={[
                {
                  required: true,
                  message: "Please enter the expiration date",
                },
              ]}
            >
              <PatternFormat
                placeholder="MM/YY"
                type="tel"
                className="ant-input"
                format="##/##"
                mask="_"
              />
            </Form.Item>

            <Form.Item
              style={{ width: "48%" }}
              label="CVC"
              name="cvc"
              rules={[
                {
                  required: true,
                  message: `Please enter the ${
                    cardLength === 15 ? "4" : "3"
                  }-digit CVC`,
                },
              ]}
            >
              <PatternFormat
                placeholder={cardLength === 15 ? "0000" : "000"}
                type="tel"
                className="ant-input"
                format={cardLength === 15 ? "####" : "###"}
                mask="_"
              />
            </Form.Item>
          </div>

          <Form.Item
            label="ZIP Code"
            name="zip"
            rules={[{ required: true, message: "Please enter the ZIP code" }]}
          >
            <Input
              type="text"
              className="ant-input"
              placeholder={
                authUser?.country === "Canada"
                  ? "A1A 1A1" // Placeholder to guide the format
                  : "12345"
              }
              maxLength={authUser?.country === "Canada" ? 7 : 5}
            />
          </Form.Item>

          <Divider />

          <div style={{ textAlign: "right" }}>
            <Button loading={loading} type="primary" onClick={handleOk}>
              Pay Now
            </Button>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
}

export default ReSubscribeModal;
