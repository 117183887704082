import React, { Component } from "react";
import { Link } from "react-router-dom";
import { WASABI_URL, baseURL } from "../../util/Api";
import ShareWidget from "../ShareWidget";
// import LikeWidget from "../LikeWidget";

export class PartsCard extends Component {
  render() {
    const {
      id,
      noOfLike,
      partName,
      price,
      noOfImage,
      location,
      number,
      condition,
      thumbnail,
      isLiked,
      forSold,
    } = this.props;
    return (
      <>
        <div className="col">
          <Link to={forSold ? "#" : `/part-detail/${id}`}>
            <div className="cd-box">
              <div className="img-box position-relative">
                <div
                  className="cb-img"
                  style={{
                    backgroundImage: `url(${
                      thumbnail?.source === "cloud"
                        ? WASABI_URL + thumbnail?.path
                        : thumbnail?.path
                    })`,
                  }}
                >
                  {!forSold && (
                    <div className="ib-overlay">
                      <div className="d-flex justify-content-between w-100">
                        <div className="text-white d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-images me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                            <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                          </svg>{" "}
                          {noOfImage}
                        </div>
                        <div>
                          {/* <LikeWidget
                            isLiked={isLiked}
                            vehicleId={id}
                            numberOfLikes={noOfLike}
                            className="text-white"
                          /> */}

                          <ShareWidget className="d-inline-block" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="cdb-details">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12  text-start text-md-end ">
                    <div className="bedge d-inline-block">${price}</div>
                  </div>
                  <div className="col-12 col-md-12  mt-2 col-lg-12">
                    {partName}
                  </div>
                </div>
                <div className="mt-3 d-flex align-items-center justify-content-between small text-white">
                  <div className="d-inline-flex align-items-center">
                    {condition && condition == "u"
                      ? "Used"
                      : condition == "n"
                      ? "New"
                      : condition.charAt(0).toUpperCase() + condition.slice(1)}
                  </div>
                  <div
                    className="d-inline-flex align-items-center"
                    style={{ marginRight: "8px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={13}
                      height={13}
                      fill="currentColor"
                      className="bi bi-geo-alt-fill me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                    </svg>{" "}
                    {location}
                  </div>
                </div>
                <hr className="op-1 mt-2 mb-2" />
                <div className="mb-3 d-flex align-items-center justify-content-between small text-white">
                  <div className="d-inline-flex align-items-center">
                    <Link to="tel:123-456-7890">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.316"
                        className="me-1"
                        height="13.312"
                        viewBox="0 0 15.316 15.312"
                      >
                        <path
                          id="phone"
                          d="M11.691,9.619c-1.054-.1-1.629,1.342-2.4,1.725-1.246.671-3.546-1.725-3.546-1.725s-2.4-2.3-1.821-3.546c.479-.767,1.917-1.342,1.821-2.4-.1-.958-2.2-4.409-3.259-3.451C.189,2.334,0,3.2,0,4.922c-.1,2.971,3.738,6.709,3.738,6.709.383.383,3.738,3.834,6.709,3.738,1.725,0,2.588-.192,4.7-2.492.958-1.054-2.4-3.163-3.451-3.259Z"
                          transform="translate(0.004 -0.059)"
                          fill="#d9e4ee"
                        />
                      </svg>
                      {number}
                    </Link>
                  </div>
                  {/* <div className="d-inline-flex align-items-center">
                    {availbility && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-1"
                          width="16.818"
                          height="16.818"
                          viewBox="0 0 21.818 21.818"
                        >
                          <path
                            id="check-verified"
                            d="M5.457,16.091H5.182a2.182,2.182,0,0,1,0-4.364h.275a8.675,8.675,0,0,1,.934-2.252l-.2-.195A2.182,2.182,0,0,1,9.282,6.2l.194.195a8.675,8.675,0,0,1,2.252-.934V5.182a2.182,2.182,0,0,1,4.364,0v.275a8.675,8.675,0,0,1,2.252.934l.195-.2a2.182,2.182,0,1,1,3.085,3.087l-.195.194a8.635,8.635,0,0,1,.934,2.252h.275a2.182,2.182,0,0,1,0,4.364h-.275a8.675,8.675,0,0,1-.934,2.252l.2.195a2.182,2.182,0,1,1-3.087,3.085l-.194-.195a8.675,8.675,0,0,1-2.252.934v.275a2.182,2.182,0,0,1-4.364,0v-.275a8.675,8.675,0,0,1-2.252-.934l-.195.2A2.182,2.182,0,0,1,6.2,18.537l.195-.194A8.675,8.675,0,0,1,5.457,16.091Zm5.18-4.364L8.455,13.909l4.364,4.364,6.546-6.546L17.182,9.546l-4.364,4.364Z"
                            transform="translate(-3 -3)"
                            fill="#d9e4ee"
                            fillRule="evenodd"
                          />
                        </svg>
                        Confirm Availability
                      </>
                    )}
                  </div> */}
                </div>
                {/* <div className="mt-4 mb-1 small text-white">
                  <VehicleExtraInfo
                    data={{
                      videoWalkaround: videoWalkaround,
                      testDrive: testDrive,
                      delivery: delivery,
                    }}
                  />
                </div>
                <div className=" d-flex align-items-center justify-content-between small text-white">
                  <div className="d-inline-flex align-items-center">
                    {onlinePaperWork && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-1"
                          width="13.859"
                          height="13.859"
                          viewBox="0 0 15.859 15.859"
                        >
                          <path
                            id="check-verified"
                            d="M4.786,12.516h-.2a1.586,1.586,0,0,1,0-3.172h.2a6.306,6.306,0,0,1,.679-1.637l-.143-.142A1.586,1.586,0,0,1,7.566,5.323l.141.142a6.306,6.306,0,0,1,1.637-.679v-.2a1.586,1.586,0,0,1,3.172,0v.2a6.306,6.306,0,0,1,1.637.679l.142-.143a1.586,1.586,0,0,1,2.243,2.244l-.142.141a6.277,6.277,0,0,1,.679,1.637h.2a1.586,1.586,0,1,1,0,3.172h-.2a6.306,6.306,0,0,1-.679,1.637l.143.142a1.586,1.586,0,1,1-2.244,2.243l-.141-.142a6.306,6.306,0,0,1-1.637.679v.2a1.586,1.586,0,0,1-3.172,0v-.2a6.306,6.306,0,0,1-1.637-.679l-.142.143a1.586,1.586,0,0,1-2.243-2.244l.142-.141a6.306,6.306,0,0,1-.679-1.637ZM8.551,9.344,6.965,10.93,10.137,14.1l4.758-4.758L13.309,7.758,10.137,10.93Z"
                            transform="translate(-3 -3)"
                            fill="#15f315"
                            fillRule="evenodd"
                          />
                        </svg>
                        Online Paper Work
                      </>
                    )}
                  </div>
                  <div className="d-inline-flex align-items-center">
                    {certification && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11.976"
                          className="me-1"
                          height="15.61"
                          viewBox="0 0 13.976 18.61"
                        >
                          <path
                            id="certificatealt"
                            d="M12.231,6.979l1.745,1.872-2.453.764L12.1,12.1,9.8,11.577l1.854,5.907a1.084,1.084,0,0,1-.509.945l-.945-.945L9.069,18.61a1.14,1.14,0,0,1-.654-.409,1.115,1.115,0,0,1-.254-.718L6.979,13.74,5.816,17.483a1.121,1.121,0,0,1-.254.718,1.134,1.134,0,0,1-.654.409L3.78,17.483l-.945.945a1.084,1.084,0,0,1-.509-.945L4.18,11.577,1.872,12.1l.582-2.49L0,8.851,1.745,6.979,0,5.107l2.453-.745-.582-2.49,2.49.582L5.107,0,6.979,1.745,8.851,0l.764,2.453,2.49-.582-.582,2.49,2.453.745ZM6.988,3.489A3.369,3.369,0,0,0,4.517,4.516,3.369,3.369,0,0,0,3.49,6.988,3.369,3.369,0,0,0,4.517,9.459a3.369,3.369,0,0,0,2.472,1.027A3.369,3.369,0,0,0,9.46,9.459a3.369,3.369,0,0,0,1.027-2.472A3.369,3.369,0,0,0,9.46,4.516,3.369,3.369,0,0,0,6.988,3.489Z"
                            fill="#e94242"
                          />
                        </svg>
                        certification
                      </>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </Link>
        </div>
        <>
          {/* Delete Modal */}
          <div
            className="modal fade"
            id="deleteModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <button
                    type="button"
                    className="btn-close modal-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                  <div className="mb-3 mt-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={56}
                      height={56}
                      fill="currentColor"
                      className="bi bi-x-circle text-danger"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                  <h2 className="text-theme bold h2-title mb-3">
                    Are you sure?
                  </h2>
                  <p>
                    Do you really want to delete these records? This process
                    cannot be undone.
                  </p>
                  <div className=" mt-4 mt-md-4 mb-4">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>{" "}
                    &nbsp;&nbsp;
                    <button type="button" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default PartsCard;
