import React, { Component, useState } from "react";
import { NavLink } from "react-router-dom";
import { VehicleExtraInfo } from "../../util/vehicles";
import { WASABI_URL, baseURL, httpClient } from "../../util/Api";
import ShareWidget from "../ShareWidget";
import { Button, Popconfirm, message } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import PaymentModal from "../Modal/PaymentModal";
import { packages } from "../../Data/packages";
export const ListedCard = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    id,
    noOfLike,
    title,
    price,
    location,
    approved,
    miles,
    thumbnail,
    status,
    isPaid,
    selectedPackage,
    isSold,
    isThumbnail,
    newThumbnail,
  } = props;

  const handleOk = (values, clearForm, paymentLoading) => {
    paymentLoading.start();

    if (values.type === "card" && values.user_role) {
      httpClient
        .post(`vehicles/instant-payment/${id}`, {
          type: values.type,
          payment: { ...values },
        })
        .then(() => {
          props.fetchVehicles();
          setModalVisible(false);
          clearForm();
          window.location.reload();
          paymentLoading.success();
        })
        .catch(() => {
          paymentLoading.error();
          message.error("Payment Faild");
        });
    } else {
      httpClient
        .post(`vehicles/payment/${id}`, {
          type: values.type,
          payment: { ...values },
        })
        .then(() => {
          props.fetchVehicles();
          setModalVisible(false);
          clearForm();
          window.location.reload();
          paymentLoading.success();
        })
        .catch(() => {
          paymentLoading.error();
          message.error("Payment Faild");
        });
    }
  };

  const handleVehicleStatus = (packageId) => {
    httpClient.patch(`vehicles/${packageId}`).then(() => {
      props.fetchVehicles();
    });
  };

  const handleVehicleSoldStatus = (packageId) => {
    if (approved === "Approved") {
      httpClient.patch(`vehicles/sold/${packageId}`).then(() => {
        props.fetchVehicles();
      });
    } else {
      message.error(
        "Vehicle must be approved before it can be marked as sold."
      );
    }
  };

  const sPackage = packages.find((el) => el.type === selectedPackage);

  return (
    <>
      <div className="col-12">
        <div className="profile-cl">
          <div className="row">
            <div className="col-md-4 col-lg-3 ">
              <div
                className="pcl-img"
                style={{
                  backgroundImage: `url(${
                    isThumbnail
                      ? WASABI_URL + newThumbnail
                      : WASABI_URL + thumbnail?.path
                  })`,
                }}
              ></div>
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="pcl-right">
                <div className="row w-100">
                  <div className="col-md-12 col-lg-8  mb-3 mb-lg-0">
                    <NavLink
                      to={`/vehicle-detail/${id}`}
                      className="ft-18 bold"
                    >
                      {title}
                    </NavLink>
                    <br />
                    <div className="bedge d-inline-block mt-2">${price}</div>
                  </div>
                  <div className="col-md-12 col-lg-4 text-start text-lg-end">
                    <div className="d-flex align-items-center justify-content-start justify-content-lg-end">
                      {!isSold ? (
                        <div className="d-inline-flex align-items-center">
                          {approved === "Approved" ? (
                            <Popconfirm
                              title="Are you sure you want to set this vehicle as sold?"
                              onConfirm={() => handleVehicleSoldStatus(id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button className="btn btn-theme-fill btn-small">
                                Set as sold
                              </Button>
                            </Popconfirm>
                          ) : null}
                        </div>
                      ) : (
                        <div className="d-inline-flex align-items-center me-3  text-danger">
                          <b>Sold</b>
                        </div>
                      )}
                      &nbsp;&nbsp;
                      {/* <div className="d-inline-block">
                          <span className="icons-hover">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.037"
                              className="share-icon-white cr"
                              height="19.738"
                              viewBox="0 0 16.037 19.738"
                            >
                              <path
                                id="trash"
                                d="M16.5,6.067h3.7A1.165,1.165,0,0,1,21.437,7.3V8.534H5.4V7.3A1.236,1.236,0,0,1,6.634,6.067h3.7a3.161,3.161,0,0,1,6.168,0Zm-4.934,0h3.7a2,2,0,0,0-3.7,0Zm-4.934,3.7H20.2l-1.11,12.459a1.217,1.217,0,0,1-1.234,1.11H8.977a1.312,1.312,0,0,1-1.234-1.11Z"
                                transform="translate(-5.4 -3.6)"
                                fill="#f15757"
                              />
                            </svg>
                          </span>
                        </div> */}
                      {status ? (
                        <Popconfirm
                          title="Are you sure you want to De-Activate this vehicle?"
                          onConfirm={() => handleVehicleStatus(id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <EyeOutlined
                            style={{ color: "#52c41a", fontSize: "30px" }}
                          />
                        </Popconfirm>
                      ) : (
                        <Popconfirm
                          title="Are you sure you want to Activate this vehicle?"
                          onConfirm={() => handleVehicleStatus(id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <EyeInvisibleOutlined
                            style={{ color: "red", fontSize: "30px" }}
                          />
                        </Popconfirm>
                      )}
                      &nbsp;&nbsp;
                      <ShareWidget className="d-inline-block" />
                    </div>
                  </div>
                </div>
                <div className="w-100 mt-4 row">
                  <div className="col-12">
                    <div className=" small ">
                      <div className="d-inline-flex align-items-center me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={13}
                          height={13}
                          fill="currentColor"
                          className="bi bi-geo-alt-fill me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>{" "}
                        {location}
                      </div>
                      <div className="d-inline-flex align-items-center">
                        <svg
                          className="me-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.412"
                          height="13.412"
                          viewBox="0 0 16.412 13.412"
                        >
                          <path
                            id="dashboard"
                            d="M8.206,0a8.206,8.206,0,1,0,8.206,8.206A8.23,8.23,0,0,0,8.206,0Zm0,2.052A6.155,6.155,0,1,1,2.052,8.206,6.146,6.146,0,0,1,8.206,2.052Zm0,2.052A1.026,1.026,0,1,0,9.232,5.129,1.016,1.016,0,0,0,8.206,4.1ZM4.8,6.155a1.026,1.026,0,0,0-.39,1.723L6.278,9.745a2.083,2.083,0,0,0-.123.513A2.052,2.052,0,1,0,8.206,8.206a2.083,2.083,0,0,0-.513.123L5.826,6.462a1.026,1.026,0,0,0-.9-.328,1.026,1.026,0,0,0-.123,0Zm6.483,0A1.026,1.026,0,1,0,12.309,7.18,1.016,1.016,0,0,0,11.283,6.155Z"
                            fill="#currentColor"
                          />
                        </svg>
                        {miles && miles.toLocaleString()} Miles
                      </div>
                    </div>
                    {/* <p className="small mt-2">
                      {" "}
                      <VehicleExtraInfo
                        data={{
                          videoWalkaround: videoWalkaround,
                          testDrive: testDrive,
                          delivery: delivery,
                        }}
                      />
                    </p> */}
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="d-inline-flex align-items-center me-2">
                        {noOfLike} &nbsp;
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="14"
                          viewBox="0 0 19.099 16.71"
                        >
                          <path
                            id="heart"
                            d="M12.941,21.962a16.791,16.791,0,0,0,8.283-7.307C22.73,11.82,22.942,9,21.6,7.3a5.227,5.227,0,0,0-4.389-2.047,6.971,6.971,0,0,0-4.271,1.718A6.971,6.971,0,0,0,8.67,5.255,5.227,5.227,0,0,0,4.281,7.3C2.94,9,3.152,11.82,4.681,14.655A16.629,16.629,0,0,0,12.941,21.962Z"
                            transform="translate(-3.392 -5.252)"
                            fill="#e94242"
                          />
                        </svg>
                      </div>

                      <div className="d-inline-flex align-items-center me-2">
                        <b>{sPackage?.title}</b>
                      </div>

                      {!isPaid && (
                        <div className="d-inline-flex align-items-center me-2">
                          <Button
                            onClick={() => setModalVisible(true)}
                            className="btn btn-theme-fill btn-small"
                            style={{ padding: "4px 12px", fontSize: "14px" }}
                          >
                            Publish Listing for ${sPackage.amount}
                          </Button>
                        </div>
                      )}

                      <NavLink
                        to={`/list-vehicle-edit/${id}/upload`}
                        className="btn btn-theme-fill btn-small me-2"
                        style={{
                          padding: "4px 12px",
                          fontSize: "14px",
                          borderRadius: "20px",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        Add Images{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          className="me-1"
                          viewBox="0 0 13.987 13.987"
                        >
                          <path
                            id="plus"
                            d="M21.187,8.98v3.228h-5.38v5.38H12.58v-5.38H7.2V8.98h5.38V3.6h3.228V8.98Z"
                            transform="translate(-7.2 -3.6)"
                            fill="#fbfdff"
                          />
                        </svg>
                      </NavLink>

                      <NavLink
                        to={`/list-vehicle-edit/${id}/info`}
                        className="btn btn-theme-fill btn-small me-2"
                        style={{
                          padding: "4px 12px",
                          fontSize: "14px",
                          borderRadius: "20px",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        Edit Information{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          className="ms-1"
                          viewBox="0 0 10.247 10.247"
                        >
                          <path
                            id="edit"
                            d="M4.5,12.609v2.134H6.634l6.3-6.3L10.8,6.313ZM14.58,6.8a.567.567,0,0,0,0-.8L13.248,4.663a.567.567,0,0,0-.8,0L11.4,5.7l2.134,2.134Z"
                            transform="translate(-4.5 -4.496)"
                            fill="#fbfdff"
                          />
                        </svg>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaymentModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleOk}
        forAddVehicle
        amount={sPackage?.amount}
      />
    </>
  );
};

export default ListedCard;
