import axios from "axios";

export const baseURL = `https://opposingcylinders.com/api/`;
export const WASABI_URL = "https://s3.us-east-1.wasabisys.com/vehicle-project/";
// export const baseURL = `http://localhost:8000/api/`;

export const httpClient = axios.create({
  baseURL,

  //YOUR_API_URL HERE
});
