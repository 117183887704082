export async function fetchImageAndGetBlobAndDimensions(imageUrl) {
  try {
    const response = await fetch(imageUrl);

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();

    const dataUrl = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });

    const image = new Image();
    image.src = dataUrl;
    image.crossOrigin = "anonymous";

    await new Promise((resolve, reject) => {
      image.onload = () => resolve();
      image.onerror = (error) => reject(error);
    });

    const width = image.width;
    const height = image.height;

    return {
      blob,
      width,
      height,
    };
  } catch (error) {
    console.error(`Error processing image: ${error.message}`);
    throw new Error(`Error processing image: ${error.message}`);
  }
}
