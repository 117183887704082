import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ProfileDropdown from "../Cards/ProfileDropdown";
import Logo from "../../Assets/Images/logo.png";
import { useAuth } from "../../authentication";
import { baseURL } from "../../util/Api";
import ProfileImage from "../../Assets/Images/User-avatar.png";
// import { NavDropdown } from "react-bootstrap";

const Header = () => {
  const navigate = useNavigate();
  const { userSignOut, authUser } = useAuth();
  const [scrolled, setScrolled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleScroll = () => {
    setScrolled(window.scrollY > 100);
  };

  const handleSearch = () => {
    // Navigate to the other screen with the searchQuery as a query parameter
    navigate(`/browse-vehicles?query=${searchQuery}`);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundColorClass = scrolled ? "scrolled" : "";
  const token = localStorage.getItem("token");

  return (
    <>
      <>
        {/* header*/}
        <div className="headerTOpBox">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <img className="imagelogo" src={Logo} />
            </Link>
            <div className="header-serach-div w-100 me-2">
              <div className="input-icon-div">
                <input
                  type="text"
                  name=""
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control"
                  placeholder="Search"
                />
                <span className="fa fa-search input-icon"></span>
              </div>
              <button onClick={handleSearch} className="Search__Button">
                Search
              </button>
            </div>
          </div>
        </div>
        <div className={`linkbox ${backgroundColorClass} `}>
          <Link to="/" className="link">
            Home
          </Link>
          <div className="dropdown text-white">
            <NavLink className="dropdown-toggle" data-bs-toggle="dropdown">
              VEHICLE & PART LISTINGS
            </NavLink>
            <ul className="dropdown-menu fade-up dropdown-menu-end">
              <li>
                <NavLink className="dropdown-item" to="/browse-vehicles">
                  VEHICLES FOR SALE
                </NavLink>
              </li>

              <li>
                <NavLink className="dropdown-item" to="/browse-parts">
                  PARTS FOR SALE
                </NavLink>
              </li>
            </ul>
          </div>
          <Link to="/list-your-vehicle" className="link">
            LIST A VEHICLE
          </Link>

          <Link to="/membership" className="link">
            MEMBERSHIPS
          </Link>
          <Link to="https://opposingcylinders.myshopify.com/" className="link">
            Apparel
          </Link>
          <Link to="/video" className="link">
            video
          </Link>
          {!token && (
            <Link className="nav-link active" to="/login">
              Login
            </Link>
          )}

          {token && (
            <>
              <div className="dropdown text-white">
                <NavLink className="dropdown-toggle" data-bs-toggle="dropdown">
                  {" "}
                  {authUser && authUser?.profile?.src !== "" ? (
                    <img
                      src={
                        baseURL + "files/get-profile/" + authUser?.profile?.src
                      }
                      className="profile-img"
                      alt="img"
                    />
                  ) : (
                    <img src={ProfileImage} className="profile-img" alt="img" />
                  )}
                  <span className="d-none d-lg-inline-block text-white">
                    Hi, {authUser && authUser?.firstName}
                  </span>{" "}
                </NavLink>
                <ul className="dropdown-menu fade-up dropdown-menu-end">
                  <li>
                    <NavLink className="dropdown-item" to="/profile">
                      Profile
                    </NavLink>
                  </li>
                  {authUser?.role === "Crew Member" ||
                  (authUser?.role === "Club Member" &&
                    authUser?.role === "member") ? (
                    <li>
                      <NavLink className="dropdown-item" to="/vendor-benefits">
                        Member Benefits
                      </NavLink>
                    </li>
                  ) : null}

                  <li>
                    <NavLink className="dropdown-item" to="/liked-vehicles">
                      My watchlist
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/listed-vehicle">
                      Listed Vehicles
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" onClick={userSignOut}>
                      Logout
                    </NavLink>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
        <div className={`header ${backgroundColorClass}`}>
          <nav className="navbar navbar-expand-xl bg-trasnparent">
            <div className="container">
              <NavLink to="/" className="navbar-brand">
                <img src={Logo} className="img-fluid logo" alt="img" />
              </NavLink>
              <div className="">
                <button
                  className="navbar-toggler border-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <input type="checkbox" id="check1" />
                  <label htmlFor="check1" className="menu_toggle">
                    <span />
                    <span />
                    <span />
                  </label>
                </button>
              </div>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                  <li className="nav-item">
                    <NavLink className="nav-link active" to="/">
                      {" "}
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link active" to="/">
                      {" "}
                      <NavLink
                        className="nav-link active"
                        to="/browse-vehicles"
                      >
                        {" "}
                        Browse Vehicles
                      </NavLink>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link active"
                      to="/list-your-vehicle"
                    >
                      {" "}
                      List Vehicle
                    </NavLink>
                  </li>
                  {!token && (
                    <li className="nav-item">
                      <div>
                        <li>
                          <NavLink to="Membership" className="nav-link active">
                            Membership
                          </NavLink>
                        </li>
                      </div>
                    </li>
                  )}

                  <li className="nav-item">
                    <NavLink
                      to="https://opposingcylinders.myshopify.com/"
                      className="nav-link active"
                    >
                      Apparel
                    </NavLink>
                  </li>

                  {/* <li className="nav-item">
                    <NavLink className="nav-link active" to="/">
                      {" "}
                    hop
                    </NavLink>
                  </li> */}
                  {!token && (
                    <li className="nav-item">
                      <NavLink className="nav-link active" to="/join">
                        Join
                      </NavLink>
                    </li>
                  )}
                  {!token && (
                    <li className="nav-item">
                      <NavLink className="nav-link active" to="/login">
                        Login
                      </NavLink>
                    </li>
                  )}
                  <li className="nav-item">
                    <NavLink className="nav-link active" to="/video">
                      {" "}
                      Videos
                    </NavLink>
                  </li>
                </ul>
              </div>
              {token && <ProfileDropdown />}
            </div>
          </nav>
        </div>
      </>
    </>
  );
};

export default Header;
