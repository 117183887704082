// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Center the carousel */
.logo-carousel-wrapper {
    width: 80%; /* Adjust the width to center */
    margin: 0 auto; /* Center it horizontally */
    padding: 30px 0; /* Add some space around the carousel */
  }
  
  .logo-carousel-wrapper img {
    width: 100%; /* Ensure logos are responsive */
    max-height: 150px; /* Set a max height for logos */
    object-fit: contain; /* Maintain the aspect ratio of the logos */
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/membershippackages/hero.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,UAAU,EAAE,+BAA+B;IAC3C,cAAc,EAAE,2BAA2B;IAC3C,eAAe,EAAE,uCAAuC;EAC1D;;EAEA;IACE,WAAW,EAAE,gCAAgC;IAC7C,iBAAiB,EAAE,+BAA+B;IAClD,mBAAmB,EAAE,2CAA2C;EAClE","sourcesContent":["/* Center the carousel */\n.logo-carousel-wrapper {\n    width: 80%; /* Adjust the width to center */\n    margin: 0 auto; /* Center it horizontally */\n    padding: 30px 0; /* Add some space around the carousel */\n  }\n  \n  .logo-carousel-wrapper img {\n    width: 100%; /* Ensure logos are responsive */\n    max-height: 150px; /* Set a max height for logos */\n    object-fit: contain; /* Maintain the aspect ratio of the logos */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
