import React from "react";
import { Modal, Form, Select, Button } from "antd";

const { Option } = Select;

const ShirtModal = ({ visible, onCancel, onOk }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      onOk(values); // Pass the selected T-shirt size back
    });
  };

  return (
    <Modal
      title="Select T-Shirt Size"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      footer={null}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="T-Shirt Size"
          name="shirtSize"
          rules={[
            { required: true, message: "Please select your T-shirt size" },
          ]}
        >
          <Select placeholder="Select T-Shirt Size">
            <Option value="M">Medium</Option>
            <Option value="L">Large</Option>
            <Option value="XL">XL</Option>
          </Select>
        </Form.Item>

        <div style={{ textAlign: "right" }}>
          <Button type="primary" onClick={handleOk}>
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ShirtModal;
