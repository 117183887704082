import React, { Component } from "react";
import ListVehiclesCard from "../../Component/Cards/ListVehiclesCard";
import Car from "../../Assets/Images/icon/icon-001.svg";
import CarHover from "../../Assets/Images/icon/icon-001-w.svg";
import Part from "../../Assets/Images/icon/icon-002.svg";
import PartHover from "../../Assets/Images/icon/icon-002-w.svg";
import Premium from "../../Assets/Images/icon/icon-003.svg";
import PremiumHover from "../../Assets/Images/icon/icon-003-w.svg";
import Plus from "../../Assets/Images/icon/icon-004.svg";
import PlusHover from "../../Assets/Images/icon/icon-004-w.svg";
export class ListYourVehicle extends Component {
  render() {
    const List = [
      {
        src: Car,
        srcHover: CarHover,
        title: "Basic Car Listing",
        to: "/list-vehicle-step?type=basic",
        price: "99.00",
      },
      {
        src: Part,
        srcHover: PartHover,
        title: "Parts Listing",
        to: "/list-parts-add",
        price: "150.00",
      },
      {
        src: Premium,
        srcHover: PremiumHover,
        title: "Premium Car Listing",
        to: "/list-vehicle-step?type=premium",
        price: "150.00",
      },
      {
        src: Plus,
        srcHover: PlusHover,
        title: "Premium Plus Car Listing",
        to: "/list-vehicle-step?type=premium_plus",
        price: "200.00",
      },
    ];
    return (
      <>
        <>
          {/* List Your Vehicle */}
          <div className="list-your-vihicle sec-pad">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h2 className="bold h2-title text-white1">
                    LIST YOUR VEHICLE
                  </h2>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                {List.map((item, index) => (
                  <ListVehiclesCard
                    key={index}
                    src={item.src}
                    srcHover={item.srcHover}
                    title={item.title}
                    to={item.to}
                    price={item.price}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default ListYourVehicle;
