import React, { Component, useEffect, useState } from "react";
import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import VehicleList from "../../Data/VehicleList";
import Car from "../../Assets/Images/car/img1.png";
import SearchIcon from "../../Assets/Images/icon/search.svg";
import { useParams } from "react-router-dom";
import { WASABI_URL, baseURL, httpClient } from "../../util/Api";
import { commentsList } from "../../Data/commentsList";
import { getTimeAgo } from "../../util/time";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LikeWidget from "../../Component/LikeWidget";
import { message, Spin } from "antd";
import MyGallery from "../../Component/LightGallery/LightBox";
import LightBox from "../../Component/LightGallery/LightBox";

import "./styles.css";
import { blobToBase64 } from "../../util/download";
import { uniqueId } from "lodash";
import CustomLoader from "../../Component/CustomLoader/CustomLoader";
import StyledDemo from "../ListVehicleStep/ImageCropper";
import * as clipboard from "clipboard-polyfill";
function VehicleDetail() {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  const [mainLoading, setMainLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [vehicleData, setVehicleData] = useState({});
  const [comments, setComments] = useState([]);
  const [images, setImages] = useState();
  const [initialLoading, setInitialLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState("");

  const fetchComments = () => {
    httpClient.get(`comments/${id}`).then((response) => {
      setComments(response?.data || []);
    });
  };
  const submitComment = () => {
    setLoading(true);
    httpClient.post(`comments`, { vehicleId: id, comment }).then((response) => {
      fetchComments();
      setComment(null);
      setLoading(false);
    });
  };
  useEffect(() => {
    setMainLoading(true);
    httpClient.get(`vehicles/${id}`).then((response) => {
      const vehicle = response.data;
      setVehicleData(vehicle);
      setMainLoading(false);
      const imagesForGallery = [];
      vehicle?.carImagesId?.images.forEach((element) => {
        imagesForGallery.push({
          src: WASABI_URL + element.path,
        });
        setImages(imagesForGallery);
      });
    });
    fetchComments();
  }, [id]);

  const onImageClick = (index) => {
    setOpen(true);
    setIndex(index);
  };
  const handleShareClick = async () => {
    try {
      await clipboard.writeText(window.location.href);
      message.success("URl copied to clipboard");
    } catch {
      console.error("Failed to copy: ");
    }
  };

  const goBack = () => {
    window.history.back();
  };

  // console.log("v data", vehicleData);

  const handleDownload = async (img) => {
    setInitialLoading(true);
    console.log(initialLoading, "thisiiss");
    try {
      const filename = `IMAGE_OPPOSING_${uuidv4()}.jpg`;
      const response = await httpClient.get(
        `${baseURL}files/get-car-image/${img.originalImage}`,
        {
          responseType: "blob",
        }
      );
      await blobToBase64(response.data, filename);
    } catch {
      console.error("Error downloading image");
    } finally {
      setInitialLoading(false);
    }
  };

  return (
    <>
      <PageLayout
        type="website"
        imageWidth="1920"
        url={`https://opposingcylinders.com/vehicle-detail/${id}`}
        title={[vehicleData.year, vehicleData.make, vehicleData.model].join(
          " "
        )}
        description={vehicleData.detail}
        image={`${WASABI_URL}${vehicleData.carImagesId?.images[0]?.key}`}
      >
        <Spin tip="Loading..." spinning={mainLoading} delay={200} size="large">
          <div className="sec-pad pt-0">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="position-relative mb-4">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <button
                        className="cr btn btn-theme-share sv-share-btn"
                        onClick={handleShareClick}
                      >
                        <span
                          className=""
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title=""
                          data-bs-original-title="Share"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            fill="currentColor"
                            className="bi bi-share share-icon-white cr"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                          </svg>
                        </span>
                      </button>
                    </div>

                    <div
                      id="carouselExampleControls"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        {vehicleData?.carImagesId?.images.map((img, i) => (
                          <div
                            className={`carousel-item ${i === 0 && "active"} `}
                            key={i}
                          >
                            <img
                              src={WASABI_URL + img.path}
                              className="d-block vehicleSliderImg"
                              alt="..."
                            />
                            <button
                              className="carousel-control-prev"
                              type="button"
                              data-bs-target="#carouselExampleControls"
                              data-bs-slide="prev"
                              style={{ borderColor: "black" }}
                            >
                              <span
                                className="carousel-control-prev-icon"
                                // aria-hidden="true"
                                style={{ color: "blue!important" }}
                              />
                              <span className="">Previous</span>
                            </button>
                            <button
                              className="carousel-control-next"
                              type="button"
                              data-bs-target="#carouselExampleControls"
                              data-bs-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              />
                              <span className="visually-hidden">Next</span>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-lg-8">
                  <h2 className="h3-title bold mb-2">
                    {[
                      vehicleData.year,
                      vehicleData.make,
                      vehicleData.model,
                    ].join(" ")}
                    {!vehicleData?.isSold && (
                      <LikeWidget
                        isLiked={vehicleData.isLiked}
                        numberOfLikes={vehicleData.likes}
                        vehicleId={id}
                      />
                    )}
                  </h2>
                  <div className=" d-inline-flex align-items-center me-3">
                    {!vehicleData?.isSold && (
                      <div className="d-inline-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-geo-alt-fill me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>{" "}
                        {vehicleData.city}, {""} {vehicleData.state}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 text-start text-md-end mt-4 mt-md-0">
                  <NavLink to="#" className="text-theme">
                    {vehicleData?.isSold ? (
                      <span style={{ color: "grey" }}>SOLD</span>
                    ) : (
                      "Asking"
                    )}
                  </NavLink>
                  <div className="mb-0 h2-title bold">
                    {" "}
                    {vehicleData?.isSold ? "" : "$" + vehicleData.price}
                  </div>
                </div>
              </div>
              <div className="row mt-2 g-4">
                <div className="col-md-12 col-lg-6">
                  <h3 className="h3-title">Description</h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: vehicleData.detail }}
                  ></p>
                </div>
                <div className="col-md-12 col-lg-6"></div>
                <div className="col-md-12 col-lg-6">
                  <h3 className="h3-title">More Information</h3>
                  {!vehicleData?.isSold && (
                    <div className="my-3">
                      Phone Number:{" "}
                      {vehicleData.selectedPackage == "premium_plus"
                        ? "(937) 430-0242 "
                        : vehicleData.number}
                    </div>
                  )}
                  <div className="my-3">Miles: {vehicleData.miles}</div>
                  <div className="my-3">
                    {vehicleData.certification &&
                      vehicleData.certification != "undefined" &&
                      "Certified:" + vehicleData.certification}
                  </div>
                </div>
              </div>
              <hr className="op-1" />

              <div
                className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"
                style={{ cursor: "pointer" }}
              >
                {vehicleData?.carImagesId?.images?.map((img, index) => {
                  // console.log("im", img);
                  return (
                    <div
                      className="col"
                      key={index}
                      onClick={() => onImageClick(index)}
                    >
                      <div className="cv-zoom">
                        <div className="cv-zoom-overlay"></div>

                        <img
                          alt="cloud-image"
                          // src={WASABI_URL + img.path}
                          src={`${WASABI_URL}${
                            img.originalCompressedImage
                              ? img.originalCompressedImage
                              : img.path
                          }`}
                          className="img-fluid br-5 w-100"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Spin>
        <JoinClub />
        <LightBox open={open} setOpen={setOpen} images={images} index={index} />
        <StyledDemo
          initialLoading={initialLoading}
          setInitialLoading={setInitialLoading}
          loaderText="Downloading image..."
        />
      </PageLayout>
    </>
  );
}

export default VehicleDetail;
