import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { DeleteOutlined } from "@ant-design/icons";

import { CiImageOn } from "react-icons/ci";
import { Spin, message, Tooltip, Popconfirm, Button, Col } from "antd";
import { WASABI_URL, baseURL, httpClient } from "../../util/Api";
import * as Yup from "yup";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Cloud from "../../Assets/Images/upload-cloud-svgrepo-com.svg";
import PageLayout from "../../Layout/PageLayout";
import { PatternFormat } from "react-number-format";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import getDeviceType from "../../util/deviceType";
import StyledDemo from "../ListVehicleStep/ImageCropper";
import CustomLoader from "../../Component/CustomLoader/CustomLoader";
import axios, { AxiosHeaders } from "axios";
import Image from "../../Assets/Images/logo.png";
const bodyStyle = [
  "--",
  "Chassis",
  "Wheels/tires",
  "Suspension",
  "Engine",
  "Engine Part",
  "Transmission",
  "Transmission Part",
  "Turbocharger / Supercharger",
  "Air Intake",
  "Brakes",
  "Interior Parts",
];
const partCondition = ["--", "new", "used"];

const EditPart = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageURLs, setImageURLs] = useState();
  const [deviceType, setDeviceType] = useState("Unknown");
  const [carImages, setCarImages] = useState([]);
  const [croppedImage, setCroppedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const dropzoneRef = useRef(null);
  const [isThumbnail, setIsThumbnail] = useState(false);
  const [images, setImages] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [data, setData] = useState({});
  const [dragId, setDragId] = useState("");
  const [imageToCrop, setImageToCrop] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [originalImage, setOriginalImage] = useState([]);
  const [angles, setAngles] = useState({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });
  const [areaZoom, setAreaZoom] = useState(1);
  const rem = (id) => {
    setImageURLs((prevFiles) => prevFiles.filter((f) => f._id !== id));
  };

  useEffect(() => {
    const initializeDropzone = () => {
      const dropzoneTemplate = document.querySelector(
        "#custom-preview-template"
      );

      if (dropzoneTemplate) {
        const dropzone = new Dropzone(dropzoneRef.current, {
          url: `${baseURL}/parts/updatePartsImages/${id}`,
          paramName: "car_images",
          dictDefaultMessage: `
            <p className="ant-upload-drag-icon">
              <div className="img-icon-list">
                <span>
                  <img src="${Cloud}" className="img-fluid" alt="img" />
                </span>
              </div>
            </p>
            <p className="ant-upload-text">Drop your files here or</p>
            <p className="ant-upload-hint">Browse</p>
          `,
          method: "PATCH",
          acceptedFiles: "image/*",
          previewTemplate: dropzoneTemplate.innerHTML,
          addRemoveLinks: false,
          init: function () {
            this.on("sending", function (file, xhr, formData) {});

            this.on("success", function (file, response) {
              const removeButton =
                file.previewElement.querySelector(".dz-remove");
              removeButton.style.display = "inline-block";
              file.previewElement.id = response.images.slice(-1)[0]._id;
              setImages((prev) => [...prev, response.images.slice(-1)[0]]);
            });
            this.on("removedfile", (file) => {
              const imageId = file.previewElement.id;
              rem(imageId);
              setImages((prev) =>
                prev.filter((image) => image._id !== imageId)
              );
            });

            this.on("error", function (file, response) {
              if (file.accepted === false) {
                this.removeFile(file); // Remove the file if it's not accepted
                message.error(
                  "Invalid file type. Please upload a JPEG, PNG, JPG, or WebP file."
                );
              }
            });
          },
        });

        return () => {
          dropzone.destroy(); // Cleanup when component unmounts
        };
      } else {
        console.error("#custom-preview-template element not found.");
      }
    };

    // Delay initialization to ensure DOM is ready
    const timeoutId = setTimeout(initializeDropzone, 5); // Adjust delay as needed

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, [isThumbnail]);
  useEffect(() => {
    fetchPartData(id);
  }, [id]);

  const token = localStorage.getItem("token");
  const fetchPartData = async (partId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/parts/get-parts-by-id/${partId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedVehicleImagesArr =
        response.data.part.partImagesId.images.map((a, i) => {
          a.id = i + 1;
          return a;
        });
      if (response.data.carImagesId?.thumbnail ?? null) {
        setIsThumbnail(true);
      }
      setData(response.data.part);
      setDeviceType(getDeviceType());
      setImageURLs(updatedVehicleImagesArr);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch part data");
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
  };
  const handleOver = (ev) => {
    ev.preventDefault();
  };
  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };
  const handleDrop = (ev) => {
    ev.preventDefault();
    const dragImage = imageURLs.find((image) => image.id == dragId);
    const dropImage = imageURLs.find(
      (image) => image.id == ev.currentTarget.id
    );
    const arr = moveItem(dragImage.id - 1, dropImage.id - 1);
    setImageURLs(arr);
  };
  const moveItem = (from, to) => {
    const updatedPreviewImages = [...imageURLs];
    const f = updatedPreviewImages.splice(from, 1)[0];
    updatedPreviewImages.splice(to, 0, f);
    return updatedPreviewImages.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
  };
  const handleThumbnailEditImage = async (image) => {
    console.log(image, "image");
    setOpen(true);
    try {
      setInitialLoading(true);
      const response = await axios.get(
        `${baseURL}files/get-car-image/${
          image.originalCompressedImage
            ? image.originalCompressedImage
            : image.path
        }`,
        { responseType: "blob" }
      );
      const url = URL.createObjectURL(response.data);
      setImageToCrop(url);

      const originalResponse = await axios.get(
        `${baseURL}files/get-car-image/${
          image.originalImage ? image.originalImage : image.path
        }`,
        { responseType: "blob" }
      );

      const originalUrl = URL.createObjectURL(originalResponse.data);

      setOriginalImage(originalUrl);
    } catch (error) {
      console.error("Error fetching image", error);
    } finally {
      setInitialLoading(false);
    }
  };
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const newFileList = Array.from(imageURLs);
    const [removed] = newFileList.splice(source.index, 1);
    newFileList.splice(destination.index, 0, removed);
    setImageURLs(newFileList);
  };
  console.log({ imageURLs });
  return (
    <>
      {loading && fileList.length > 0 && (
        <CustomLoader text="please wait, it takes few minutes to process your images" />
      )}
      <PageLayout
        type="website"
        imageWidth="1920"
        title="Edit Parts"
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <Spin
          tip="Loading..."
          spinning={loading}
          delay={200}
          size="large"
        ></Spin>
        <div className="sec-pad pt-0">
          <div className="container">
            <div className="login-box">
              <div className="row">
                <div className="col-md-12 col-lg-8">
                  <div className="text-center mb-4">
                    <h2 className="h2-title mb-2 bold">
                      List Your Vehicle Part
                    </h2>
                  </div>
                  <div style={{ padding: "20px" }} className="bb-mobile">
                    <Formik
                      initialValues={data}
                      enableReinitialize
                      validationSchema={Yup.object({
                        partName: Yup.string().required(
                          "part name is required"
                        ),
                        partType: Yup.string().required("Type is required"),
                        price: Yup.number().required("price is required"),
                        description: Yup.string().required(
                          "description is required"
                        ),
                        phone: Yup.string().required("phone is required"),
                        location: Yup.string().required("location is required"),
                        condition: Yup.string().required(
                          "location is required"
                        ),
                      })}
                      onSubmit={async (values, actions) => {
                        if (values) {
                          if (values) {
                            setLoading(true);
                          }
                        }
                        let prevImages = imageURLs.concat(images);
                        if (carImages.length === 0 && imageURLs.length === 0) {
                          message.error("Please Upload the images");
                          setLoading(false);
                          return;
                        }

                        const obj = {
                          ...values,
                        };

                        // Create a FormData object to send file data
                        const formData = new FormData();

                        // Add text fields to the FormData
                        Object.keys(obj).forEach((key) => {
                          if (key !== "partImagesId") {
                            formData.append(key, obj[key]);
                          }
                        });

                        formData.append(
                          "prevImages",
                          JSON.stringify(prevImages)
                        );
                        formData.append(
                          "newThumbnail",
                          imageURLs[0].thumb
                            ? imageURLs[0].thumb
                            : imageURLs[0].path
                        );
                        formData.append("x1", angles.x1);
                        formData.append("y1", angles.y1);
                        formData.append("x2", angles.x2);
                        formData.append("y2", angles.y2);
                        formData.append("zoom", areaZoom);

                        formData.append("car_images", croppedImage);

                        if (imageURLs.length === 0) {
                          message.error("Please Upload the images");
                          setLoading(false);
                          return;
                        }
                        await httpClient
                          .patch(`/parts/update-part/${id}`, formData, {
                            "Content-Type": "multipart/form-data",
                          })
                          .then((res) => {
                            setLoading(false);
                            fetchPartData(id);
                            message.success("Part updated successfully");
                            navigate("/listed-parts");
                          })
                          .catch((e) => {
                            setLoading(false);
                            console.error(e);
                            message.error(
                              "make sure you provide information and media is correct"
                            );
                          });
                      }}
                    >
                      {({
                        validateForm,
                        handleChange,
                        handleBlur,
                        setTouched,
                        setFieldValue,
                      }) => (
                        <Form>
                          <fieldset
                            className="text-center "
                            style={{ display: "block" }}
                          >
                            <div className="row mb-4 text-start">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Part Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="partName"
                                    className="form-control fc"
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="make"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Part Type{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="partType"
                                    className="form-control fc"
                                    render={({ field }) => (
                                      <select
                                        className="form-control fc"
                                        value={field.value}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "partType",
                                            e.target.value
                                          )
                                        }
                                      >
                                        {bodyStyle.map((style) => (
                                          <option value={style}>{style}</option>
                                        ))}
                                      </select>
                                    )}
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="bodyStyle"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Price <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="number"
                                    name="price"
                                    className="form-control fc"
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="price"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Mobile Number{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="phone"
                                    name="phone"
                                    className="form-control fc"
                                    render={({ field }) => (
                                      <PatternFormat
                                        format="+1 (###) ###-####"
                                        mask="_"
                                        // allowEmptyFormatting
                                        value={field.value}
                                        onValueChange={(value) => {
                                          setFieldValue(
                                            "phone",
                                            value.formattedValue
                                          );
                                        }}
                                        className="form-control fc"
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="number"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Location/Address{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="location"
                                    className="form-control fc mb-2"
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="location"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Condition{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="condition"
                                    className="form-control fc"
                                    render={({ field }) => (
                                      <select
                                        className="form-control fc"
                                        value={field.value}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "condition",
                                            e.target.value
                                          )
                                        }
                                      >
                                        {partCondition.map((style) => (
                                          <option value={style}>{style}</option>
                                        ))}
                                      </select>
                                    )}
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="bodyStyle"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="mb-1">Description</label>{" "}
                                  <span className="text-danger">*</span>
                                  <Field
                                    as="textarea"
                                    rows={10}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="description"
                                    className="form-control fc"
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="description"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-4 justify-content-center">
                              <div className="col-md-8 col-lg-6">
                                <div>
                                  <Col
                                    style={{ marginTop: "30px" }}
                                    span={24}
                                    md={24}
                                  >
                                    <div style={{ marginTop: "50px" }}>
                                      <div
                                        ref={dropzoneRef}
                                        className="dropzone"
                                      />
                                      <div
                                        id="custom-preview-template"
                                        style={{ display: "none" }}
                                      >
                                        <div className="dz-preview dz-file-preview">
                                          <div
                                            style={{ cursor: "pointer" }}
                                            className="dz-image"
                                          >
                                            <img
                                              data-dz-thumbnail
                                              alt="Thumbnail"
                                            />
                                          </div>
                                          <div className="dz-details">
                                            <div className="dz-size">
                                              <span data-dz-size></span>
                                            </div>
                                            <div className="dz-filename">
                                              <span data-dz-name></span>
                                            </div>
                                          </div>
                                          <div className="dz-progress">
                                            <span
                                              className="dz-upload"
                                              data-dz-uploadprogress
                                            ></span>
                                          </div>
                                          <div className="dz-error-message">
                                            <span data-dz-errormessage></span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <i
                                              className="fas fa-times dz-remove"
                                              data-dz-remove
                                              style={{
                                                padding: "8px",
                                                fontSize: "24px",
                                                display: "none",
                                              }} // Initially hidden
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  {imageURLs && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {(deviceType === "Tablet" ||
                                        deviceType === "Mobile") && (
                                        <label
                                          className="mt-3"
                                          style={{
                                            marginTop: "30px",
                                            marginLeft: "15px",
                                          }}
                                        >
                                          Images
                                        </label>
                                      )}
                                      <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppableImages">
                                          {(provided) => (
                                            <div
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                              style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                gap: "10px",
                                                marginTop: "10px",
                                              }}
                                            >
                                              {(deviceType === "Tablet" ||
                                                deviceType === "Mobile") &&
                                                imageURLs.map(
                                                  (image, index) => (
                                                    <Draggable
                                                      key={image.id}
                                                      draggableId={image.id.toString()}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <div
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          style={{
                                                            width: "230px",
                                                            padding: "10px",
                                                            border:
                                                              "1px solid grey",
                                                            borderRadius:
                                                              "10px",
                                                            ...provided
                                                              .draggableProps
                                                              .style,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              flexDirection:
                                                                "column",
                                                              justifyContent:
                                                                "space-between",
                                                            }}
                                                          >
                                                            {image?.originFileObj ? (
                                                              <img
                                                                alt={
                                                                  image.alt ||
                                                                  "Displayed image"
                                                                }
                                                                src={`${image.preview}`}
                                                                style={{
                                                                  height:
                                                                    "100px",
                                                                  objectFit:
                                                                    "cover",
                                                                }}
                                                              />
                                                            ) : (
                                                              <img
                                                                alt={
                                                                  image.alt ||
                                                                  "Displayed image"
                                                                }
                                                                src={`${WASABI_URL}${
                                                                  image.originalCompressedImage
                                                                    ? image.originalCompressedImage
                                                                    : image.path
                                                                }`}
                                                                style={{
                                                                  height:
                                                                    "100px",
                                                                  objectFit:
                                                                    "cover",
                                                                }}
                                                              />
                                                            )}
                                                          </div>
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                            }}
                                                          >
                                                            <Popconfirm
                                                              title="Are you sure you want to delete this image?"
                                                              onConfirm={() =>
                                                                rem(image._id)
                                                              }
                                                              okText="Yes"
                                                              cancelText="No"
                                                            >
                                                              <Tooltip title="delete">
                                                                <Button
                                                                  danger
                                                                  style={{
                                                                    margin:
                                                                      "5px 0 0",
                                                                  }}
                                                                  icon={
                                                                    <DeleteOutlined />
                                                                  }
                                                                  size="small"
                                                                />
                                                              </Tooltip>
                                                            </Popconfirm>
                                                            {index == 0 && (
                                                              <Tooltip title="Change Thumbnail Crop">
                                                                <Button
                                                                  danger
                                                                  style={{
                                                                    margin:
                                                                      "5px 0 0 8px",
                                                                  }}
                                                                  icon={
                                                                    <CiImageOn
                                                                      style={{
                                                                        color:
                                                                          "red",
                                                                      }}
                                                                    />
                                                                  }
                                                                  size="small"
                                                                  onClick={() =>
                                                                    handleThumbnailEditImage(
                                                                      image
                                                                    )
                                                                  }
                                                                />
                                                              </Tooltip>
                                                            )}
                                                          </div>
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    </div>
                                  )}
                                  {deviceType === "Desktop" && imageURLs && (
                                    <>
                                      <label className="mt-3">Images</label>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: "10px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {imageURLs.map((image, index) => {
                                          return (
                                            <div
                                              style={{
                                                width: "230px",
                                                padding: "10px",
                                                border: "1px solid grey",
                                                borderRadius: "10px",
                                                cursor: "grab",
                                              }}
                                              className="mt-3"
                                              key={index}
                                              id={image?.id}
                                              draggable
                                              onDragOver={(e) => handleOver(e)}
                                              onDragStart={(e) => handleDrag(e)}
                                              onDrop={(e) => handleDrop(e)}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  flexDirection: "column",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <div>
                                                  <img
                                                    alt="car"
                                                    src={`${WASABI_URL}${
                                                      image.originalCompressedImage
                                                        ? image.originalCompressedImage
                                                        : image.path
                                                    }`}
                                                    style={{
                                                      height: "100px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </div>
                                                <div>
                                                  <Popconfirm
                                                    title="Are you sure you want to delete this image?"
                                                    onConfirm={() =>
                                                      rem(image._id)
                                                    }
                                                    okText="Yes"
                                                    cancelText="No"
                                                  >
                                                    <Tooltip title="delete">
                                                      <Button
                                                        danger
                                                        style={{
                                                          margin: "5px 0 0",
                                                        }}
                                                        icon={
                                                          <DeleteOutlined />
                                                        }
                                                        size="small"
                                                      />
                                                    </Tooltip>
                                                  </Popconfirm>
                                                  <Tooltip title="Set image as thumbnail">
                                                    <Button
                                                      danger
                                                      style={{
                                                        margin: "5px 0 0 8px",
                                                      }}
                                                      icon={
                                                        <CiImageOn
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        />
                                                      }
                                                      size="small"
                                                      onClick={() =>
                                                        handleThumbnailEditImage(
                                                          image
                                                        )
                                                      }
                                                    />
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <button
                              type="submit"
                              name="Add Part"
                              className="next action-button btn btn-theme-fill"
                              onClick={() => {
                                validateForm().then((errors) => {
                                  if (Object.keys(errors).length === 0) {
                                    handleSubmit();
                                  } else {
                                    setTouched(errors);
                                    return errors;
                                  }
                                });
                              }}
                            >
                              Update Part
                            </button>
                          </fieldset>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="lb-right join-right-box">
                    <div className="text-center text-white1">
                      <img src={Image} className="img-fluid mb-3" alt="img" />
                    </div>
                    {/* <h3 className="h3-title bold  mb-2 text-white1 mt-4">
                      COMING SOON
                    </h3> */}
                    <div className="align-items-start mb-2 d-flex text-white1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23.583"
                        className="mt-2"
                        height="12.612"
                        viewBox="0 0 23.583 12.612"
                      >
                        <g id="check-all" transform="translate(-4.682 -10.647)">
                          <path
                            id="Path_22"
                            data-name="Path 22"
                            d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                            fill="#03cb3f"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="align-items-start mb-2 d-flex text-white1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        className="mt-2"
                        height="42"
                        viewBox="0 0 23.583 12.612"
                      >
                        <g id="check-all" transform="translate(-4.682 -10.647)">
                          <path
                            id="Path_22"
                            data-name="Path 22"
                            d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                            fill="#03cb3f"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                      <div className="ms-2">
                        Listing will be posted on IG, FB, and the Opposing
                        Cylinders Website
                      </div>
                    </div>
                    <div className="align-items-start mb-2 d-flex text-white1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23.583"
                        className="mt-2"
                        height="12.612"
                        viewBox="0 0 23.583 12.612"
                      >
                        <g id="check-all" transform="translate(-4.682 -10.647)">
                          <path
                            id="Path_22"
                            data-name="Path 22"
                            d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                            fill="#03cb3f"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                      <div className="ms-2">Up to 25 photos</div>
                    </div>
                    <div className="align-items-start mb-2 d-flex text-white1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23.583"
                        className="mt-2"
                        height="12.612"
                        viewBox="0 0 23.583 12.612"
                      >
                        <g id="check-all" transform="translate(-4.682 -10.647)">
                          <path
                            id="Path_22"
                            data-name="Path 22"
                            d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                            fill="#03cb3f"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                      <div className="ms-2">Unlimited Listing Duration</div>
                    </div>
                    <div className="align-items-start mb-2 d-flex text-white1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23.583"
                        className="mt-2"
                        height="12.612"
                        viewBox="0 0 23.583 12.612"
                      >
                        <g id="check-all" transform="translate(-4.682 -10.647)">
                          <path
                            id="Path_22"
                            data-name="Path 22"
                            d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                            fill="#03cb3f"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                      <div className="ms-2">Unlimited Price Changes</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <StyledDemo
          initialLoading={initialLoading}
          setInitialLoading={setInitialLoading}
          imageToCrop={imageToCrop}
          originalImage={originalImage}
          setAngles={setAngles}
          {...{ open, setOpen, croppedImage, setCroppedImage, setAreaZoom }}
        />
      </PageLayout>
    </>
  );
};

export default EditPart;
