import React, { useState } from "react";
import PageLayout from "../../Layout/PageLayout";
import Image from "../../Assets/Images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../authentication";
import stateList from "../../Data/statesList";

import Region from "../../Data/caRegion";
import { PatternFormat, NumericFormat } from "react-number-format";
import MemberShipModel from "../../Component/Modal/MemberShipModal";

const MemberShip = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    location: "",
    locationTwo: "",
    state: "",
    postCode: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);

  const { memberSignup } = useAuth();
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleOpenPayment = (e) => {
    navigate("/thanksformembership");
    e.preventDefault();
    if (validateForm()) {
      setPaymentModal(true);
    }
  };
  const handleClosePayment = () => {
    setPaymentModal(false);
  };
  const handleOkPayment = (values, clearForm) => {
    handleSubmit(values, clearForm);
    setPaymentModal(false);
  };
  const validateForm = () => {
    const {
      firstName,
      lastName,
      companyName,
      location,
      state,
      postCode,
      phone,
      email,
      password,
      city,
      confirmPassword,
      country,
    } = formData;
    let errors = {};

    if (!firstName.trim()) {
      errors.firstName = "First Name is required";
    }
    if (!lastName.trim()) {
      errors.lastName = "Last Name is required";
    }
    if (!location.trim()) {
      errors.location = "Address is required";
    }
    if (!country.trim()) {
      errors.country = "country is required";
    }
    if (!state.trim()) {
      errors.state = "State is required";
    }
    if (!city.trim()) {
      errors.state = "City is required";
    }
    if (!postCode.trim()) {
      errors.postCode = "Post Code is required";
    }
    const phoneRegex = /^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!phone.trim()) {
      errors.phone = "Phone is required";
    } else if (!phoneRegex.test(phone)) {
      errors.phone = "Invalid phone number";
    }
    if (!email.trim()) {
      errors.email = "Email Address is required";
    } else if (!emailRegex.test(email)) {
      errors.email = "Invalid Email Address ";
    }
    if (!password.trim()) {
      errors.password = "Password is required";
    }
    if (!confirmPassword.trim()) {
      errors.confirmPassword = "Confirm Password is required";
    } else if (password.trim() !== confirmPassword.trim()) {
      errors.confirmPassword = "Password and Confirm Password does not match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const clearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      companyName: "",
      location: "",
      locationTwo: "",
      subrub: "",
      country: "",
      state: "",
      city: "",
      postCode: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setErrors({});
  };
  const handleSubmit = async (val, clForm, paymentLoading) => {
    if (validateForm()) {
      await memberSignup(formData, val, null, paymentLoading);
    }
  };
  const {
    firstName,
    lastName,
    companyName,
    location,
    locationTwo,
    state,
    city,
    postCode,
    phone,
    email,
    password,
    country,
    confirmPassword,
  } = formData;
  return (
    <>
      <PageLayout
        type="website"
        imageWidth="1920"
        title="Membership"
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <>
          {/* content */}
          <div className="sec-pad pt-0">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="login-box">
                    <div className="row h-100">
                      <div className="col-md-12 col-lg-8">
                        <div className="lb-left">
                          <div className="text-center">
                            <h2 className="h2-title mb-2 bold">
                              Become a Member
                            </h2>
                            <p>Enter your Payment Information</p>
                          </div>
                          <form className="mt-4 mb-4">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    First Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control fc"
                                    placeholder=""
                                    name="firstName"
                                    value={firstName}
                                    onChange={handleInputChange}
                                  />
                                  {errors.firstName && (
                                    <div className="text-danger">
                                      {errors.firstName}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Last Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control fc"
                                    placeholder=""
                                    name="lastName"
                                    value={lastName}
                                    onChange={handleInputChange}
                                  />
                                  {errors.lastName && (
                                    <div className="text-danger">
                                      {errors.lastName}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Company Name (optional)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control fc"
                                    placeholder=""
                                    name="companyName"
                                    value={companyName}
                                    onChange={handleInputChange}
                                  />
                                  {errors.companyName && (
                                    <div className="text-danger">
                                      {errors.companyName}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Street Address{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control fc mb-2"
                                    placeholder="House number and street name"
                                    name="location"
                                    value={location}
                                    onChange={handleInputChange}
                                  />
                                  <input
                                    type="text"
                                    className="form-control fc"
                                    placeholder="Apartment, suite, unit, etc. (optional)"
                                    name="locationTwo"
                                    value={locationTwo}
                                    onChange={handleInputChange}
                                  />
                                  {errors.location && (
                                    <div className="text-danger">
                                      {errors.location}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label htmlFor="country" className="mb-1">
                                    Country{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    as="select"
                                    type="string"
                                    name="country"
                                    className="form-control fc"
                                    value={country}
                                    onChange={handleInputChange}
                                  >
                                    <option value=""></option>
                                    <option value="United States">
                                      United States
                                    </option>
                                    <option value="Canada">Canada</option>
                                  </select>
                                  {errors.country && (
                                    <div className="text-danger">
                                      {errors.country}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    City <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control fc"
                                    placeholder=""
                                    name="city"
                                    value={city}
                                    onChange={handleInputChange}
                                  />
                                  {errors.city && (
                                    <div className="text-danger">
                                      {errors.city}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {console.log(country)}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    State / Province{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-control fc form-select"
                                    name="state"
                                    value={state}
                                    onChange={handleInputChange}
                                  >
                                    {country === "Canada"
                                      ? Region.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item.abbreviation}
                                          >
                                            {item.name}
                                          </option>
                                        ))
                                      : stateList.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item.abbreviation}
                                          >
                                            {item.name}
                                          </option>
                                        ))}
                                  </select>
                                  {errors.state && (
                                    <div className="text-danger">
                                      {errors.state}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Postal Code{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control fc"
                                    placeholder=""
                                    name="postCode"
                                    value={postCode}
                                    onChange={handleInputChange}
                                  />

                                  {errors.postCode && (
                                    <div className="text-danger">
                                      {errors.postCode}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Phone <span className="text-danger">*</span>
                                  </label>
                                  <PatternFormat
                                    format="+1 (###) ###-####"
                                    mask="_"
                                    value={phone}
                                    onValueChange={(value) => {
                                      setFormData({
                                        ...formData,
                                        phone: value.formattedValue,
                                      });
                                    }}
                                    className="form-control fc"
                                  />
                                  {errors.phone && (
                                    <div className="text-danger">
                                      {errors.phone}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Email Address{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control fc"
                                    placeholder=""
                                    name="email"
                                    value={email}
                                    onChange={handleInputChange}
                                  />
                                  {errors.email && (
                                    <div className="text-danger">
                                      {errors.email}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control fc"
                                    name="password"
                                    value={password}
                                    onChange={handleInputChange}
                                  />
                                  {errors.password && (
                                    <div className="text-danger">
                                      {errors.password}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-1">
                                    Confirm Password
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control fc"
                                    placeholder=""
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handleInputChange}
                                  />
                                  {errors.confirmPassword && (
                                    <div className="text-danger">
                                      {errors.confirmPassword}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="text-center mt-3">
                              <button
                                // type="submit"
                                className="btn btn-theme-red"
                                // onClick={handleSubmit}
                                onClick={handleOpenPayment}
                              >
                                Join
                              </button>
                            </div>
                            <div className="mt-4 text-center">
                              Already have an account?{" "}
                              <NavLink to="/login" className="text-theme">
                                Sign In
                              </NavLink>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-4">
                        <div className="lb-right join-right-box">
                          <div className="text-center text-white1">
                            <img
                              src={Image}
                              className="img-fluid mb-3"
                              alt="img"
                            />
                          </div>
                          <h3 className="h3-title bold  mb-2 text-white1 mt-4">
                            COMING SOON
                          </h3>
                          <div className="align-items-start mb-2 d-flex text-white1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23.583"
                              className="mt-2"
                              height="12.612"
                              viewBox="0 0 23.583 12.612"
                            >
                              <g
                                id="check-all"
                                transform="translate(-4.682 -10.647)"
                              >
                                <path
                                  id="Path_22"
                                  data-name="Path 22"
                                  d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                                  fill="#03cb3f"
                                  fillRule="evenodd"
                                />
                              </g>
                            </svg>
                            <div className="ms-2">Discounts</div>
                          </div>
                          <div className="align-items-start mb-2 d-flex text-white1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23.583"
                              className="mt-2"
                              height="12.612"
                              viewBox="0 0 23.583 12.612"
                            >
                              <g
                                id="check-all"
                                transform="translate(-4.682 -10.647)"
                              >
                                <path
                                  id="Path_22"
                                  data-name="Path 22"
                                  d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                                  fill="#03cb3f"
                                  fillRule="evenodd"
                                />
                              </g>
                            </svg>
                            <div className="ms-2">Early access to listings</div>
                          </div>
                          <div className="align-items-start mb-2 d-flex text-white1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23.583"
                              className="mt-2"
                              height="12.612"
                              viewBox="0 0 23.583 12.612"
                            >
                              <g
                                id="check-all"
                                transform="translate(-4.682 -10.647)"
                              >
                                <path
                                  id="Path_22"
                                  data-name="Path 22"
                                  d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                                  fill="#03cb3f"
                                  fillRule="evenodd"
                                />
                              </g>
                            </svg>
                            <div className="ms-2">Special events</div>
                          </div>
                          <div className="align-items-start mb-2 d-flex text-white1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23.583"
                              className="mt-2"
                              height="12.612"
                              viewBox="0 0 23.583 12.612"
                            >
                              <g
                                id="check-all"
                                transform="translate(-4.682 -10.647)"
                              >
                                <path
                                  id="Path_22"
                                  data-name="Path 22"
                                  d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                                  fill="#03cb3f"
                                  fillRule="evenodd"
                                />
                              </g>
                            </svg>
                            <div className="ms-2">Giveaways</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </PageLayout>
      <MemberShipModel
        visible={paymentModal}
        onCancel={() => {
          handleClosePayment(null);
        }}
        onOk={(values, clearForm, paymentLoading) => {
          handleSubmit(values, clearForm, paymentLoading);
        }}
      />
    </>
  );
};

export default MemberShip;
