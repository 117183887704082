import React, { useState } from "react";
import PageLayout from "../../Layout/PageLayout";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../authentication";
import "./style.css";
const ThanksForClubMembership = () => {
  const { isLoading, error, userLogin } = useAuth();

  return (
    <>
      <PageLayout
        type="website"
        imageWidth="1920"
        url="https://opposingcylinders.com/"
        title="Thanks for becoming a Club Member"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <>
          {/* content */}
          <div className="sec-pad pt-0">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="login-box">
                    <div className="row h-100">
                      <div className="col-md-12 col-lg-6">
                        <div className="lb-left">
                          <div className="text-center">
                            <h2 className="h2-title mb-2 bold">
                              Thank you for becoming a Club Member!
                            </h2>
                            <p>
                              You have successfully signed up as an Opposing
                              Cylinders Club Member. You now have exclusive
                              early access to premium and premium plus vehicle
                              listings, and can take advantage of our vendor
                              partner benefits!
                            </p>
                          </div>
                          <div className="button-container">
                            <div className="text-center mt-3">
                              <NavLink to="/profile">
                                <button
                                  className="btn btn-theme-red small-button"
                                  disabled={isLoading}
                                >
                                  View Profile
                                </button>
                              </NavLink>
                            </div>
                            <div className="text-center mt-3 ">
                              <NavLink to="/">
                                <button
                                  className="btn btn-theme-red small-button"
                                  disabled={isLoading}
                                >
                                  Vehicle Listings
                                </button>
                              </NavLink>
                            </div>
                            <div className="text-center mt-3">
                              <NavLink to="/vendor-benefits">
                                <button
                                  className="btn btn-theme-red small-button"
                                  disabled={isLoading}
                                >
                                  Vendor Partner Benefits
                                </button>
                              </NavLink>
                            </div>
                          </div>

                          {error && <div className="text-danger">{error}</div>}
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="lb-right d-none d-lg-block ">
                          <div className="position-relative">
                            <h3 className="h2-title text-white1 bold">
                              Follow us for more information
                            </h3>
                            <div className="d-flex align-items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="me-2"
                                width={38}
                                height={38}
                                viewBox="0 0 38 38"
                              >
                                <g
                                  id="Group_310"
                                  data-name="Group 310"
                                  transform="translate(-1039 -385)"
                                >
                                  <path
                                    id="phone"
                                    d="M16.764,12.991c-1.1-.945-2.226-1.517-3.317-.574l-.651.57c-.477.414-1.363,2.347-4.79-1.594S6.619,6.844,7.1,6.433l.655-.571c1.085-.946.676-2.136-.107-3.361l-.473-.742C6.386.537,5.53-.266,4.442.679l-.588.514A5.521,5.521,0,0,0,1.7,4.845c-.393,2.6.846,5.569,3.682,8.83s5.606,4.9,8.234,4.874a5.533,5.533,0,0,0,3.915-1.621l.59-.514c1.085-.943.411-1.9-.694-2.851l-.664-.572Z"
                                    transform="translate(1047.848 394.593)"
                                    fill="#eef2ff"
                                  />
                                  <g
                                    id="Ellipse_3"
                                    data-name="Ellipse 3"
                                    transform="translate(1039 385)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeWidth={2}
                                  >
                                    <circle
                                      cx={19}
                                      cy={19}
                                      r={19}
                                      stroke="none"
                                    />
                                    <circle
                                      cx={19}
                                      cy={19}
                                      r={18}
                                      fill="none"
                                    />
                                  </g>
                                </g>
                              </svg>
                              <NavLink
                                to="tel:1-937-430-0242"
                                className="text-white1 h4-title mb-0"
                              >
                                937-430-0242
                              </NavLink>
                            </div>
                            <div>
                              <ul className="mb-0 list-unstyled list-inline mt-4 mt-md-5 lb-social-icon">
                                <li className="list-inline-item me-3">
                                  <NavLink
                                    to="https://www.facebook.com/profile.php?id=100063454788193"
                                    target="_blank"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={36}
                                      height={36}
                                      fill="currentColor"
                                      className="bi bi-facebook"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                    </svg>
                                  </NavLink>
                                </li>
                                <li className="list-inline-item">
                                  <NavLink
                                    to="https://www.instagram.com/opposingcylinders/"
                                    target="_blank"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={36}
                                      height={36}
                                      fill="currentColor"
                                      className="bi bi-twitter "
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                    </svg>
                                  </NavLink>
                                </li>
                                <li
                                  className="list-inline-item"
                                  style={{ marginLeft: "8px" }}
                                >
                                  <NavLink
                                    to="https://www.instagram.com/opposingcylinders/"
                                    target="_blank"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={36}
                                      height={36}
                                      fill="currentColor"
                                      className="bi bi-instagram "
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                    </svg>
                                  </NavLink>
                                </li>
                                <li
                                  className="list-inline-item"
                                  style={{ marginLeft: "8px" }}
                                >
                                  <NavLink
                                    to="https://www.instagram.com/opposingcylinders/"
                                    target="_blank"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={36}
                                      height={36}
                                      fill="currentColor"
                                      className="bi bi-youtube"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                                    </svg>
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </PageLayout>
    </>
  );
};

export default ThanksForClubMembership;
