import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { WASABI_URL, baseURL } from "../../util/Api";
import { VehicleExtraInfo } from "../../util/vehicles";
import Resizer from "react-image-file-resizer";
import { Spin } from "antd";
import ShareWidget from "../ShareWidget";
import LikeWidget from "../LikeWidget";
import { Tooltip } from "antd";

const VehiclesCard = (props) => {
  const {
    id,
    noOfLike,
    title,
    price,
    noOfImage,
    location,
    availbility,
    page,
    onlinePaperWork,
    number,
    certification,
    drivenMile,
    thumbnail,
    isThumbnail,
    oldImage,
    newThumbnail,
    videoWalkaround,
    testDrive,
    delivery,
    isLiked,
    forSold,
  } = props;

  const [resizedImageUrl, setResizedImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const urlToBlob = async (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error("Failed to load image"));
        }
      };
      xhr.onerror = () => {
        reject(new Error("Network error"));
      };
      xhr.send();
    });
  };

  const formatPrice = (price) => {
    const roundedPrice = Math.round(price);

    // Format the price without decimal points
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0, // No decimal places
      maximumFractionDigits: 0, // No decimal places
    }).format(roundedPrice);
  };

  const resizeAndDisplayImage = async (url) => {
    try {
      setLoading(true);
      const blob = await urlToBlob(url);
      Resizer.imageFileResizer(
        blob,
        300, // Maximum width
        225, // Maximum height
        "JPEG",
        100,
        0,
        (resizedUri) => {
          setResizedImageUrl(resizedUri);
        },
        "base64",
        300, // New width after cropping
        225, // New height after cropping
        "center" // Position to crop from
      );
      setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <>
      <div className="col">
        <Link to={forSold ? "#" : `/vehicle-detail/${id}`}>
          <div className="cd-box">
            <div className="img-box position-relative">
              {loading ? (
                <Spin
                  tip="Loading..."
                  spinning={loading}
                  delay={200}
                  size="large"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "225px",
                  }}
                ></Spin>
              ) : (
                <div
                  className="cb-img"
                  style={{
                    backgroundImage: `url(${
                      // isThumbnail // ? resizedImageUrl
                      //   ? WASABI_URL + newThumbnail
                      // :
                      WASABI_URL + newThumbnail
                    })`,
                  }}
                >
                  {!forSold && (
                    <div className="ib-overlay">
                      <div className="d-flex justify-content-between w-100">
                        <div className="text-white d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-images me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                            <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                          </svg>{" "}
                          {noOfImage}
                        </div>
                        <div>
                          <LikeWidget
                            isLiked={isLiked}
                            vehicleId={id}
                            numberOfLikes={noOfLike}
                            className="text-white"
                          />
                          <ShareWidget className="d-inline-block" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="cdb-details">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 mt-2 mt-md-0 text-start text-md-end">
                  <div className="bedge d-inline-block">
                    {formatPrice(price)}
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  {title.length > 25 ? (
                    <Tooltip title={title}>
                      {title.slice(0, 25) + "..."}
                    </Tooltip>
                  ) : (
                    title
                  )}
                </div>
              </div>
              {/* <div className="mt-3 d-flex align-items-center justify-content-between small text-white">
                <div className="d-inline-flex align-items-center">
                  <svg
                    className="me-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.412"
                    height="13.412"
                    viewBox="0 0 16.412 13.412"
                  >
                    <path
                      id="dashboard"
                      d="M8.206,0a8.206,8.206,0,1,0,8.206,8.206A8.23,8.23,0,0,0,8.206,0Zm0,2.052A6.155,6.155,0,1,1,2.052,8.206,6.146,6.146,0,0,1,8.206,2.052Zm0,2.052A1.026,1.026,0,1,0,9.232,5.129,1.016,1.016,0,0,0,8.206,4.1ZM4.8,6.155a1.026,1.026,0,0,0-.39,1.723L6.278,9.745a2.083,2.083,0,0,0-.123.513A2.052,2.052,0,1,0,8.206,8.206a2.083,2.083,0,0,0-.513.123L5.826,6.462a1.026,1.026,0,0,0-.9-.328,1.026,1.026,0,0,0-.123,0Zm6.483,0A1.026,1.026,0,1,0,12.309,7.18,1.016,1.016,0,0,0,11.283,6.155Z"
                      fill="#fbfdff"
                    />
                  </svg>
                  {drivenMile} Miles
                </div>
                <div className="d-inline-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={13}
                    height={13}
                    fill="currentColor"
                    className="bi bi-geo-alt-fill me-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>{" "}
                  {location}
                </div>
              </div>
              <hr className="op-1 mt-2 mb-2" />
              <div className="mb-3 d-flex align-items-center justify-content-between small text-white">
                <div className="d-inline-flex align-items-center">
                  <Link to="tel:123-456-7890">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.316"
                      className="me-1"
                      height="13.312"
                      viewBox="0 0 15.316 15.312"
                    >
                      <path
                        id="phone"
                        d="M11.691,9.619c-1.054-.1-1.629,1.342-2.4,1.725-1.246.671-3.546-1.725-3.546-1.725s-2.4-2.3-1.821-3.546c.479-.767,1.917-1.342,1.821-2.4-.1-.958-2.2-4.409-3.259-3.451C.189,2.334,0,3.2,0,4.922c-.1,2.971,3.738,6.709,3.738,6.709.383.383,3.738,3.834,6.709,3.738,1.725,0,2.588-.192,4.7-2.492.958-1.054-2.4-3.163-3.451-3.259Z"
                        transform="translate(0.004 -0.059)"
                        fill="#d9e4ee"
                      />
                    </svg>
                    {number}
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </Link>
      </div>
      <>
        {/* Delete Modal */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center">
                <button
                  type="button"
                  className="btn-close modal-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <div className="mb-3 mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={56}
                    height={56}
                    fill="currentColor"
                    className="bi bi-x-circle text-danger"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <h2 className="text-theme bold h2-title mb-3">Are you sure?</h2>
                <p>
                  Do you really want to delete these records? This process
                  cannot be undone.
                </p>
                <div className=" mt-4 mt-md-4 mb-4">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>{" "}
                  &nbsp;&nbsp;
                  <button type="button" className="btn btn-danger">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default VehiclesCard;
