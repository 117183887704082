import { useEffect, useState } from "react";
import { message } from "antd";
import { httpClient } from "../../../util/Api";
import { values } from "lodash";

export const useProvideAuth = () => {
  const [likes, setLikes] = useState([]);
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState("");
  const [isLoadingUser, setLoadingUser] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError("");
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError("");
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error || null);
  };

  const userLogin = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post("auth/login", user)
      .then(({ data }) => {
        if (data.isAuthenticated) {
          fetchSuccess();
          httpClient.defaults.headers.common["Authorization"] =
            "Bearer " + data.token;
          localStorage.setItem("token", data.token);
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.response.data.message);
      });
  };

  const userSignup = (user, cardVal, callbackFun, paymentLoading) => {
    fetchStart();
    paymentLoading?.start();
    httpClient
      .post("auth/sign-up", {
        ...user,
        payment: cardVal ? { ...cardVal } : null,
      })
      .then(({ data }) => {
        if (data.isAuthenticated) {
          if (cardVal) {
            httpClient
              .post("auth/subscription", { user_id: data.user._id })
              .then(() => {
                fetchSuccess();
                paymentLoading?.success();
                localStorage.setItem("token", data.token);
                httpClient.defaults.headers.common["Authorization"] =
                  "Bearer " + data.token;
                getAuthUser();
                if (callbackFun) callbackFun();
              });
          } else {
            fetchSuccess();
            paymentLoading?.success();
            localStorage.setItem("token", data.token);
            httpClient.defaults.headers.common["Authorization"] =
              "Bearer " + data.token;
            getAuthUser();
            if (callbackFun) callbackFun();
          }
        } else {
          fetchError(data.error);
          paymentLoading?.error();
        }
      })
      .catch(function (error) {
        fetchError();
        message.error(error.response.data.message);
        paymentLoading?.error();
      });
  };

  const memberSignup = (user, cardVal, callbackFun, paymentLoading) => {
    fetchStart();
    paymentLoading?.start();
    httpClient
      .post("auth/sign-up", {
        ...user,
        payment: cardVal ? { ...cardVal } : null,
      })
      .then(({ data }) => {
        if (data.isAuthenticated) {
          if (cardVal) {
            httpClient
              .post("auth/subscription", {
                user_id: data.user._id,
                payment: cardVal,
                paymentType:
                  cardVal.payment === "219.89" ? "annually" : "monthly",
              })
              .then((result) => {
                message.success("thanks for subscription");

                fetchSuccess();
                paymentLoading?.success();
                localStorage.setItem("token", data.token);
                httpClient.defaults.headers.common["Authorization"] =
                  "Bearer " + data.token;
                getAuthUser();
                if (callbackFun) callbackFun();
                if (cardVal.payment === "monthly") {
                  window.location.href = "/thanksforclubmembership";
                } else {
                  window.location.href = "/thanksforcrewmembership";
                }
              });
          } else {
            fetchSuccess();
            paymentLoading?.success();
            localStorage.setItem("token", data.token);
            httpClient.defaults.headers.common["Authorization"] =
              "Bearer " + data.token;
            getAuthUser();
            if (callbackFun) callbackFun();
          }
        } else {
          fetchError(data.error);
          paymentLoading?.error();
        }
      })
      .catch(function (error) {
        fetchError();
        message.error(error.response.data.message);
        paymentLoading?.error();
      });
  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchStart();
    // httpClient
    //   .post('auth/logout')
    //   .then(({ data }) => {
    //     if (data.result) {
    fetchSuccess();
    httpClient.defaults.headers.common["Authorization"] = "";
    localStorage.removeItem("token");
    setAuthUser(false);
    window.location.reload();
    //   if (callbackFun) callbackFun();
    // } else {
    //   fetchError(data.error);
    // }
    // })
    // .catch(function (error) {
    //   fetchError(error.message);
    // });
  };

  const getAuthUser = () => {
    fetchStart();
    httpClient
      .get("auth/me")
      .then(({ data }) => {
        if (data.user) {
          fetchSuccess();
          setAuthUser(data.user);
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        localStorage.removeItem("token");
        httpClient.defaults.headers.common["Authorization"] = "";
        fetchError(error.message);
      });
  };

  const getUserLikes = () => {
    if (authUser?._id) {
      httpClient.get("likes").then((res) => {
        setLikes(res.data);
      });
    }
  };

  useEffect(() => {
    getUserLikes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser?._id]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      httpClient.defaults.headers.common["Authorization"] = "Bearer " + token;
      setLoadingUser(true);
      httpClient
        .get("auth/me")
        .then(({ data }) => {
          if (data.user) {
            setAuthUser(data.user);
          }
          setLoadingUser(false);
        })
        .catch(function () {
          localStorage.removeItem("token");
          httpClient.defaults.headers.common["Authorization"] = "";
          setLoadingUser(false);
        });
    }
  }, []);

  const getSub = (user_id, cardVal, callbackFun, paymentLoading) => {
    paymentLoading?.start();
    httpClient
      .post("auth/subscription", {
        user_id,
        payment: cardVal,
        paymentType: cardVal?.payment === "219.89" ? "annually" : "monthly",
      })
      .then(() => {
        paymentLoading?.success();
        getAuthUser();
        if (callbackFun) callbackFun();
        if (cardVal.payment === "219.89") {
          console.log("if in");
          window.location.href = "/thanksforcrewmembership";
        } else {
          console.log("else in");
          window.location.href = "/thanksforclubmembership";
        }
      })
      .catch(() => paymentLoading?.error());
  };
  const upgradeSub = (user_id, cardVal, callbackFun, paymentLoading) => {
    paymentLoading?.start();
    httpClient
      .post("auth/subscription-upgrade", {
        user_id,
        payment: cardVal,
        paymentType: "annually",
      })
      .then(() => {
        console.log("came");
        paymentLoading?.success();
        getAuthUser();
        if (callbackFun) callbackFun();
        window.location.href = "/thanksforcrewmembership";
        console.log("yes it should close");
      })
      .catch((error) => {
        // Log the full error object for better debugging
        console.error(
          "Error occurred during subscription upgrade:",
          error.message || error.response || error
        );
      });
  };
  const reSubscribe = (user_id, cardVal, callbackFun, paymentLoading) => {
    console.log(cardVal);
    paymentLoading?.start();
    httpClient
      .post("auth/subscribe-again", {
        user_id,
        payment: cardVal,
        paymentType: cardVal?.payment === "219.89" ? "annually" : "monthly",
      })
      .then(() => {
        paymentLoading?.success();
        message.success("you have successfully subscribed");
        getAuthUser();
        if (callbackFun) callbackFun();
        if (cardVal.payment === "219.89") {
          window.location.href = "/thanksforcrewmembership";
        } else {
          window.location.href = "/thanksforclubmembership";
        }
      })
      .catch(() => paymentLoading?.error());
  };

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    upgradeSub,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
    getUserLikes,
    likes,
    getSub,
    reSubscribe,
    memberSignup,
  };
};
