import React, { Component } from "react";

import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import RecentVehicleListing from "../../Layout/RecentVehicleListing";
import ListYourVehicle from "../../Layout/ListYourVehicle";
import Hero from "../../Layout/Hero";

export class Home extends Component {
  render() {
    return (
      <>
        <PageLayout
          title="Home"
          type="website"
          imageWidth="1920"
          url="https://opposingcylinders.com/"
          description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
          image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
        >
          <Hero />

          <ListYourVehicle />
          {/* <RecentVehicleListing /> */}
          <JoinClub />
        </PageLayout>
      </>
    );
  }
}

export default Home;
