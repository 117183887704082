import React, { useState } from "react";
import ReactDOM from "react-dom";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import { Button, message } from "antd";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import getCroppedImg from "./CropImage";
import { styles } from "./styles";
import Dialog from "@material-ui/core/Dialog";
import { fetchImageAndGetBlobAndDimensions } from "../../util/fetchImageAndGetBlobAndDimensions";

import Slide from "@material-ui/core/Slide";
import CustomLoader from "../../Component/CustomLoader/CustomLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Demo = ({
  classes,
  imageToCrop,
  open,
  initialLoading,
  setInitialLoading,
  originalImage,
  setOpen,
  loaderText,
  angles,
  setAngles,
  croppedImage,
  setCroppedImage,
  setAreaZoom,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const showCroppedImage = async () => {
    try {
      setLoading(true);

      // Fetch image dimensions
      const { width, height } = await fetchImageAndGetBlobAndDimensions(
        originalImage
      );
      let displayedWidth = 0;
      await fetchImageAndGetBlobAndDimensions(imageToCrop).then((data) => {
        displayedWidth = data.height;
      });

      let originalImageWidth = width;

      if (croppedAreaPixels) {
        const crop = {
          x1: (originalImageWidth / displayedWidth) * croppedAreaPixels.x,
          y1: (originalImageWidth / displayedWidth) * croppedAreaPixels.y,
          x2:
            (originalImageWidth / displayedWidth) *
            (croppedAreaPixels.x + croppedAreaPixels.width),
          y2:
            (originalImageWidth / displayedWidth) *
            (croppedAreaPixels.y + croppedAreaPixels.height),
        };

        setAngles(crop);
        const croppedImage = await getCroppedImg(
          imageToCrop,
          croppedAreaPixels,
          rotation
        );

        const response = await fetch(originalImage);
        if (!response.ok) {
          throw new Error(`Failed to fetch image: ${response.statusText}`);
        }

        const blob = await response.blob();
        const file = new File([blob], "image.jpg", { type: "image/jpeg" });
        message.success("image cropped successfully");
        setCroppedImage(file);
        setLoading(false);
        handleClose();
        setAreaZoom(zoom);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onClose = () => {
    setCroppedImage(null);
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1));
  };
  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  return (
    <div>
      {initialLoading && (
        <CustomLoader text={loaderText || "Fetching image..."} />
      )}
      {loading && <CustomLoader text="Your image is cropping " />}
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className={classes.cropContainer}>
          <Cropper
            image={imageToCrop}
            objectFit="contain"
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 3}
            onZoomChange={onZoomChange}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
          />
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleZoomOut}
            style={{ marginRight: "10px" }}
          >
            Zoom Out
          </Button>
          <Button variant="contained" color="primary" onClick={handleZoomIn}>
            Zoom In
          </Button>
        </div>
        <div style={{ width: "70%", margin: "auto" }}>
          <Typography gutterBottom>Zoom</Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, value) => onZoomChange(value)}
            aria-labelledby="continuous-slider"
          />
        </div>

        <div
          style={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Button
            style={{ marginRight: "10px" }}
            className="mt:3"
            onClick={showCroppedImage}
            type="primary"
            classes={{ root: classes.cropButton }}
          >
            crop
          </Button>
          <Button
            onClick={() => setOpen(false)}
            type="default"
            classes={{ root: classes.cropButton }}
          >
            Cancel
          </Button>
        </div>
        {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
      </Dialog>
    </div>
  );
};

const StyledDemo = withStyles(styles)(Demo);

export default StyledDemo;
