import React, { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
// import Protected from "./PriviteRoutes";
import Home from "../Pages/Home";
import Video from "../Pages/Video";
import Login from "../Pages/Login";
import Join from "../Pages/Join";
import Thankyou from "../Pages/Thanksforregister";
import ThanksForListing from "../Pages/Thanksforlisting";
import BrowseVehicles from "../Pages/BrowseVehicles";
import BrowseParts from "../Pages/BrowseParts";

import ForgetPassword from "../Pages/ForgetPassword";
import VideoDetail from "../Pages/VideoDetail";
import ListVehicle from "../Pages/ListYourVehicle";
import VehicleDetail from "../Pages/VehicleDetail";
import PartDetail from "../Pages/PartsDetail";
import EditPart from "../Pages/VehicleParts/edit";
import Benifits from "../Pages/Benifits";

import Billing from "../Pages/Billing";
import Profile from "../Pages/Profile";
import ListVehicleStep from "../Pages/ListVehicleStep";
import ListedVehicle from "../Pages/ListedVehicle";
import ListedParts from "../Pages/ListedParts";

import Cart from "../Pages/Cart";
import Terms from "../Pages/Terms";
import Checkout from "../Pages/Checkout";
import Privacy from "../Pages/Privacy";
import ProtectedRoute from "../util/ProtectedRoute";
import { Success } from "../Pages/Success";
import NotFound from "../Pages/404/NotFound";
import { useAuth } from "../authentication";
import VehicleEdit from "../Pages/VehicleEdit";
import { Payment } from "../Pages/Payment";
import ResetPassword from "../Pages/ResetPassword";
import LikedVehicles from "../Pages/LikedVehicles";
import RecentSoldVehicles from "../Pages/RecentSoldVehicles";
import PartsAdd from "../Pages/VehicleParts/add";
import MemberShip from "../Pages/membership";
import ThanksForMembership from "../Pages/ThanksMemeberShip";
import ThanksForAddingParts from "../Pages/Thanksforaddingparts";
import ThanksForClubMembership from "../Pages/thanksclubmember";
import ThanksForCrewMembership from "../Pages/ThanksForCrewMembership";
import MemberShipPackages from "../Pages/membershippackages";

const MainRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authUser, isLoadingUser } = useAuth();
  const notAllowedPaths = [
    "/join",
    "/login",
    "/forget-password",
    "/reset-password",
    "/Membership",
  ];
  useEffect(() => {
    if (!isLoadingUser) {
      if (authUser && notAllowedPaths.includes(location.pathname)) {
        navigate("/");
      }
    }
  }, [isLoadingUser, authUser, location.pathname, navigate]);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="video" element={<Video />} />
      <Route path="browse-vehicles" element={<BrowseVehicles />} />
      <Route path="cars-for-sale" element={<Navigate replace to="/" />} />
      <Route
        path="category/cars-for-sale/page/2"
        element={<Navigate replace to="/" />}
      />
      <Route path="/" element={<Home />} />
      <Route path="videos" element={<Navigate replace to="/video" />} />
      <Route path="video" element={<Video />} />
      <Route path="browse-vehicles" element={<BrowseVehicles />} />
      <Route path="vendor-benefits" element={<Benifits />} />
      <Route
        path="parts-for-sale"
        element={<Navigate replace to="/browse-parts" />}
      />
      <Route path="browse-parts" element={<BrowseParts />} />
      <Route path="join" element={<Join />} />
      {/* Thankyou pages */}
      <Route path="thankyou" element={<Thankyou />} />
      <Route path="thanksforlisting/:id" element={<ThanksForListing />} />
      <Route path="thanksformembership" element={<ThanksForMembership />} />
      <Route
        path="thanksforclubmembership"
        element={<ThanksForClubMembership />}
      />
      <Route
        path="thanksforcrewmembership"
        element={<ThanksForCrewMembership />}
      />
      <Route
        path="thanksforaddingparts/:id"
        element={<ThanksForAddingParts />}
      />

      <Route
        path="Membership-Test"
        element={!authUser ? <MemberShip /> : <Navigate to="/" />}
      />

      <Route path="login" element={<Login />} />
      <Route path="payment" element={<Payment />} />
      <Route path="forget-password" element={<ForgetPassword />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route
        path="category/cars-for-sale-sold"
        element={<Navigate replace to="/all-sold-vehicles" />}
      />
      <Route path="all-sold-vehicles" element={<RecentSoldVehicles />} />
      <Route
        path="car-listings-choices"
        element={<Navigate replace to="/list-your-vehicle" />}
      />
      <Route path="list-your-vehicle" element={<ListVehicle />} />
      <Route path="membership" element={<MemberShipPackages />} />
      <Route path="/video-detail/:id" element={<VideoDetail />} />
      <Route path="/vehicle-detail/:id" element={<VehicleDetail />} />
      <Route path="terms-and-conditions" element={<Terms />} />
      <Route path="checkout-agreement" element={<Checkout />} />
      <Route path="privacy-policy" element={<Privacy />} />
      <Route path="/part-detail/:id" element={<PartDetail />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/success" element={<Success />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="liked-vehicles" element={<LikedVehicles />} />
        <Route path="/list-parts-add" element={<PartsAdd />} />

        {/* <Route path="/billing" element={<Billing />} /> */}
        <Route path="/list-vehicle-edit/:id/:sec" element={<VehicleEdit />} />
        <Route path="/list-vehicle-step" element={<ListVehicleStep />} />
        <Route path="/listed-vehicle" element={<ListedVehicle />} />
        <Route path="/listed-parts" element={<ListedParts />} />
        <Route path="/list-parts-edit/:id" element={<EditPart />} />

        <Route path="/cart" element={<Cart />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default MainRoutes;
