import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../Layout/PageLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Cloud from "../../Assets/Images/upload-cloud-svgrepo-com.svg";
import Image from "../../Assets/Images/logo.png";
import * as Yup from "yup";

import { PatternFormat } from "react-number-format";
import { baseURL, httpClient } from "../../util/Api";
import { Spin, message, Col } from "antd";
import PaymentModal from "../../Component/Modal/PaymentModal";
import { useNavigate } from "react-router";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import StyledDemo from "../ListVehicleStep/ImageCropper";
// Define body styles for part types
const bodyStyle = [
  "--",
  "Chassis",
  "Wheels/tires",
  "Suspension",
  "Engine",
  "Engine Part",
  "Transmission",
  "Transmission Part",
  "Turbocharger / Supercharger",
  "Air Intake",
  "Brakes",
  "Interior Parts",
];

const partCondition = ["--", "new", "used"];

const AddPart = () => {
  const [loading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [id, setId] = useState("");
  const dropzoneRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState("");
  const [imageURLs, setImageURLs] = useState([]);
  const [carImages, setCarImages] = useState([]);
  const [imageToCrop, setImageToCrop] = useState("");
  const [autoThumbnail, setAutoThumbnail] = useState([]);
  const [isElse, setIsElse] = useState(false);
  const navigate = useNavigate();

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const imageURL = e.target.result;
  //       setImageURLs((prev) => [...prev, imageURL]);
  //       setCarImages((prev) => [...prev, file]);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  useEffect(() => {
    setInitialLoading(true);
    const dropzone = new Dropzone(dropzoneRef.current, {
      // url: "http://localhost:8000/api/vehicles/updateVehicleImages/66a0e5c0f2bc0122301ebbd1",
      url: `${baseURL}/ping`,
      maxFiles: 1,
      method: "GET",
      acceptedFiles: "image/*",
      dictDefaultMessage: `
        <p className="ant-upload-drag-icon">
          <div className="img-icon-list">
            <span>
              <img src="${Cloud}" className="img-fluid" alt="img" />
            </span>
          </div>
        </p>
        <p className="ant-upload-text">Drop your files here or</p>
        <p className="ant-upload-hint">Browse</p>
      `,
      previewTemplate: document.querySelector("#custom-preview-template")
        .innerHTML,
      addRemoveLinks: false,
      init: function () {
        this.on("addedfile", function (file) {
          if (this.files.length > 1) {
            this.removeFile(this.files[0]);
          }
        });

        this.on("complete", () => {});
        this.on("sending", function (file, xhr, formData) {});

        this.on("success", function (file) {});

        this.on("error", function (file, response) {});
      },
    });

    dropzone.on("addedfile", (file) => {
      setUploadedImage(file);
    });

    // Cleanup function
    return () => {
      dropzone.destroy();
    };
  }, [initialLoading]);
  useEffect(() => {
    // Get the dropzone container element
    const dropzoneContainer = dropzoneRef.current;

    // Handler function for click events
    const handleIconClick = (event) => {
      if (event.target.matches(".fa-image")) {
        handleThumbnailImage();
        // Your logic here
      }
    };

    // Add event listener
    dropzoneContainer.addEventListener("click", handleIconClick);

    // Cleanup the event listener
    return () => {
      dropzoneContainer.removeEventListener("click", handleIconClick);
    };
  }, [initialLoading, uploadedImage]);

  const handleThumbnailImage = (image) => {
    try {
      setImageToCrop(uploadedImage.dataURL);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching image", error);
    }
  };

  const handleDeleteImage = (index) => {
    setImageURLs((prev) => prev.filter((_, i) => i !== index));
    setCarImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleOk = (values, clearForm, paymentLoading) => {
    paymentLoading.start();
    if (values.type === "card") {
      httpClient
        .post(`parts/instant-payment/${id}`, {
          type: values.type,
          payment: { ...values, amount: 150 },
        })
        .then(() => {
          setPaymentModal(false);
          setLoading(false);
          paymentLoading.success();
          navigate("/");
          clearForm();
        })
        .catch(() => {
          paymentLoading.error();
          message.error("Payment Failed");
        });
    }
  };
  const handleSubmit = () => {
    // alert();
    setLoading(true);
  };

  return (
    <PageLayout
      type="website"
      imageWidth="1920"
      title="Add Parts"
      url="https://opposingcylinders.com/"
      description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
      image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
    >
      <Spin tip="Loading..." spinning={loading} delay={200} size="large"></Spin>
      <div className="sec-pad pt-0">
        <div className="container">
          <div className="login-box">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="text-center mb-4">
                  <h2 className="h2-title mb-2 bold">List Your Vehicle Part</h2>
                </div>
                <div style={{ padding: "20px" }} className="bb-mobile">
                  <Formik
                    initialValues={{
                      partName: "",
                      partType: "",
                      price: "",
                      description: "",
                      phone: "",
                      location: "",
                      condition: "",
                    }}
                    validationSchema={Yup.object({
                      partName: Yup.string().required("Part name is required"),
                      partType: Yup.string().required("Type is required"),
                      price: Yup.number().required("Price is required"),
                      description: Yup.string().required(
                        "Description is required"
                      ),
                      phone: Yup.string().required("Phone is required"),
                      location: Yup.string().required("Location is required"),
                      condition: Yup.string().required("Condition is required"),
                    })}
                    onSubmit={async (values, actions) => {
                      setLoading(true);

                      const formData = new FormData();
                      Object.keys(values).forEach((key) => {
                        formData.append(key, values[key]);
                      });

                      // carImages.forEach((file) => {
                      //   formData.append("car_images", file);
                      // });
                      if (uploadedImage || croppedImage) {
                        formData.append("car_images", uploadedImage);
                        croppedImage &&
                          formData.append("car_images", croppedImage);
                      } else {
                        message.error("Please add an image");
                      }
                      try {
                        // Make sure to await the API call
                        const res = await httpClient.post(
                          "/parts/create-part",
                          formData,
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );

                        // Log the response to inspect its structure
                        if (
                          res &&
                          res.data &&
                          res.data.vehicle &&
                          res.data.vehicle._id
                        ) {
                          setId(res.data.vehicle._id);
                          navigate(
                            `/thanksforaddingparts/${res.data.vehicle._id}`
                          ); // Set the id correctly
                          setPaymentModal(true);
                          setAutoThumbnail([]);
                          message.success("Ad Created");
                        } else {
                          throw new Error(
                            "Invalid response structure: ID not found."
                          );
                        }
                      } catch (error) {
                        console.error("Error:", error);
                        message.error(
                          "Make sure you provide correct information and media."
                        );
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    {({
                      validateForm,
                      handleChange,
                      handleBlur,
                      setTouched,
                      setFieldValue,
                    }) => (
                      <Form>
                        <fieldset
                          className="text-center "
                          style={{ display: "block" }}
                        >
                          <div className="row mb-4 text-start">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Part Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="partName"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="make"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Part Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="partType"
                                  className="form-control fc"
                                  render={({ field }) => (
                                    <select
                                      className="form-control fc"
                                      value={field.value}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "partType",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {bodyStyle.map((style) => (
                                        <option value={style}>{style}</option>
                                      ))}
                                    </select>
                                  )}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="bodyStyle"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Price <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="price"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="price"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Mobile Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="phone"
                                  name="phone"
                                  className="form-control fc"
                                  render={({ field }) => (
                                    <PatternFormat
                                      format="+1 (###) ###-####"
                                      mask="_"
                                      // allowEmptyFormatting
                                      value={field.value}
                                      onValueChange={(value) => {
                                        setFieldValue(
                                          "phone",
                                          value.formattedValue
                                        );
                                      }}
                                      className="form-control fc"
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="number"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="mb-1">
                                  Location/Address{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="location"
                                  className="form-control fc mb-2"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="location"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Condition{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="condition"
                                  className="form-control fc"
                                  render={({ field }) => (
                                    <select
                                      className="form-control fc"
                                      value={field.value}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "condition",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {partCondition.map((style) => (
                                        <option value={style}>{style}</option>
                                      ))}
                                    </select>
                                  )}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="bodyStyle"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="mb-1">Description</label>{" "}
                                <span className="text-danger">*</span>
                                <textarea
                                  rows={10}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  name="description"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="description"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mb-4 justify-content-center">
                            <div className="col-md-8 col-lg-6">
                              <div>
                                <div className="row mb-4 justify-content-center">
                                  <br />
                                  <div
                                    style={{
                                      textAlign: "center",
                                      fontFamily: "Arial, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <p>
                                      Please upload one photo of your vehicle
                                      Parts,which we'll use as the main image.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        backgroundColor: "yellow",
                                        display: "inline",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      You can add more photos or change this
                                      photo after you've completed your listing.
                                    </p>
                                  </div>{" "}
                                  <Col span={24} md={24}>
                                    <div style={{ marginTop: "50px" }}>
                                      <div
                                        ref={dropzoneRef}
                                        className="dropzone"
                                      />
                                      <div
                                        id="custom-preview-template"
                                        style={{ display: "none" }}
                                      >
                                        <div className="dz-preview dz-file-preview">
                                          <div
                                            style={{ cursor: "pointer" }}
                                            className="dz-image"
                                          >
                                            <img
                                              data-dz-thumbnail
                                              alt="Thumbnail"
                                            />
                                          </div>
                                          <div className="dz-details">
                                            <div className="dz-size">
                                              <span data-dz-size></span>
                                            </div>
                                            <div className="dz-filename">
                                              <span data-dz-name></span>
                                            </div>
                                          </div>
                                          <div className="dz-progress">
                                            <span
                                              className="dz-upload"
                                              data-dz-uploadprogress
                                            ></span>
                                          </div>
                                          <div className="dz-error-message">
                                            <span data-dz-errormessage></span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <i
                                                className="fas fa-regular fa-image"
                                                style={{
                                                  padding: "8px",
                                                  fontSize: "24px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleThumbnailImage();
                                                }}
                                              />
                                            </div>
                                            <i
                                              className="fas fa-times dz-remove"
                                              data-dz-remove
                                              style={{
                                                padding: "8px",
                                                fontSize: "24px",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                                {/* Custom file input */}
                                {/* <label className="custom-file-upload">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    className="d-none"
                                    onChange={handleFileChange}
                                  />
                                  <div
                                    className="border-dashed-box flex-center"
                                    id="OpenImgUpload"
                                  >
                                    <div className="img-icon-list">
                                      <span>
                                        <img
                                          src={Cloud}
                                          className="img-fluid"
                                          alt="img"
                                        />
                                      </span>
                                    </div>
                                    <p className="h3-title">
                                      Drop your files here or{" "}
                                      <span className="text-theme">Browse</span>
                                    </p>
                                  </div>
                                </label> */}
                              </div>
                            </div>
                            {/* Display uploaded images */}
                            <div className="row row-cols-2 row-cols-lg-4 row-cols-md-4 mb-4 g-3 justify-content-center">
                              {imageURLs?.map((imageURL, index) => (
                                <div className="col " key={index}>
                                  <div className="position-relative">
                                    <div className="upload-image-delete">
                                      <div
                                      // data-bs-toggle="modal"
                                      // data-bs-target="#deleteModal"
                                      >
                                        <span
                                          className="icons-hover"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="bottom"
                                          title=""
                                          data-bs-original-title="Delete"
                                          onClick={() =>
                                            handleDeleteImage(index)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16.037"
                                            className="share-icon-white cr"
                                            height="19.738"
                                            viewBox="0 0 16.037 19.738"
                                          >
                                            <path
                                              id="trash"
                                              d="M16.5,6.067h3.7A1.165,1.165,0,0,1,21.437,7.3V8.534H5.4V7.3A1.236,1.236,0,0,1,6.634,6.067h3.7a3.161,3.161,0,0,1,6.168,0Zm-4.934,0h3.7a2,2,0,0,0-3.7,0Zm-4.934,3.7H20.2l-1.11,12.459a1.217,1.217,0,0,1-1.234,1.11H8.977a1.312,1.312,0,0,1-1.234-1.11Z"
                                              transform="translate(-5.4 -3.6)"
                                              fill="#f15757"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                    <img
                                      src={imageURL}
                                      className=" br-5"
                                      alt={`Image ${index}`}
                                      width={"100%"}
                                      height={"200px"}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <button
                            // type="submit"
                            name="Add Part"
                            className="next action-button btn btn-theme-fill"
                            onClick={() => {
                              validateForm().then((errors) => {
                                if (Object.keys(errors).length === 0) {
                                  handleSubmit();
                                } else {
                                  setTouched(errors);
                                  return errors;
                                }
                              });
                            }}
                          >
                            {loading ? (
                              <span>
                                <i className="spinner-border spinner-border-sm"></i>{" "}
                                Adding Part...
                              </span>
                            ) : (
                              "Add Part"
                            )}
                          </button>
                        </fieldset>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <div className="lb-right join-right-box">
                  <div className="text-center text-white1">
                    <img src={Image} className="img-fluid mb-3" alt="img" />
                  </div>

                  <div className="align-items-start mb-2 d-flex text-white1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="42"
                      className="mt-2"
                      height="42"
                      viewBox="0 0 23.583 12.612"
                    >
                      <g id="check-all" transform="translate(-4.682 -10.647)">
                        <path
                          id="Path_22"
                          data-name="Path 22"
                          d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                          fill="#03cb3f"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>
                    <div className="ms-2">
                      Listing will be posted on IG, FB, and the Opposing
                      Cylinders Website
                    </div>
                  </div>
                  <div className="align-items-start mb-2 d-flex text-white1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.583"
                      className="mt-2"
                      height="12.612"
                      viewBox="0 0 23.583 12.612"
                    >
                      <g id="check-all" transform="translate(-4.682 -10.647)">
                        <path
                          id="Path_22"
                          data-name="Path 22"
                          d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                          fill="#03cb3f"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>
                    <div className="ms-2">Up to 25 photos</div>
                  </div>
                  <div className="align-items-start mb-2 d-flex text-white1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.583"
                      className="mt-2"
                      height="12.612"
                      viewBox="0 0 23.583 12.612"
                    >
                      <g id="check-all" transform="translate(-4.682 -10.647)">
                        <path
                          id="Path_22"
                          data-name="Path 22"
                          d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                          fill="#03cb3f"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>
                    <div className="ms-2">Unlimited Listing Duration</div>
                  </div>
                  <div className="align-items-start mb-2 d-flex text-white1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.583"
                      className="mt-2"
                      height="12.612"
                      viewBox="0 0 23.583 12.612"
                    >
                      <g id="check-all" transform="translate(-4.682 -10.647)">
                        <path
                          id="Path_22"
                          data-name="Path 22"
                          d="M18.014,11.107a1.452,1.452,0,1,1,2.073,2.033L12.361,22.8a1.452,1.452,0,0,1-2.09.039L5.152,17.714A1.452,1.452,0,1,1,7.2,15.663l4.053,4.05,6.718-8.563a.487.487,0,0,1,.039-.042Zm-1.78,9.946,1.78,1.78A1.452,1.452,0,0,0,20.1,22.8l7.725-9.657a1.452,1.452,0,1,0-2.111-1.989l-6.721,8.563-.938-.94-1.825,2.282Z"
                          fill="#03cb3f"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>
                    <div className="ms-2">Unlimited Price Changes</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaymentModal
        visible={paymentModal}
        onCancel={() => {
          setPaymentModal(false);
          navigate("/");
        }}
        onOk={handleOk}
        showSkipButton
        forAddVehicle
        amount="150.00"
      />
      <StyledDemo
        imageToCrop={imageToCrop}
        {...{ open, setOpen, croppedImage, setCroppedImage }}
      />
    </PageLayout>
  );
};

export default AddPart;
