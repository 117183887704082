import React, { useState } from "react";
import { Modal, Form, Input, Button, Divider, Spin, message } from "antd";
import { useAuth } from "../../authentication";
import { PatternFormat } from "react-number-format";
import "./card.css";

const MemberShipModel = ({ visible, onCancel, onOk, showSkipButton }) => {
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState("");
  const [form] = Form.useForm();
  const { authUser } = useAuth();
  function clearForm() {
    form.resetFields();
  }
  const laodingFunctions = {
    start: () => {
      setLoading(true);
    },
    success: () => {
      setLoading(false);
      onCancel();
    },
    error: (error) => {
      setLoading(false);
      message.error("Please use valid payment source.");
    },
  };

  const onChange = (e) => {
    setPayment(e.target.value);
  };

  const handleOk = async () => {
    try {
      await form.validateFields().then((values) => {
        if (authUser.country === "Canada" && values.zip.length === 3) {
          // Append two zeros to the ZIP code
          values.zip = `${values.zip}00`;
        }
        if (!payment) {
          return message.error("Please choose a payment plan");
        }

        onOk(values, clearForm, laodingFunctions);
      });
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  return (
    <Spin tip="Loading..." spinning={loading} delay={200} size="large">
      <Modal
        title="Card Payment"
        visible={visible}
        onCancel={onCancel}
        onOk={handleOk}
        footer={null}
      >
        <div>
          {payment === "19.99" ? (
            <>
              {" "}
              <b>Amount: </b>$ 19.99 <b>per month</b>
            </>
          ) : (
            <>
              {" "}
              <b>Amount: </b>$ 219.89 <b>per year</b>
            </>
          )}
        </div>

        <Form form={form} layout="vertical">
          <>
            <Form.Item
              initialValue="monthly"
              name="payment"
              rules={[
                {
                  required: true,
                  message: "Please choose a payment plan",
                },
              ]}
            >
              <div className="">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <label>
                      <input
                        value="19.99"
                        type="radio"
                        name="payment"
                        className="card-input-element"
                        onChange={onChange}
                      />

                      <div className="panel panel-default card-input">
                        <div className="payment-selection-container">
                          <div className="panel-heading">Pay Monthly</div>
                          <div className="panel-heading">$19.99 per month</div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <label>
                      <input
                        value="219.89"
                        type="radio"
                        name="payment"
                        className="card-input-element"
                        onChange={onChange}
                      />

                      <div className="panel panel-default card-input">
                        <div className="payment-selection-container">
                          <div className="panel-heading">Annual Membership</div>
                          <div className="panel-heading">
                            $219.89 per year
                            <div>
                              <small>saving $19.99 a year</small>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </Form.Item>
            <Form.Item
              label="Cardholder Name"
              name="cardholder"
              rules={[
                {
                  required: true,
                  message: "Please enter the cardholder name",
                },
              ]}
            >
              <Input placeholder="Card Holder Name" />
            </Form.Item>
            <Form.Item
              label="Card Number"
              name="cardNumber"
              // initialValue="4242 4242 4242 4242"
              rules={[
                { required: true, message: "Please enter the card number" },
              ]}
            >
              <PatternFormat
                placeholder="0000 0000 0000 0000"
                type="tel"
                className="ant-input"
                format="#### #### #### ####"
                mask="_"
              />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                style={{ width: "48%" }}
                label="Expiration Date"
                name="expiry"
                rules={[
                  {
                    required: true,
                    message: "Please enter the expiration date",
                  },
                ]}
              >
                <PatternFormat
                  placeholder="MM/YY"
                  type="tel"
                  className="ant-input"
                  format="##/##"
                  mask="_"
                />
              </Form.Item>

              <Form.Item
                style={{ width: "48%" }}
                label="CVC"
                name="cvc"
                rules={[{ required: true, message: "Please enter the CVC" }]}
              >
                <PatternFormat
                  placeholder="000"
                  type="tel"
                  className="ant-input"
                  format="###"
                  mask="_"
                />
              </Form.Item>
            </div>
            <div
              style={{ color: "red", marginBottom: "8px", fontWeight: "bold" }}
            >
              Enter the three digits from your postal code
            </div>
            <Form.Item
              label="ZIP Code"
              name="zip"
              rules={[{ required: true, message: "Please enter the ZIP code" }]}
            >
              <PatternFormat
                type="tel"
                className="ant-input"
                format={authUser?.country === "Canada" ? "###" : "#####"}
                mask="_"
              />
            </Form.Item>
          </>

          <Divider />

          <div style={{ textAlign: "right" }}>
            {showSkipButton && (
              <Button
                loading={loading}
                danger
                onClick={onCancel}
                className="me-3"
              >
                skip payment
              </Button>
            )}
            <Button loading={loading} type="primary" onClick={handleOk}>
              Pay Now
            </Button>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
};

export default MemberShipModel;
