import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { WASABI_URL, baseURL } from "../../util/Api";
import ShareWidget from "../ShareWidget";
import LikeWidget from "../LikeWidget";
import Resizer from "react-image-file-resizer";
import { Spin, Tooltip } from "antd";
import "./hero.css";

const HeroCard = ({
  deviceType,
  id,
  src,
  noOfLike,
  title,
  price,
  year,
  noOfImage,
  location,
  thumbnail,
  isLiked,
  isThumbnail,
  oldImage,
}) => {
  const [resizedImageUrl, setResizedImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  console.log("deviceType inner card", deviceType);

  const urlToBlob = async (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error("Failed to load image"));
        }
      };
      xhr.onerror = () => {
        reject(new Error("Network error"));
      };
      xhr.send();
    });
  };

  useEffect(() => {
    const url = isThumbnail && `${WASABI_URL}${oldImage?.path}`;
    resizeAndDisplayImage(url);
  }, [oldImage, isThumbnail, thumbnail]);

  const resizeAndDisplayImage = async (url) => {
    try {
      setLoading(true);
      const blob = await urlToBlob(url);
      Resizer.imageFileResizer(
        blob,
        300,
        225,
        "JPEG",
        100,
        0,
        (resizedUri) => {
          setResizedImageUrl(resizedUri);
          setLoading(false);
        },
        "base64",
        300,
        225,
        "center"
      );
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  return (
    <div className="col">
      <div className="cd-box">
        <div className="img-box">
          <div className="cb-img" style={{ margin: 0 }}>
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <Link to={`/vehicle-detail/${id}`} style={{ height: "100%" }}>
                {loading ? (
                  <Spin
                    tip="Loading..."
                    spinning={loading}
                    delay={200}
                    size="large"
                  ></Spin>
                ) : (
                  <img
                    className={
                      deviceType == "iPhone/iOS"
                        ? "heroCustomImgOnMobile"
                        : "heroCustomImgOnDesktop"
                    }
                    // style={{ height: "225px", width: "300px" }}
                    src={
                      isThumbnail
                        ? // ? resizedImageUrl
                          WASABI_URL + thumbnail
                        : resizedImageUrl
                    }
                    alt="Local Image"
                  />
                )}
              </Link>
              <div
                style={{
                  position: "absolute",
                  top: 170,
                  left: 0,
                  right: 0,
                  bottom: -10,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  color: "white",
                  zIndex: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  backdropFilter: "blur(-10px)",
                }}
              >
                <div className="text-white d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className="bi bi-images me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                    <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                  </svg>{" "}
                  {noOfImage}
                </div>
                <div>
                  <LikeWidget
                    isLiked={isLiked}
                    vehicleId={id}
                    numberOfLikes={noOfLike}
                    className="text-white"
                  />
                  <ShareWidget
                    className="d-inline-block"
                    shareUrl={window.location.href}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to={`/vehicle-detail/${id}`} style={{ height: "100%" }}>
          <div
            className="cdb-details"
            style={{ padding: "15px 10px", height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div>
                <div
                  className="bedge d-inline-block"
                  style={{ float: "right" }}
                >
                  ${price}
                </div>
              </div>
              <div className="flexmedia">
                <div className="headiing">
                  {title.length > 35 ? (
                    <Tooltip title={title}>
                      {title.slice(0, 30) + "..."}
                    </Tooltip>
                  ) : (
                    title
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HeroCard;
