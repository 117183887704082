import React, { useState, useEffect } from "react";
import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import { NavLink } from "react-router-dom";
import VideoList from "../../Data/VideoList";
import { useParams } from "react-router-dom";
import { baseURL, httpClient } from "../../util/Api";
import moment from "moment";

function VideoDetail() {
  const { id } = useParams();
  const [video, setVideo] = useState({});
  const fetchVideos = async () => {
    try {
      const response = await httpClient.get(`files/videos/${id}`);
      setVideo(response.data);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);
  return (
    <>
      <PageLayout
        type="website"
        imageWidth="1920"
        title={video?.title}
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <div className="sec-pad pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <NavLink to="/video">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>{" "}
                  Back
                </NavLink>
                <h2 className="bold h2-title mt-2 mb-3">{video?.title}</h2>

                <div className="d-md-flex d-block justify-content-between align-items-center">
                  <div className="mb-2 mb-md-0">
                    <div className="d-inline-flex align-items-center me-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-1"
                        width="11.253"
                        height="12.861"
                        viewBox="0 0 11.253 12.861"
                      >
                        <path
                          id="calendar"
                          d="M.3,4.823h10.65a.3.3,0,0,1,.3.3v6.531a1.206,1.206,0,0,1-1.206,1.206H1.206A1.206,1.206,0,0,1,0,11.655V5.124A.3.3,0,0,1,.3,4.823ZM11.253,3.717v-.9a1.206,1.206,0,0,0-1.206-1.206H8.842V.3a.3.3,0,0,0-.3-.3h-1a.3.3,0,0,0-.3.3V1.608H4.019V.3a.3.3,0,0,0-.3-.3h-1a.3.3,0,0,0-.3.3V1.608H1.206A1.206,1.206,0,0,0,0,2.813v.9a.3.3,0,0,0,.3.3h10.65a.3.3,0,0,0,.3-.3Z"
                          fill="#234579"
                        />
                      </svg>
                      {moment(video?.date).format("DD MMMM, YYYY")}
                    </div>
                    <div className="d-inline-flex align-items-center"></div>
                  </div>
                  <div>
                    <div className="me-2  d-inline-flex align-items-center">
                      {video?.noOFLike} &nbsp;
                      <span
                        className="icons-hover"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title=""
                        data-bs-original-title="Like"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={18}
                          height={18}
                          fill="currentColor"
                          className="bi bi-heart   cr heart-icon-red"
                          viewBox="0 0 16 16"
                        >
                          <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                        </svg>
                      </span>
                    </div>
                    <div className="d-inline-block">
                      <span
                        className="icons-hover"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title=""
                        data-bs-original-title="Share"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={18}
                          height={18}
                          fill="currentColor"
                          className="bi bi-share  cr"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <p className="mt-4">{video?.detail}</p>
                <div className="ratio ratio-16x9">
                  {video?.type === "cloud" ? (
                    <video
                      style={{ width: "100%" }}
                      controls
                      src={`${baseURL}files/get-video/${video?.video?.path}`}
                    />
                  ) : (
                    <iframe
                      style={{ width: "100%", height: "100%" }}
                      src={`https://www.youtube.com/embed/${video?.youtube?.url}`}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <JoinClub />
      </PageLayout>
    </>
  );
}

export default VideoDetail;
