import { useNavigate } from "react-router-dom";
import Slider from "react-slick"; // Import the Slick Slider component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./hero.css"; // Your custom CSS
import img from "../../Assets/Images/membership-section-revised.jpg";
import logo1 from "../../Assets/Images/vendor-benefits/aceofshades.jpg";
import logo2 from "../../Assets/Images/vendor-benefits/california.jpg";
import logo3 from "../../Assets/Images/vendor-benefits/greeningauto.jpg";
import logo4 from "../../Assets/Images/vendor-benefits/precision-hotrods-logo.png";
import logo5 from "../../Assets/Images/vendor-benefits/ricks-logo.png";
import logo6 from "../../Assets/Images/vendor-benefits/socal-speedshop-logo.png";
import logo7 from "../../Assets/Images/vendor-benefits/sweetpatina.jpg";
import logo8 from "../../Assets/Images/vendor-benefits/vintagepro.png";

const BecomeMember = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/profile");
  };

  // Slick settings for the carousel
  const settings = {
    dots: false, // Hide dots below the carousel
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 5, // Show 4 logos at a time
    slidesToScroll: 1, // Scroll one logo at a time
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 3000, // Auto-scrolling interval (3 seconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="hero-container">
        <img src={img} alt="Become a member" className="hero-image" />
        <div className="hero-content">
          <h1 className="hero-title">
            Become a Member of
            <br /> Opposing Cylinders!
          </h1>
          <p className="hero-description">
            Club Members get FIRST ACCESS to premium vehicle listings and have
            access to our exclusive member vendor-partner discounts!
            <br />
            Crew Members receive all the perks of Club Members and can enter
            Opposing Cylinders giveaway events!
          </p>
          <div className="hero-button-wrapper">
            <button
              onClick={handleClick}
              className="btn btn-theme-fill rounded mb-4 px-2"
            >
              Become a Member Today!
            </button>
          </div>
        </div>
      </div>

      {/* Logo Carousel */}
      <div className="logo-carousel-wrapper ">
        <Slider {...settings}>
          <div>
            <img src={logo1} alt="Logo 1" />
          </div>
          <div>
            <img src={logo2} alt="Logo 2" />
          </div>
          <div>
            <img src={logo3} alt="Logo 3" />
          </div>
          <div>
            <img src={logo4} alt="Logo 4" />
          </div>
          <div>
            <img src={logo5} alt="Logo 5" style={{ marginTop: "26px" }} />
          </div>
          <div>
            <img src={logo6} alt="Logo 6" style={{ marginTop: "26px" }} />
          </div>
          <div>
            <img src={logo7} alt="Logo 7" />
          </div>
          <div>
            <img src={logo8} alt="Logo 8" />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default BecomeMember;
