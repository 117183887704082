import React from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../Layout/PageLayout";
import MembershipFeatures from "../../Data/MembershipFeature.js";
import Slider from "react-slick"; // Import Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./hero.css";
import logo1 from "../../Assets/Images/vendor-benefits/aceofshades.jpg";
import logo2 from "../../Assets/Images/vendor-benefits/california.jpg";
import logo3 from "../../Assets/Images/vendor-benefits/greeningauto.jpg";
import logo4 from "../../Assets/Images/vendor-benefits/precision-hotrods-logo.png";
import logo5 from "../../Assets/Images/vendor-benefits/ricks-logo.png";
import logo6 from "../../Assets/Images/vendor-benefits/socal-speedshop-logo.png";
import logo7 from "../../Assets/Images/vendor-benefits/sweetpatina.jpg";
import logo8 from "../../Assets/Images/vendor-benefits/vintagepro.png";
const MemberShipPackages = () => {
  const navigate = useNavigate();

  const handlePackageSelection = (packageType) => {
    // Store the selected package type in localStorage
    localStorage.setItem("selectedPackage", packageType);

    // Check if the user is logged in (this assumes you have a way to check)
    const isLoggedIn = localStorage.getItem("token"); // Replace with actual auth check

    if (isLoggedIn) {
      console.log("yes logged in", packageType);
      // If logged in, redirect to the list vehicle step page
      navigate(`/list-vehicle-step?type=${packageType}`);
    } else {
      console.log("not logged in");
      // If not logged in, redirect to the sign-up page
      navigate("/join");
    }
  };

  // Slick settings for the vendor carousel
  const settings = {
    dots: false, // Disable dots
    infinite: true, // Infinite loop
    speed: 500, // Speed of the transition
    slidesToShow: 5, // Show 4 items at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Autoplay
    autoplaySpeed: 3000, // Autoplay interval (3 seconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <PageLayout
      type="website"
      imageWidth="1920"
      title="List Vehicle"
      url="https://opposingcylinders.com/"
      description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
      image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
    >
      <div className="sec-pad pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center mb-1">
                <h2 className="h2-title mb-2 bold">Become a Member</h2>
                <p>
                  Sign up as a Club Member or Crew Member and get exclusive
                  benefits
                  <br /> from being a part of the Opposing Cylinders community!
                </p>
              </div>
              <div className="">
                <div className=" price-table-section">
                  <div className="row justify-content-center">
                    {/* Membership benefits */}
                    <div className="col-lg-12 col-xl-4 col-xxl-4 col-xs-12 pricetable-label">
                      <div className="price-table pt-radius1">
                        <div className="price-header">
                          <h5 className="pricetype bold text-white1 text-start">
                            Membership Benefits
                          </h5>
                        </div>
                        <div className="price-details">
                          <ul className="price-list text-start">
                            {MembershipFeatures.map((feature, index) => (
                              <li key={index} className="lh-2">
                                <div className="d-flex align-items-start">
                                  {/* SVG icon */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12.91"
                                    height="11.912"
                                    className="me-2 mt-1"
                                    viewBox="0 0 15.91 15.912"
                                  >
                                    <g
                                      id="arrow-left-square"
                                      transform="translate(22.096 22.098) rotate(180)"
                                    >
                                      <path
                                        id="Path_24"
                                        data-name="Path 24"
                                        d="M18.883,25.7a1.136,1.136,0,0,0,0-1.609l-6.015-6.013,6.016-6.014a1.138,1.138,0,1,0-1.609-1.609l-6.818,6.818a1.136,1.136,0,0,0,0,1.609L17.275,25.7a1.136,1.136,0,0,0,1.609,0Z"
                                        transform="translate(-3.937 -3.937)"
                                        fill="#d9e4ee"
                                        fillRule="evenodd"
                                      />
                                      <path
                                        id="Path_25"
                                        data-name="Path 25"
                                        d="M26.011,18.011a1.136,1.136,0,0,0-1.136-1.136H13.511a1.136,1.136,0,0,0,0,2.273H24.874A1.136,1.136,0,0,0,26.011,18.011Z"
                                        transform="translate(-3.914 -3.869)"
                                        fill="#d9e4ee"
                                        fillRule="evenodd"
                                      />
                                    </g>
                                  </svg>
                                  <div>{feature.title}</div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* Pricing tables */}
                    <div className="col-lg-12 col-xl-2 col-xs-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h5 className="pricetype bold text-theme">
                            Club Members
                          </h5>
                        </div>
                        <div className="price-details">
                          <ul className="price-list">
                            {MembershipFeatures.map((feature, index) => (
                              <li className=" lh-2" key={index}>
                                <span>{feature.title}</span>
                                {feature.club}
                                {feature.club === true && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="20"
                                    width="24"
                                    viewBox="0 0 448 512"
                                    fill="#09b715"
                                  >
                                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                  </svg>
                                )}
                                {feature.club === false && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.827"
                                    height="16.771"
                                    viewBox="0 0 16.827 16.771"
                                  >
                                    <path
                                      id="close"
                                      d={
                                        "M16.827,5.72l-6.753,6.7,6.753,6.7L15.139,20.8l-6.753-6.7-6.7,6.7L0,19.114l6.7-6.7L0,5.72,1.689,4.031l6.7,6.7,6.753-6.7Z"
                                      }
                                      transform="translate(0 -4.031)"
                                      fill="#d81919"
                                    />
                                  </svg>
                                )}
                              </li>
                            ))}

                            <li className="pricing-t d-block">
                              <div className="price-value h3-title bold">
                                $19.99 <br /> per month
                              </div>
                              <button
                                onClick={() =>
                                  handlePackageSelection("premium")
                                }
                                className="btn btn-theme"
                              >
                                Purchase
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-xl-2 col-xs-12">
                      <div className="price-table bg-success h-100">
                        <div className="price-header">
                          <h5 className="pricetype bold text-theme">
                            Crew Members
                          </h5>
                        </div>
                        <div className="price-details">
                          <ul className="price-list">
                            {MembershipFeatures.map((feature, index) => (
                              <li key={index} className="text-success lh-2">
                                <span>{feature.title}</span>
                                {feature.crew}
                                {feature.crew === true && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="20"
                                    width="24"
                                    viewBox="0 0 448 512"
                                    fill="#09b715"
                                  >
                                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                  </svg>
                                )}
                                {feature.crew === false && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.827"
                                    height="16.771"
                                    viewBox="0 0 16.827 16.771"
                                  >
                                    <path
                                      id="close"
                                      d={
                                        "M16.827,5.72l-6.753,6.7,6.753,6.7L15.139,20.8l-6.753-6.7-6.7,6.7L0,19.114l6.7-6.7L0,5.72,1.689,4.031l6.7,6.7,6.753-6.7Z"
                                      }
                                      transform="translate(0 -4.031)"
                                      fill="#d81919"
                                    />
                                  </svg>
                                )}
                              </li>
                            ))}

                            <li className="pricing-t d-block">
                              <div className="price-value h3-title bold">
                                $219.89 <br /> per year
                              </div>
                              <button
                                onClick={() =>
                                  handlePackageSelection("premium_plus")
                                }
                                className="btn btn-theme-fill"
                              >
                                Purchase
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h3
          className="text-center"
          style={{ textAlign: "center", marginTop: "24px" }}
        >
          Our Vendor Partners with Member Benefits
        </h3>
      </div>

      {/* Slick Carousel */}
      <div className="logo-carousel-wrapper">
        <Slider {...settings}>
          <div>
            <img src={logo1} alt="Logo 1" />
          </div>
          <div>
            <img src={logo2} alt="Logo 2" />
          </div>
          <div>
            <img src={logo3} alt="Logo 3" />
          </div>
          <div>
            <img src={logo4} alt="Logo 4" />
          </div>
          <div>
            <img src={logo5} alt="Logo 5" style={{ marginTop: "26px" }} />
          </div>
          <div>
            <img src={logo6} alt="Logo 6" style={{ marginTop: "26px" }} />
          </div>
          <div>
            <img src={logo7} alt="Logo 7" />
          </div>
          <div>
            <img src={logo8} alt="Logo 8" />
          </div>
        </Slider>
      </div>
    </PageLayout>
  );
};

export default MemberShipPackages;
