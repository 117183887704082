const MembershipFeatures = [
  {
    title: "Invites to Opposing Cylinder Events",
    club: false,
    crew: true,
  },
  {
    title: "Entry to Drawing Giveaway Events",
    club: false,
    crew: true,
  },
  {
    title: "Early Access to Premium Listings",
    club: true,
    crew: true,
  },
  {
    title: "Access to Vendor Partner Discounts",
    club: true,
    crew: true,
  },
];

export default MembershipFeatures;
