import React, { useState } from "react";
import { Modal, Form, Input, Button, Divider, Spin, message } from "antd";
import { PatternFormat } from "react-number-format";

function ReSubscribeModal({ visible, onCancel, onOk, membershipAmount }) {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  function clearForm() {
    form.resetFields();
  }
  const laodingFunctions = {
    start: () => {
      setLoading(true);
    },
    success: () => {
      setLoading(false);
    },
    error: (error) => {
      setLoading(false);
      message.error("Please use valid payment source.");
    },
  };
  const handleOk = async () => {
    try {
      await form.validateFields().then((values) => {
        onOk(values, clearForm, laodingFunctions);
      });
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  return (
    <Spin tip="Loading..." spinning={loading} delay={200} size="large">
      <Modal
        title="Card Payment"
        visible={visible}
        onCancel={onCancel}
        onOk={handleOk}
        footer={null}
      >
        {membershipAmount && (
          <div>
            <b>Amount: </b>$ {membershipAmount}
          </div>
        )}
        <Form form={form} layout="vertical">
          <Form.Item
            label="Cardholder Name"
            name="cardholder"
            rules={[
              {
                required: true,
                message: "Please enter the cardholder name",
              },
            ]}
          >
            <Input placeholder="Card Holder Name" />
          </Form.Item>
          <Form.Item
            label="Card Number"
            name="cardNumber"
            // initialValue="4242 4242 4242 4242"
            rules={[
              { required: true, message: "Please enter the card number" },
            ]}
          >
            <PatternFormat
              placeholder="0000 0000 0000 0000"
              type="tel"
              className="ant-input"
              format="#### #### #### ####"
              mask="_"
            />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              style={{ width: "48%" }}
              label="Expiration Date"
              name="expiry"
              rules={[
                {
                  required: true,
                  message: "Please enter the expiration date",
                },
              ]}
            >
              <PatternFormat
                placeholder="MM/YY"
                type="tel"
                className="ant-input"
                format="##/##"
                mask="_"
              />
            </Form.Item>

            <Form.Item
              style={{ width: "48%" }}
              label="CVC"
              name="cvc"
              rules={[{ required: true, message: "Please enter the CVC" }]}
            >
              <PatternFormat
                placeholder="000"
                type="tel"
                className="ant-input"
                format="###"
                mask="_"
              />
            </Form.Item>
          </div>

          <Form.Item
            label="ZIP Code"
            name="zip"
            rules={[{ required: true, message: "Please enter the ZIP code" }]}
          >
            <PatternFormat
              type="tel"
              className="ant-input"
              format="#####"
              mask="_"
            />
          </Form.Item>

          <Divider />

          <div style={{ textAlign: "right" }}>
            <Button loading={loading} type="primary" onClick={handleOk}>
              Pay Now
            </Button>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
}

export default ReSubscribeModal;
