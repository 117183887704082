const newzealand = [
  { name: "Select Region", shortCode: "" },
  {
    name: "Auckland",
    shortCode: "AUK",
  },
  {
    name: "Bay of Plenty",
    shortCode: "BOP",
  },
  {
    name: "Canterbury",
    shortCode: "CAN",
  },
  {
    name: "Gisborne",
    shortCode: "GIS",
  },
  {
    name: "Hawke's Bay",
    shortCode: "HKB",
  },
  {
    name: "Manawatu-Wanganui",
    shortCode: "MWT",
  },
  {
    name: "Marlborough",
    shortCode: "MBH",
  },
  {
    name: "Nelson",
    shortCode: "NSN",
  },
  {
    name: "Northland",
    shortCode: "NTL",
  },
  {
    name: "Otago",
    shortCode: "OTA",
  },
  {
    name: "Southland",
    shortCode: "STL",
  },
  {
    name: "Taranaki",
    shortCode: "TKI",
  },
  {
    name: "Tasman",
    shortCode: "TAS",
  },
  {
    name: "Waikato",
    shortCode: "WKO",
  },
  {
    name: "Wellington",
    shortCode: "WGN",
  },
  {
    name: "West Coast",
    shortCode: "WTC",
  },
];
export default newzealand;
