import React, { useState, useEffect } from "react";
import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import VehiclesCard from "../../Component/Cards/VehiclesCard";
import { NavLink } from "react-router-dom";
import VehicleList from "../../Data/VehicleList";
import ReactPaginate from "react-paginate";
import { httpClient } from "../../util/Api";
import { Button, Form, Space, Input } from "antd";
import SoldVehiclesCard from "../../Component/Cards/SoldVehiclesCard";

const RecentSoldVehicles = () => {
  // State for vehicle data
  const [vehicles, setVehicles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const videosPerPage = 9;
  const offset = currentPage * videosPerPage;
  const videoSlice = vehicles.slice(offset, offset + videosPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const fetchVehicles = async () => {
    try {
      const response = await httpClient.get("vehicles/sold");
      setVehicles(response.data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };
  useEffect(() => {
    fetchVehicles();
  }, []);
  return (
    <>
      <PageLayout
        type="website"
        imageWidth="1920"
        title="Recently Sold Vehicles"
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <div className="sec-pad pt-0">
          <div className="container">
            <div className="row mb-4 mb-md-5">
              <div className="col-12 text-center">
                <h2 className="bold h2-title mb-3">Our Sold Vehicles</h2>
                <p>Want to list your car?</p>
                <NavLink to="/list-your-vehicle" className="btn btn-theme">
                  List a Vehicle
                </NavLink>
              </div>
            </div>
            <div className="row">
              {videoSlice.map((item, index) => (
                <div className="col-12 col-lg-4 col-md-6 g-4">
                  <SoldVehiclesCard
                    id={item._id}
                    key={item._id} // Remember to add a unique key prop for each child element in a list
                    title={[item.year, item.make, item.model].join(" ")}
                    thumbnail={item.carImagesId.images[0]}
                  />
                </div>
              ))}
            </div>

            <div className="row mt-4 mt-lg-5">
              <div className="col-12 text-end">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(vehicles.length / videosPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={
                    "pagination d-flex justify-content-end page-item"
                  }
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>

        <JoinClub />
      </PageLayout>
    </>
  );
};

export default RecentSoldVehicles;
