import React, { Component } from "react";
import Icon from "../../Assets/Images/icon/play-filled.svg";
import { NavLink } from "react-router-dom";
import Image from "../../Assets/Images/icon/play-filled.svg";
import { baseURL } from "../../util/Api";
import moment from "moment/moment";
export class VideoCard extends Component {
  render() {
    const { type, createdAt, likes, title, id, userId } = this.props.video;
    return (
      <>
        <div className="col">
          <div
            style={{ width: "400px", height: "225px" }}
            className="v-box mb-2 position-relative cr"
          >
            <iframe
              title="WHERE DO WE GO NEXT?"
              src={this.props.video.url}
              width="100%"
              height="100%"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <>
              {/* <div
                style={{ width: "400px", height: "225px" }}
                dangerouslySetInnerHTML={{
                  __html: `<iframe width="100%" height="100%" src=${this.props.video.url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                }}
              /> */}
            </>
          </div>
          {/* <div className="mt-2 mb-2 d-flex align-items-center justify-content-between small text-theme">
            <div className="d-inline-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="me-1"
                width="11.253"
                height="12.861"
                viewBox="0 0 11.253 12.861"
              >
                <path
                  id="calendar"
                  d="M.3,4.823h10.65a.3.3,0,0,1,.3.3v6.531a1.206,1.206,0,0,1-1.206,1.206H1.206A1.206,1.206,0,0,1,0,11.655V5.124A.3.3,0,0,1,.3,4.823ZM11.253,3.717v-.9a1.206,1.206,0,0,0-1.206-1.206H8.842V.3a.3.3,0,0,0-.3-.3h-1a.3.3,0,0,0-.3.3V1.608H4.019V.3a.3.3,0,0,0-.3-.3h-1a.3.3,0,0,0-.3.3V1.608H1.206A1.206,1.206,0,0,0,0,2.813v.9a.3.3,0,0,0,.3.3h10.65a.3.3,0,0,0,.3-.3Z"
                  fill="#234579"
                />
              </svg>
              {moment(createdAt).format("DD MMMM YYYY")}
            </div>
            <div className="d-inline-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.568"
                className="me-1"
                height="11.568"
                viewBox="0 0 11.568 11.568"
              >
                <path
                  id="user"
                  d="M11.784,6A2.892,2.892,0,1,1,8.892,8.892,2.891,2.891,0,0,1,11.784,6Zm0,11.568s5.784,0,5.784-1.446c0-1.735-2.82-3.615-5.784-3.615S6,14.387,6,16.122C6,17.568,11.784,17.568,11.784,17.568Z"
                  transform="translate(-6 -6)"
                  fill="#234579"
                />
              </svg>
              {userId && `${userId?.firstName} ${userId?.lastName}`}
            </div>
          </div> */}
          <NavLink to={`/video-detail/${id}`} className="ahover">
            {title}
          </NavLink>
        </div>
      </>
    );
  }
}

export default VideoCard;
