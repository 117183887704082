import React, { useState, useEffect, useRef } from "react";
import PageLayout from "../../Layout/PageLayout";
import Cloud from "../../Assets/Images/upload-cloud-svgrepo-com.svg";
import { CiImageOn } from "react-icons/ci";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DeleteOutlined } from "@ant-design/icons";
import { WASABI_URL, baseURL, httpClient } from "../../util/Api";
import { Spin, Tooltip, Upload, message, Popconfirm, Button, Col } from "antd";
import StyledDemo from "../ListVehicleStep/ImageCropper";
import CustomLoader from "../../Component/CustomLoader/CustomLoader";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dropzone, { getElement } from "dropzone";
import "dropzone/dist/dropzone.css";
import getDeviceType from "../../util/deviceType";
import { useParams } from "react-router-dom";
import "./style.css";
import axios from "axios";

const bodyStyle = [
  "--",
  "Sedan",
  "Coupe",
  "Convertible",
  "Hatchback",
  "Station Wagon",
  "SUV",
  "Minivan",
  "Truck",
];
const transmissionType = ["--", "Automatic", "Manual"];

const VehicleEdit = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageURLs, setImageURLs] = useState([]);
  const [carImages, setCarImages] = useState([]);
  const [dragId, setDragId] = useState("");

  const [data, setData] = useState({});
  const [originalImage, setOriginalImage] = useState([]);
  const [angles, setAngles] = useState({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });
  const [areaZoom, setAreaZoom] = useState(1);

  const [editData, setEditData] = useState({});
  const [editFileList, setEditFileList] = useState([]);
  const [isThumbnail, setIsThumbnail] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [deviceType, setDeviceType] = useState("Unknown");
  const [imageToCrop, setImageToCrop] = useState("");

  const [croppedImage, setCroppedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [editorHtml, setEditorHtml] = useState("");
  const [detail, setDetail] = useState("");
  const dropzoneRef = useRef(null);
  const quillRef = useRef(null);
  const [images, setImages] = useState([]);
  const { id, sec } = useParams();
  useEffect(() => {
    if (quillRef.current) {
      let quill = quillRef.current.getEditor();
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let plaintext = node.innerText;
        delta.ops = [{ insert: plaintext }];
        return delta;
      });
    }
  }, []);

  const handleEditorChange = (e, html) => {
    setEditorHtml(e);
    setDetail(e);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const newFileList = Array.from(editFileList);
    const [removed] = newFileList.splice(source.index, 1);
    newFileList.splice(destination.index, 0, removed);
    setEditFileList(newFileList);
  };

  useEffect(() => {
    const initializeDropzone = () => {
      const dropzoneTemplate = document.querySelector(
        "#custom-preview-template"
      );

      if (dropzoneTemplate) {
var vehicleId = id || window.location.pathname.split("/")[2];
        const dropzone = new Dropzone(dropzoneRef.current, {
          url: `${baseURL}vehicles/updateVehicleImages/${vehicleId}`,
          paramName: "car_images",
          dictDefaultMessage: `
            <p className="ant-upload-drag-icon">
              <div className="img-icon-list">
                <span>
                  <img src="${Cloud}" className="img-fluid" alt="img" />
                </span>
              </div>
            </p>
            <p className="ant-upload-text">Drop your files here or</p>
            <p className="ant-upload-hint">Browse</p>
          `,
          method: "PATCH",
          acceptedFiles: "image/*",
          acceptedFiles: "image/jpeg, image/png, image/jpg, image/webp",
          previewTemplate: dropzoneTemplate.innerHTML,
          addRemoveLinks: false,
          init: function () {
            this.on("sending", function (file, xhr, formData) {});

            this.on("success", function (file, response) {
              const removeButton =
                file.previewElement.querySelector(".dz-remove");
              removeButton.style.display = "inline-block";
              file.previewElement.id = response.images.slice(-1)[0]._id;
              setImages((prev) => [...prev, response.images.slice(-1)[0]]);
              dropzone.on();
            });
            this.on("removedfile", (file) => {
              const imageId = file.previewElement.id;
              rem(imageId);
              setImages((prev) =>
                prev.filter((image) => image._id !== imageId)
              );
            });

            this.on("error", function (file, response) {
              if (file.accepted === false) {
                this.removeFile(file); // Remove the file if it's not accepted
                alert(
                  "Invalid file type. Please upload a JPEG, PNG, JPG, or WebP file."
                );
              }
            });
          },
        });
        dropzone.on("addedfile", function (file) {
          document.querySelector(".dz-default.dz-message").style.display =
            "block";
        });
        return () => {
          dropzone.destroy(); // Cleanup when component unmounts
        };
      } else {
        console.error("#custom-preview-template element not found.");
      }
    };

    const timeoutId = setTimeout(initializeDropzone, 10); // Adjust delay as needed
    if (sec === "info") {
      // setTimeout(() => {
      const anchor = document.getElementById("info");
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
      // }, 800);
    } else {
      const anchor = document.getElementById("upload");
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, [editData]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const vehicleId = window.location.pathname.split("/")[2];
    if (vehicleId) {
      axios
        .get(`${baseURL}/vehicles/${vehicleId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const updatedVehicleImagesArr = res.data.carImagesId.images.map(
            (a, i) => {
              a.id = i + 1;
              return a;
            }
          );
          if (res.data.carImagesId?.thumbnail ?? null) {
            setIsThumbnail(true);
          }

          setDeviceType(getDeviceType());
          setEditData(res.data);
          setDetail(res.data?.detail);
          setEditorHtml(res.data?.detail);
          setEditFileList(updatedVehicleImagesArr);
        });
    }
  }, []);

  const handleFileChange = (info) => {
    info.file.preview = URL.createObjectURL(info.file.originFileObj);
    setFileList([...info.fileList]);
    setImageURLs([...info.fileList]);
    setCarImages([...info.fileList]);
  };

  const handleDeleteImage = (index) => {
    setImageURLs((prevURLs) => {
      const newURLs = [...prevURLs];
      newURLs.splice(index, 1);
      return newURLs;
    });
    setCarImages((prevImages) => {
      const newImages = [...prevImages];
      newImages.splice(index, 1);
      return newImages;
    });
  };

  const rem = (id) => {
    setEditFileList((prevFiles) => prevFiles.filter((f) => f._id !== id));
  };

  const handleOver = (ev) => {
    ev.preventDefault();
  };
  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };
  const handleDrop = (ev) => {
    ev.preventDefault();
    const dragImage = editFileList.find((image) => image.id == dragId);
    const dropImage = editFileList.find(
      (image) => image.id == ev.currentTarget.id
    );
    const arr = moveItem(dragImage.id - 1, dropImage.id - 1);
    setEditFileList(arr);
  };

  const moveItem = (from, to) => {
    const updatedPreviewImages = [...editFileList];
    const f = updatedPreviewImages.splice(from, 1)[0];
    updatedPreviewImages.splice(to, 0, f);
    return updatedPreviewImages.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
  };

  const handleThumbnailEditImage = async (image) => {
    setOpen(true);
    try {
      setInitialLoading(true);
      const response = await axios.get(
        `${baseURL}files/get-car-image/${image.originalCompressedImage}`,
        { responseType: "blob" }
      );
      const url = URL.createObjectURL(response.data);
      setImageToCrop(url);

      const originalResponse = await axios.get(
        `${baseURL}files/get-car-image/${image.originalImage}`,
        { responseType: "blob" }
      );

      const originalUrl = URL.createObjectURL(originalResponse.data);

      setOriginalImage(originalUrl);
    } catch (error) {
      console.error("Error fetching image", error);
    } finally {
      setInitialLoading(false);
    }
  };

  return (
    <>
      {loading && fileList.length > 0 && (
        <CustomLoader text="please wait, it takes few minutes to process your images" />
      )}
      <PageLayout
        type="website"
        imageWidth="1920"
        title="Edit Vehicle"
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <div
          style={{
            backgroundColor: "#003366",
            color: "white",
            fontFamily: "Arial, sans-serif",
            padding: "10px 20px",
            textAlign: "center",
          }}
        >
          <p>
            If you are experiencing any technical problems with our new website,
            please contact us at (937) 430-0242.
          </p>
          <p>We can help you finalize your orders.</p>
        </div>
        <div className="sec-pad pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-11">
                <div className="text-center mb-4">
                  <h2 className="h2-title mb-2 bold">Edit Your Vehicle</h2>
                </div>
                <div className="billing-box bb-mobile">
                  {editData?._id === window.location.pathname.split("/")[2] && (
                    <Formik
                      initialValues={{
                        make: editData?.make || "",
                        model: editData?.model || "",
                        year: editData?.year || "",
                        exteriorColor: editData?.exteriorColor || "",
                        price: editData?.price || "",
                        miles: editData?.miles,
                        country: editData?.country || "",
                        number: editData?.number || "",
                        altNumber: editData?.altNumber || "",
                        bodyStyle: editData?.bodyStyle || "",
                        transmissionType: editData?.transmissionType || "",
                        address: editData?.address || "",
                        postalCode: editData?.postalCode || "",
                        city: editData?.city || "",
                        state: editData?.state || "",
                      }}
                      validationSchema={Yup.object({
                        make: Yup.string().required("Vehicle Name is required"),
                        model: Yup.string().required(
                          "Vehicle Name is required"
                        ),
                        year: Yup.number().required("Vehicle Name is required"),
                        exteriorColor: Yup.string().required(
                          "Vehicle Name is required"
                        ),
                        address: Yup.string().required("Address is required"),
                        postalCode: Yup.string().required(
                          "Postal code is required"
                        ),
                        city: Yup.string().required("city is required"),
                        state: Yup.string().required("state is required"),
                        price: Yup.string().required("Price is required"),
                        miles: Yup.string().required("Miles is required"),
                        country: Yup.string().required("country is required"),
                        number: Yup.string().required(
                          "Phone Number is required"
                        ),
                        bodyStyle: Yup.string().required(
                          "Body Style is required"
                        ),
                        transmissionType: Yup.string().required(
                          "Transmission Type is required"
                        ),
                      })}
                      onSubmit={async (values, actions) => {
                        if (detail == "" || detail === "<p><br></p>") {
                          return message.error("please Add detail");
                        }
                        if (values) {
                          setLoading(true);
                        }
                        let prevImages = editFileList.concat(images);

                        const obj = {
                          ...values,
                        };
                        const formData = new FormData();
                        Object.keys(obj).forEach((key) => {
                          formData.append(key, obj[key]);
                        });
                        formData.append("detail", detail);
                        formData.append("newThumbnail", editFileList[0].thumb);
                        formData.append(
                          "prevImages",
                          JSON.stringify(prevImages)
                        );

                        formData.append("x1", angles.x1);
                        formData.append("y1", angles.y1);
                        formData.append("x2", angles.x2);
                        formData.append("y2", angles.y2);
                        formData.append("zoom", areaZoom);

                        formData.append("car_images", croppedImage);

                        if (editFileList.length === 0 && !images) {
                          message.error("Please Upload the images");
                          setLoading(false);
                          return;
                        }

                        await httpClient
                          .put(
                            `vehicles/updateVehicleAdmin/${
                              window.location.pathname.split("/")[2]
                            }`,
                            formData,
                            {
                              "Content-Type": "multipart/form-data",
                            }
                          )
                          .then(() => {
                            setLoading(false);
                            message.success("Vehicle Updated");
                            window.location.replace("/listed-vehicle");
                          });
                      }}
                    >
                      {({
                        validateForm,
                        handleChange,
                        handleBlur,
                        values,
                        setFieldValue,
                      }) => (
                        <Form>
                          <div
                            id="upload"
                            className="row mb-4 justify-content-center"
                          >
                            <div className="col-md-8 col-lg-6">
                              <Col
                                style={{ marginTop: "30px" }}
                                span={24}
                                md={24}
                              >
                                <div style={{ marginTop: "50px" }}>
                                  <div ref={dropzoneRef} className="dropzone" />
                                  <div
                                    id="custom-preview-template"
                                    style={{ display: "none" }}
                                  >
                                    <div className="dz-preview dz-file-preview">
                                      <div
                                        style={{ cursor: "pointer" }}
                                        className="dz-image"
                                      >
                                        <img
                                          data-dz-thumbnail
                                          alt="Thumbnail"
                                        />
                                      </div>
                                      <div className="dz-details">
                                        <div className="dz-size">
                                          <span data-dz-size></span>
                                        </div>
                                        <div className="dz-filename">
                                          <span data-dz-name></span>
                                        </div>
                                      </div>
                                      <div className="dz-progress">
                                        <span
                                          className="dz-upload"
                                          data-dz-uploadprogress
                                        ></span>
                                      </div>
                                      <div className="dz-error-message">
                                        <span data-dz-errormessage></span>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i
                                          className="fas fa-times dz-remove"
                                          data-dz-remove
                                          style={{
                                            padding: "8px",
                                            fontSize: "24px",
                                            display: "none",
                                          }} // Initially hidden
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              {editFileList && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {(deviceType === "Tablet" ||
                                    deviceType === "Mobile") && (
                                    <label
                                      className="mt-3"
                                      style={{
                                        marginTop: "30px",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      Images
                                    </label>
                                  )}
                                  <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppableImages">
                                      {(provided) => (
                                        <div
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "10px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {(deviceType === "Tablet" ||
                                            deviceType === "Mobile") &&
                                            editFileList.map((image, index) => (
                                              <Draggable
                                                key={image.id}
                                                draggableId={image.id.toString()}
                                                index={index}
                                              >
                                                {(provided) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                      width: "230px",
                                                      padding: "10px",
                                                      border: "1px solid grey",
                                                      borderRadius: "10px",
                                                      ...provided.draggableProps
                                                        .style,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      {image?.originFileObj ? (
                                                        <img
                                                          alt={
                                                            image.alt ||
                                                            "Displayed image"
                                                          }
                                                          src={`${image.preview}`}
                                                          style={{
                                                            height: "120px",
                                                            maxWidth: "100%",
                                                            objectFit: "cover",
                                                          }}
                                                        />
                                                      ) : (
                                                        <img
                                                          alt={
                                                            image.alt ||
                                                            "Displayed image"
                                                          }
                                                          src={`${WASABI_URL}${
                                                            image.originalCompressedImage
                                                              ? image.originalCompressedImage
                                                              : image.path
                                                          }`}
                                                          style={{
                                                            height: "120px",
                                                            maxWidth: "100%",

                                                            objectFit: "cover",
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      <Popconfirm
                                                        title="Are you sure you want to delete this image?"
                                                        onConfirm={() =>
                                                          rem(image._id)
                                                        }
                                                        okText="Yes"
                                                        cancelText="No"
                                                      >
                                                        <Tooltip title="delete">
                                                          <Button
                                                            danger
                                                            style={{
                                                              margin: "5px 0 0",
                                                            }}
                                                            icon={
                                                              <DeleteOutlined />
                                                            }
                                                            size="small"
                                                          />
                                                        </Tooltip>
                                                      </Popconfirm>
                                                      {index == 0 && (
                                                        <Tooltip title="Change Thumbnail Crop">
                                                          <Button
                                                            danger
                                                            style={{
                                                              margin:
                                                                "5px 0 0 8px",
                                                            }}
                                                            icon={
                                                              <CiImageOn
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              />
                                                            }
                                                            size="small"
                                                            onClick={() =>
                                                              handleThumbnailEditImage(
                                                                image
                                                              )
                                                            }
                                                          />
                                                        </Tooltip>
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </Draggable>
                                            ))}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </div>
                              )}
                              {deviceType === "Desktop" && editFileList && (
                                <>
                                  <label className="mt-3">Images</label>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {editFileList.map((image, index) => {
                                      return (
                                        <div
                                          style={{
                                            width: "230px",
                                            padding: "10px",
                                            border: "1px solid grey",
                                            borderRadius: "10px",
                                            cursor: "grab",
                                          }}
                                          className="mt-3"
                                          key={index}
                                          id={image?.id}
                                          draggable
                                          onDragOver={(e) => handleOver(e)}
                                          onDragStart={(e) => handleDrag(e)}
                                          onDrop={(e) => handleDrop(e)}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              flexDirection: "column",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <img
                                                alt="car"
                                                src={`${WASABI_URL}${
                                                  image.originalCompressedImage
                                                    ? image.originalCompressedImage
                                                    : image.path
                                                }`}
                                                style={{
                                                  height: "100px",
                                                  objectFit: "cover",
                                                }}
                                              />
                                            </div>
                                            <div>
                                              <Popconfirm
                                                title="Are you sure you want to delete this image?"
                                                onConfirm={() => rem(image._id)}
                                                okText="Yes"
                                                cancelText="No"
                                              >
                                                <Tooltip title="delete">
                                                  <Button
                                                    danger
                                                    style={{
                                                      margin: "5px 0 0",
                                                    }}
                                                    icon={<DeleteOutlined />}
                                                    size="small"
                                                  />
                                                </Tooltip>
                                              </Popconfirm>
                                              <Tooltip title="Set image as thumbnail">
                                                <Button
                                                  danger
                                                  style={{
                                                    margin: "5px 0 0 8px",
                                                  }}
                                                  icon={
                                                    <CiImageOn
                                                      style={{ color: "red" }}
                                                    />
                                                  }
                                                  size="small"
                                                  onClick={() =>
                                                    handleThumbnailEditImage(
                                                      image
                                                    )
                                                  }
                                                />
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="row row-cols-2 row-cols-lg-4 row-cols-md-4 mb-4 g-3 justify-content-center">
                              {imageURLs.map((imageURL, index) => (
                                <div className="col" key={index}>
                                  <div className="position-relative">
                                    <div className="upload-image-delete">
                                      <div>
                                        <span
                                          className="icons-hover"
                                          onClick={() =>
                                            handleDeleteImage(index)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16.037"
                                            className="share-icon-white cr"
                                            height="19.738"
                                            viewBox="0 0 16.037 19.738"
                                          >
                                            <path
                                              id="trash"
                                              d="M16.5,6.067h3.7A1.165,1.165,0,0,1,21.437,7.3V8.534H5.4V7.3A1.236,1.236,0,0,1,6.634,6.067h3.7a3.161,3.161,0,0,1,6.168,0Zm-4.934,0h3.7a2,2,0,0,0-3.7,0Zm-4.934,3.7H20.2l-1.11,12.459a1.217,1.217,0,0,1-1.234,1.11H8.977a1.312,1.312,0,0,1-1.234-1.11Z"
                                              transform="translate(-5.4 -3.6)"
                                              fill="#f15757"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                    <img
                                      src={imageURL.preview}
                                      className=" br-5"
                                      alt={` ${index}`}
                                      style={{ maxWidth: "100%" }}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div id="info" className="row mb-4 text-start">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Vehicle Make{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="make"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="make"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Vehicle Model{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="model"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="model"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Body Style{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="bodyStyle"
                                  className="form-control fc"
                                  render={({ field }) => (
                                    <select
                                      className="form-control fc"
                                      value={field.value}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "bodyStyle",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {bodyStyle.map((style) => (
                                        <option key={style} value={style}>
                                          {style}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="bodyStyle"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Transmission Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="transmissionType"
                                  className="form-control fc"
                                  render={({ field }) => (
                                    <select
                                      className="form-control fc"
                                      value={field.value}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "transmissionType",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {transmissionType.map((style) => (
                                        <option key={style} value={style}>
                                          {style}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="transmissionType"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Year <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="year"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="year"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Price <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="price"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="price"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Miles <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="miles"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="miles"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="country" className="mb-1">
                                  Country:
                                </label>
                                <Field
                                  as="select"
                                  type="string"
                                  name="country"
                                  className="form-control fc"
                                >
                                  <option value=""></option>
                                  <option value="United States">
                                    United States
                                  </option>
                                  <option value="Canada">Canada</option>
                                  <option value="New Zealand">
                                    New Zealand
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="option"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="mb-1">
                                  Address <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="address"
                                  className="form-control fc mb-2"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="address"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Postal Code{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="test"
                                  name="postalCode"
                                  className="form-control fc mb-2"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="postalCode"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  City <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="city"
                                  className="form-control fc mb-2"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="city"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  State <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="state"
                                  className="form-control fc mb-2"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="state"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Mobile Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="number"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="number"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  AlterNet Mobile Number
                                </label>
                                <Field
                                  type="number"
                                  name="altNumber"
                                  className="form-control fc"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Exterior Color{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="exteriorColor"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="exteriorColor"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="mb-1">Details</label>
                                <ReactQuill
                                  ref={quillRef}
                                  theme="snow"
                                  value={editorHtml}
                                  onChange={handleEditorChange}
                                  name="detail"
                                  style={{ height: "150px" }}
                                />
                              </div>
                            </div>
                          </div>

                          <input
                            disabled={loading}
                            type="submit"
                            name="submit"
                            id="submitBtn"
                            className="submit action-button btn btn-theme-fill"
                            defaultValue="Submit"
                          />
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <StyledDemo
          initialLoading={initialLoading}
          setInitialLoading={setInitialLoading}
          originalImage={originalImage}
          setAngles={setAngles}
          angles={angles}
          imageToCrop={imageToCrop}
          {...{ open, setOpen, croppedImage, setCroppedImage, setAreaZoom }}
        />
      </PageLayout>
    </>
  );
};

export default VehicleEdit;
