import React, { useState, useRef } from "react";
import { Button, Modal, Slider, message } from "antd";
import { PictureOutlined, UploadOutlined } from "@ant-design/icons";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import "./style.css";
import { httpClient } from "../../util/Api";
import { useAuth } from "../../authentication";

const ImageUploader = () => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [uploading, setUploading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const inputRef = useRef(null);

  const { getAuthUser } = useAuth();

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        setImage(reader.result);
        setIsModalVisible(true);
      };
    }
  };

  const handleCropComplete = (_, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  };

  const handleSave = async () => {
    if (image && croppedAreaPixels) {
      try {
        setUploading(true);
        const croppedImageBlob = await getCroppedImg(image, croppedAreaPixels);
        const formData = new FormData();
        formData.append("profile_image", croppedImageBlob);
        httpClient.post("files/profile-upload", formData).then(() => {
          getAuthUser();
          message.success("Image uploaded successfully!");
          setUploading(false);
          setIsModalVisible(false);
        });
      } catch (e) {
        message.error("Failed to upload image.");
      }
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={onFileChange}
      />
      <UploadOutlined
        onClick={() => inputRef.current && inputRef.current.click()}
      />

      <Modal
        title="Crop Image"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        confirmLoading={uploading}
        centered
      >
        <div className="container-wrapper">
          <div className="cropper-container">
            {image && (
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            )}
          </div>
        </div>
        <div className="icon-wrapper">
          <PictureOutlined />
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            tooltip={{ open: false }}
            onChange={(value) => setZoom(value)}
          />
          <PictureOutlined />
        </div>
      </Modal>
    </div>
  );
};

export default ImageUploader;
