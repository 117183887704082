const ListingFeatures = [
  {
    title: "Opposing Cylinders website",
    PremiumPlus: true,
    Premium: true,
    Basic: true,
  },
  {
    title: "Feature on Opposing Cylinders IG/FB Pages",
    PremiumPlus: true,
    Premium: true,
    Basic: true,
  },
  {
    title: "Coming soon on opposing cylinders IG/FB Pages",
    PremiumPlus: true,
    Premium: true,
    Basic: true,
  },
  {
    title:
      "Listing on up to 20+ other FB Pages (Depending on the vehicle Type)",
    PremiumPlus: true,
    Premium: true,
    Basic: false,
  },

  {
    title: "Number of Photos on Opposing Cylinders website",
    PremiumPlus: "30",
    Premium: "25",
    Basic: "10",
  },
  {
    title: "Quality customer videos on Opposing Cylinders IG, FB and Youtube",
    PremiumPlus: true,
    Premium: false,
    Basic: false,
  },
  {
    title:
      "We handle the sales and handle the calls - 5% additional fees - time of sale",
    PremiumPlus: true,
    Premium: false,
    Basic: false,
  },
  {
    title: "Listing assistance for vehicle information and photos.",
    PremiumPlus: true,
    Premium: true,
    Basic: false,
  },
  {
    title: "Listing Duration",
    PremiumPlus: "unlimited",
    Premium: "unlimited",
    Basic: "unlimited",
  },
  {
    title: "Updates/Price Changes on website",
    PremiumPlus: "unlimited",
    Premium: "unlimited",
    Basic: "unlimited",
  },
  {
    title: "Receive a Free Opposing Cylinder T-shirt",
    PremiumPlus: true,
    Premium: true,
    Basic: false,
  },
];

export default ListingFeatures;
