import React from "react";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// If you want to include optional plugins (e.g., zoom, thumbnails, fullscreen)

import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Download from "yet-another-react-lightbox/plugins/download";

import "yet-another-react-lightbox/plugins/thumbnails.css";

function LightBox({ open, setOpen, images, index }) {
  console.log({ images });
  return (
    <div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={images}
        plugins={[Zoom, Thumbnails, Fullscreen, Download]}
        index={index}
      />
    </div>
  );
}

export default LightBox;
