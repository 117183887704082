import React, { useState, useEffect } from "react";
import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import VehiclesCard from "../../Component/Cards/VehiclesCard";
import { NavLink } from "react-router-dom";
import VehicleList from "../../Data/VehicleList";
import ReactPaginate from "react-paginate";
import { baseURL, httpClient } from "../../util/Api";
import axios from "axios";

const LikedVehicles = () => {
  const token = localStorage.getItem("token");
  // State for vehicle data
  const [vehicles, setVehicles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const videosPerPage = 9;
  const offset = currentPage * videosPerPage;
  const videoSlice = vehicles.slice(offset, offset + videosPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const fetchVehicles = async (guest) => {
    try {
      const response = await axios.get(`${baseURL}vehicles/user-liked`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setVehicles(response.data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("token", token);
    if (token) {
      fetchVehicles(true);
    } else {
      fetchVehicles(false);
    }
  }, []);

  return (
    <>
      <PageLayout
        type="website"
        imageWidth="1920"
        title="Liked Vehicles"
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <div className="sec-pad pt-0">
          <div className="container">
            <div className="row mb-4 mb-md-5">
              <div className="col-12 text-center">
                <h2 className="bold h2-title mb-3">My Liked Vehicles</h2>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4">
              {videoSlice.map((item, index) => (
                <VehiclesCard
                  key={item._id} // Remember to add a unique key prop for each child element in a list
                  id={item._id}
                  noOfLike={item.likes}
                  isLiked={item.isLiked}
                  title={[item.year, item.make, item.model].join(" ")}
                  price={item.price}
                  year={item.year}
                  noOfImage={item.carImagesId.images?.length}
                  location={item.location}
                  availbility={item.availbility}
                  onlinePaperWork={item.onlinePaperWork}
                  number={item.number}
                  certification={item.certification}
                  testing={item.testing}
                  drivenMile={item.miles}
                  videoWalkaround={item.videoWalkaround}
                  testDrive={item.testDrive}
                  delivery={item.delivery}
                  // thumbnail={item.carImagesId.images[0]}
                  isThumbnail={item?.carImagesId?.thumbnail || false}
                  thumbnail={item.carImagesId.images[0]}
                  oldImage={item?.carImagesId?.images[0]}
                  newThumbnail={item?.carImagesId?.thumbnail}
                />
              ))}
            </div>

            <div className="row mt-4 mt-lg-5">
              <div className="col-12 text-end">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(vehicles.length / videosPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={
                    "pagination d-flex justify-content-end page-item"
                  }
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>

        <JoinClub />
      </PageLayout>
    </>
  );
};

export default LikedVehicles;
