import React, { Component, useEffect, useState } from "react";
import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import { NavLink } from "react-router-dom";
import SearchIcon from "../../Assets/Images/icon/search.svg";
import { useParams } from "react-router-dom";
import { WASABI_URL, baseURL, httpClient } from "../../util/Api";
import { getTimeAgo } from "../../util/time";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import LikeWidget from "../../Component/LikeWidget";
import { Spin } from "antd";
import "./style.css";
import axios from "axios";

function VehicleDetail() {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [vehicleData, setPartData] = useState({});
  const [comments, setComments] = useState([]);

  // const fetchComments = () => {
  //   httpClient.get(`comments/${id}`).then((response) => {
  //     setComments(response?.data || []);
  //   });
  // };
  // const submitComment = () => {
  //   setLoading(true);
  //   httpClient.post(`comments`, { vehicleId: id, comment }).then((response) => {
  //     fetchComments();
  //     setComment(null);
  //     setLoading(false);
  //   });
  // };

  useEffect(() => {
    setMainLoading(true);
    axios
      .get(`${baseURL}/parts/get-parts-by-id/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const vehicle = response.data.part;
        setPartData(vehicle);
        setMainLoading(false);
      });
  }, [id]);
  return (
    <>
      <PageLayout
        type="website"
        imageWidth="1920"
        url="https://opposingcylinders.com/"
        title={[vehicleData?.year, vehicleData?.make, vehicleData?.model].join(
          " "
        )}
        description={vehicleData.detail}
        image={`${WASABI_URL}/${vehicleData?.partImagesId?.images?.key}`}
      >
        <Spin tip="Loading..." spinning={mainLoading} delay={200} size="large">
          <div className="sec-pad pt-0">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="position-relative mb-4">
                    <button className="cr btn btn-theme-share sv-share-btn">
                      <span
                        className=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title=""
                        data-bs-original-title="Share"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={18}
                          height={18}
                          fill="currentColor"
                          className="bi bi-share share-icon-white cr"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                        </svg>
                      </span>
                    </button>
                    <div
                      id="carouselExampleControls"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        {vehicleData?.partImagesId?.images.map((img, i) => (
                          <div
                            className={`carousel-item ${i === 0 && "active"} `}
                            key={i}
                          >
                            <img
                              src={
                                img?.source === "cloud"
                                  ? WASABI_URL + img.path
                                  : img.path
                              }
                              className="d-block partSliderImg"
                              alt="..."
                            />
                          </div>
                        ))}
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-lg-8">
                  <h2 className="h3-title bold mb-2">{vehicleData.partName}</h2>
                  <div className=" d-inline-flex align-items-center me-3">
                    <div className="bedge d-inline-block me-2">
                      {" "}
                      {vehicleData?.year || "2024"}
                    </div>
                    {!vehicleData?.isSold && (
                      <div className="d-inline-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-geo-alt-fill me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>{" "}
                        {vehicleData?.location}
                      </div>
                    )}
                  </div>
                  {!vehicleData?.isSold && (
                    <LikeWidget
                      isLiked={vehicleData.isLiked}
                      numberOfLikes={vehicleData.likes}
                      vehicleId={id}
                    />
                  )}
                </div>
                <div className="col-md-4 col-lg-4 text-start text-md-end mt-4 mt-md-0">
                  <NavLink to="#" className="text-theme">
                    Asking
                  </NavLink>
                  <div className="mb-0 h2-title bold">
                    {" "}
                    {vehicleData?.isSold ? "SOLD" : vehicleData?.price}
                  </div>
                </div>
              </div>
              <div className="row mt-2 g-4">
                <div className="col-md-12 col-lg-6">
                  <h3 className="h3-title">Details</h3>
                  <p>{vehicleData?.description}</p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <h3 className="h3-title">Condition</h3>
                  <p>{vehicleData?.condition}</p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <h3 className="h3-title">More informations</h3>

                  <div className="my-3">Phone Number: {vehicleData?.phone}</div>
                </div>
              </div>
              <hr className="op-1" />
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                {vehicleData?.partImagesId?.images?.map((img, index) => (
                  <div className="col" key={index}>
                    <div className="cv-zoom">
                      <div className="cv-zoom-overlay">
                        <img
                          src={SearchIcon}
                          className="img-fluid"
                          alt="icon"
                        />
                      </div>

                      <img
                        alt="cloud-image"
                        src={`${WASABI_URL}${
                          img.originalCompressedImage
                            ? img.originalCompressedImage
                            : img.path
                        }`}
                        className="img-fluid br-5 w-100"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Spin>
        <JoinClub />
      </PageLayout>
    </>
  );
}

export default VehicleDetail;
