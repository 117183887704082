import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { WASABI_URL, baseURL, httpClient } from "../../util/Api";
import ShareWidget from "../ShareWidget";
import { Button, Popconfirm, message } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import PaymentModal from "../Modal/PaymentModal";
import { packages } from "../../Data/packages";

export const ListedPartsCard = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    id,
    noOfLike,
    title,
    price,
    location,
    condition,
    thumbnail,
    status,
    isThumbnail,
    newThumbnail,
    isPaid,
    selectedPackage,
    isSold,
  } = props;

  const handleOk = (values, clearForm, paymentLoading) => {
    paymentLoading.start();
    console.log(values);
    if (values.type === "card") {
      httpClient
        .post(`parts/instant-payment/${id}`, {
          type: values.type,
          payment: { ...values },
        })
        .then(() => {
          props.fetchVehicles();
          setModalVisible(false);
          clearForm();
          window.location.reload();
          paymentLoading.success();
        })
        .catch(() => {
          paymentLoading.error();
          message.error("Payment Faild");
        });
    } else {
      httpClient
        .post(`parts/payment/${id}`, {
          type: values.type,
          payment: { ...values },
        })
        .then(() => {
          props.fetchVehicles();
          setModalVisible(false);
          clearForm();
          window.location.reload();
          paymentLoading.success();
        })
        .catch(() => {
          paymentLoading.error();
          message.error("Payment Faild");
        });
    }
  };

  const handleVehicleStatus = (packageId) => {
    httpClient.patch(`parts/parts-status/${packageId}`).then(() => {
      props.fetchVehicles();
    });
  };

  const handleVehicleSoldStatus = (packageId) => {
    httpClient.patch(`parts/parts-sold/${packageId}`).then(() => {
      props.fetchVehicles();
    });
  };

  const sPackage = packages.find((el) => el.type === selectedPackage);

  return (
    <>
      <div className="col-12">
        <div className="profile-cl">
          <div className="row">
            <div className="col-md-4 col-lg-3 ">
              <div
                className="pcl-img"
                style={{
                  backgroundImage: `url(${
                    isThumbnail
                      ? WASABI_URL + newThumbnail
                      : WASABI_URL + thumbnail?.path
                  })`,
                }}
              ></div>
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="pcl-right">
                <div className="row w-100">
                  <div className="col-md-12 col-lg-8  mb-3 mb-lg-0">
                    <NavLink to={`/part-detail/${id}`} className="ft-18 bold">
                      {title}
                    </NavLink>
                    <br />
                    <div className="bedge d-inline-block mt-2">{price}</div>
                  </div>
                  <div className="col-md-12 col-lg-4 text-start text-lg-end">
                    <div className="d-flex align-items-center justify-content-start justify-content-lg-end">
                      <NavLink
                        to={`/list-parts-edit/${id}`}
                        className="btn btn-theme-fill btn-small "
                      >
                        Edit{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10.247"
                          height="10.247"
                          className="ms-2 plus-icon mt-minus-3"
                          viewBox="0 0 10.247 10.247"
                        >
                          <path
                            id="edit"
                            d="M4.5,12.609v2.134H6.634l6.3-6.3L10.8,6.313ZM14.58,6.8a.567.567,0,0,0,0-.8L13.248,4.663a.567.567,0,0,0-.8,0L11.4,5.7l2.134,2.134Z"
                            transform="translate(-4.5 -4.496)"
                            fill="#fbfdff"
                          />
                        </svg>
                      </NavLink>{" "}
                      &nbsp;&nbsp;
                      {/* <div className="d-inline-block">
                          <span className="icons-hover">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.037"
                              className="share-icon-white cr"
                              height="19.738"
                              viewBox="0 0 16.037 19.738"
                            >
                              <path
                                id="trash"
                                d="M16.5,6.067h3.7A1.165,1.165,0,0,1,21.437,7.3V8.534H5.4V7.3A1.236,1.236,0,0,1,6.634,6.067h3.7a3.161,3.161,0,0,1,6.168,0Zm-4.934,0h3.7a2,2,0,0,0-3.7,0Zm-4.934,3.7H20.2l-1.11,12.459a1.217,1.217,0,0,1-1.234,1.11H8.977a1.312,1.312,0,0,1-1.234-1.11Z"
                                transform="translate(-5.4 -3.6)"
                                fill="#f15757"
                              />
                            </svg>
                          </span>
                        </div> */}
                      {status ? (
                        <Popconfirm
                          title="Are you sure you want to De-Activate this vehicle?"
                          onConfirm={() => handleVehicleStatus(id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <EyeOutlined
                            style={{ color: "#52c41a", fontSize: "30px" }}
                          />
                        </Popconfirm>
                      ) : (
                        <Popconfirm
                          title="Are you sure you want to Activate this vehicle?"
                          onConfirm={() => handleVehicleStatus(id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <EyeInvisibleOutlined
                            style={{ color: "red", fontSize: "30px" }}
                          />
                        </Popconfirm>
                      )}
                      &nbsp;&nbsp;
                      <ShareWidget className="d-inline-block" />
                    </div>
                  </div>
                </div>
                <div className="w-100 mt-4 row">
                  <div className="col-12">
                    <div className=" small ">
                      <div className="d-inline-flex align-items-center me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={13}
                          height={13}
                          fill="currentColor"
                          className="bi bi-geo-alt-fill me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>{" "}
                        {location}
                      </div>
                      <div className="d-inline-flex align-items-center">
                        <svg
                          className="me-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.412"
                          height="13.412"
                          viewBox="0 0 16.412 13.412"
                        >
                          <path
                            id="dashboard"
                            d="M8.206,0a8.206,8.206,0,1,0,8.206,8.206A8.23,8.23,0,0,0,8.206,0Zm0,2.052A6.155,6.155,0,1,1,2.052,8.206,6.146,6.146,0,0,1,8.206,2.052Zm0,2.052A1.026,1.026,0,1,0,9.232,5.129,1.016,1.016,0,0,0,8.206,4.1ZM4.8,6.155a1.026,1.026,0,0,0-.39,1.723L6.278,9.745a2.083,2.083,0,0,0-.123.513A2.052,2.052,0,1,0,8.206,8.206a2.083,2.083,0,0,0-.513.123L5.826,6.462a1.026,1.026,0,0,0-.9-.328,1.026,1.026,0,0,0-.123,0Zm6.483,0A1.026,1.026,0,1,0,12.309,7.18,1.016,1.016,0,0,0,11.283,6.155Z"
                            fill="#currentColor"
                          />
                        </svg>
                        {condition}
                      </div>
                    </div>
                    {/* <p className="small mt-2">
                      {" "}
                      <VehicleExtraInfo
                        data={{
                          videoWalkaround: videoWalkaround,
                          testDrive: testDrive,
                          delivery: delivery,
                        }}
                      />
                    </p> */}
                    <div>
                      {/* <div className="d-inline-flex align-items-center me-3">
                        {onlinePaperWork && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="me-1 mt-1"
                              width="14.859"
                              height="14.859"
                              viewBox="0 0 15.859 15.859"
                            >
                              <path
                                id="check-verified"
                                d="M4.786,12.516h-.2a1.586,1.586,0,0,1,0-3.172h.2a6.306,6.306,0,0,1,.679-1.637l-.143-.142A1.586,1.586,0,0,1,7.566,5.323l.141.142a6.306,6.306,0,0,1,1.637-.679v-.2a1.586,1.586,0,0,1,3.172,0v.2a6.306,6.306,0,0,1,1.637.679l.142-.143a1.586,1.586,0,0,1,2.243,2.244l-.142.141a6.277,6.277,0,0,1,.679,1.637h.2a1.586,1.586,0,1,1,0,3.172h-.2a6.306,6.306,0,0,1-.679,1.637l.143.142a1.586,1.586,0,1,1-2.244,2.243l-.141-.142a6.306,6.306,0,0,1-1.637.679v.2a1.586,1.586,0,0,1-3.172,0v-.2a6.306,6.306,0,0,1-1.637-.679l-.142.143a1.586,1.586,0,0,1-2.243-2.244l.142-.141a6.306,6.306,0,0,1-.679-1.637ZM8.551,9.344,6.965,10.93,10.137,14.1l4.758-4.758L13.309,7.758,10.137,10.93Z"
                                transform="translate(-3 -3)"
                                fill="#15f315"
                                fillRule="evenodd"
                              />
                            </svg>
                            Online Paper Work
                          </>
                        )}
                      </div>
                      <div className="d-inline-flex align-items-center me-3">
                        {availbility && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="me-1 mt-1"
                              width="14.859"
                              height="14.859"
                              viewBox="0 0 15.859 15.859"
                            >
                              <path
                                id="check-verified"
                                d="M4.786,12.516h-.2a1.586,1.586,0,0,1,0-3.172h.2a6.306,6.306,0,0,1,.679-1.637l-.143-.142A1.586,1.586,0,0,1,7.566,5.323l.141.142a6.306,6.306,0,0,1,1.637-.679v-.2a1.586,1.586,0,0,1,3.172,0v.2a6.306,6.306,0,0,1,1.637.679l.142-.143a1.586,1.586,0,0,1,2.243,2.244l-.142.141a6.277,6.277,0,0,1,.679,1.637h.2a1.586,1.586,0,1,1,0,3.172h-.2a6.306,6.306,0,0,1-.679,1.637l.143.142a1.586,1.586,0,1,1-2.244,2.243l-.141-.142a6.306,6.306,0,0,1-1.637.679v.2a1.586,1.586,0,0,1-3.172,0v-.2a6.306,6.306,0,0,1-1.637-.679l-.142.143a1.586,1.586,0,0,1-2.243-2.244l.142-.141a6.306,6.306,0,0,1-.679-1.637ZM8.551,9.344,6.965,10.93,10.137,14.1l4.758-4.758L13.309,7.758,10.137,10.93Z"
                                transform="translate(-3 -3)"
                                fill="#15f315"
                                fillRule="evenodd"
                              />
                            </svg>{" "}
                            Confirm Availability
                          </>
                        )}
                      </div>
                      <div className="d-inline-flex align-items-center me-3">
                        {certification && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.976"
                              className="me-1"
                              height="15.61"
                              viewBox="0 0 13.976 18.61"
                            >
                              <path
                                id="certificatealt"
                                d="M12.231,6.979l1.745,1.872-2.453.764L12.1,12.1,9.8,11.577l1.854,5.907a1.084,1.084,0,0,1-.509.945l-.945-.945L9.069,18.61a1.14,1.14,0,0,1-.654-.409,1.115,1.115,0,0,1-.254-.718L6.979,13.74,5.816,17.483a1.121,1.121,0,0,1-.254.718,1.134,1.134,0,0,1-.654.409L3.78,17.483l-.945.945a1.084,1.084,0,0,1-.509-.945L4.18,11.577,1.872,12.1l.582-2.49L0,8.851,1.745,6.979,0,5.107l2.453-.745-.582-2.49,2.49.582L5.107,0,6.979,1.745,8.851,0l.764,2.453,2.49-.582-.582,2.49,2.453.745ZM6.988,3.489A3.369,3.369,0,0,0,4.517,4.516,3.369,3.369,0,0,0,3.49,6.988,3.369,3.369,0,0,0,4.517,9.459a3.369,3.369,0,0,0,2.472,1.027A3.369,3.369,0,0,0,9.46,9.459a3.369,3.369,0,0,0,1.027-2.472A3.369,3.369,0,0,0,9.46,4.516,3.369,3.369,0,0,0,6.988,3.489Z"
                                fill="#e94242"
                              />
                            </svg>
                            certification
                          </>
                        )}
                      </div> */}
                      <div className="d-inline-flex align-items-center me-3">
                        {noOfLike} &nbsp;
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.099"
                          height="16.71"
                          viewBox="0 0 19.099 16.71"
                        >
                          <path
                            id="heart"
                            d="M12.941,21.962a16.791,16.791,0,0,0,8.283-7.307C22.73,11.82,22.942,9,21.6,7.3a5.227,5.227,0,0,0-4.389-2.047,6.971,6.971,0,0,0-4.271,1.718A6.971,6.971,0,0,0,8.67,5.255,5.227,5.227,0,0,0,4.281,7.3C2.94,9,3.152,11.82,4.681,14.655A16.629,16.629,0,0,0,12.941,21.962Z"
                            transform="translate(-3.392 -5.252)"
                            fill="#e94242"
                          />
                        </svg>
                      </div>
                      <div className="d-inline-flex align-items-center me-3">
                        <b>Parts Listing</b>
                      </div>
                      {!isPaid && (
                        <div className="d-inline-flex align-items-center">
                          <NavLink
                            onClick={() => setModalVisible(true)}
                            // to={`/list-vehicle-edit/${id}/upload`}
                            className="btn btn-theme-fill btn-small me-2"
                            style={{
                              padding: "4px 12px",
                              fontSize: "14px",
                              borderRadius: "20px",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            Publish It in $150.00
                          </NavLink>
                        </div>
                      )}

                      {!isSold ? (
                        <div className="d-inline-flex align-items-center">
                          <Popconfirm
                            title="Are you sure you want to set this vehicle as sold?"
                            onConfirm={() => handleVehicleSoldStatus(id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <NavLink
                              className="btn btn-theme-fill btn-small me-2"
                              style={{
                                padding: "4px 12px",
                                fontSize: "14px",
                                borderRadius: "20px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              Set as Sold
                            </NavLink>
                          </Popconfirm>
                        </div>
                      ) : (
                        <div className="d-inline-flex align-items-center me-3  text-danger">
                          <b>Sold</b>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaymentModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleOk}
        forAddVehicle
        amount="150.00"
        // amount={sPackage?.amount}
      />
    </>
  );
};

export default ListedPartsCard;
