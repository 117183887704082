import React, { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import { Link } from "react-router-dom";
import { WASABI_URL, baseURL } from "../../util/Api";

const SoldVehiclesCard = (props) => {
  const { thumbnail, title, id, isThumbnail, oldImage, newThumbnail } = props;
  const [resizedImageUrl, setResizedImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let url = "";
    if (!isThumbnail) {
      url = WASABI_URL + oldImage?.path;
    }
    console.log("url:", url); // Log the URL here
    //  resizeAndDisplayImage(url);
  }, [oldImage]);

  // Function to convert image URL to Blob object
  const urlToBlob = async (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error("Failed to load image"));
        }
      };
      xhr.onerror = () => {
        reject(new Error("Network error"));
      };
      xhr.send();
    });
  };

  // Function to resize and display the image
  const resizeAndDisplayImage = async (url) => {
    try {
      setLoading(true);
      const blob = await urlToBlob(url);
      console.log("blob", blob);
      Resizer.imageFileResizer(
        blob,
        300, // Maximum width
        225, // Maximum height
        "JPEG",
        100,
        0,
        (resizedUri) => {
          // Set the resized image URI to the state
          setResizedImageUrl(resizedUri);
        },
        "base64",
        300, // New width after cropping
        225, // New height after cropping
        "center" // Position to crop from
      );
      setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <>
      <Link to={`/vehicle-detail/${id}`}>
        <div
          className="col cd-box"
          style={{
            background:
              "linear-gradient(90deg, rgb(98, 101, 105) 0%, rgb(178 186 198) 100%) 0% 0% no-repeat padding-box padding-box transparent",
            color: "#fbfdff",
            height: "100%",
          }}
        >
          <div className=" mb-2 position-relative cr">
            <div
              className="cb-img"
              style={{
                backgroundImage: `url(${WASABI_URL + thumbnail?.path})`,
                height: "275px",
              }}
            ></div>
          </div>
          <div className="flexmedia px-2 pb-2">
            <div className="headiing">{title}</div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SoldVehiclesCard;
