import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Form, Input, Space, Spin, DatePicker } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import {
  setVehicles,
  setPage,
  setScrollPosition,
} from "../../redux/actions/browseVehiclesActions";
import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import BrowseVehiclesHeroImg from "../../Assets/Images/browserbg.jpg";
import VehiclesCard from "../../Component/Cards/VehiclesCard";
import { httpClient } from "../../util/Api";

const { RangePicker } = DatePicker;

const BrowseVehicles = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [finalQueryString, setFinalQueryString] = useState("");
  const dispatch = useDispatch();
  const [fromSearch, setFromSearch] = useState(false);
  const vehicles = useSelector((store) => store.browseVehicles.vehicles) || [];
  const page = useSelector((state) => state.browseVehicles.page);
  const scrollPosition = useSelector(
    (state) => state.browseVehicles.scrollPosition
  );
  const pageSize = 30;

  const hasMore = vehicles.length % 10 === 0 && vehicles.length !== 0;

  useEffect(() => {
    const queryString = new URLSearchParams();

    if (searchParams.get("query")) {
      queryString.append("title", searchParams.get("query"));
    }
    if (searchParams.get("fromYear")) {
      queryString.append("fromYear", searchParams.get("fromYear"));
    }
    if (searchParams.get("toYear")) {
      queryString.append("toYear", searchParams.get("toYear"));
    }
    if (searchParams.get("make")) {
      queryString.append("make", searchParams.get("make"));
    }
    if (searchParams.get("model")) {
      queryString.append("model", searchParams.get("model"));
    }

    const finalQueryString = queryString.toString();
    setFinalQueryString(finalQueryString);

    if (vehicles.length === 0) {
      // Only fetch if there are no vehicles already stored (i.e., on first load)
      dispatch(setPage(1)); // Reset to the first page on initial load
      fetchFilterVehicles(true, finalQueryString, 1, true); // Fetch the first page with clear flag
    } else {
      // Restore scroll position
      window.scrollTo(0, scrollPosition);
    } // Fetch the first page with clear flag
  }, []);

  useEffect(() => {
    if (page > 1) {
      fetchFilterVehicles(true, finalQueryString, page, false); // Fetch subsequent pages without clearing
    }
  }, [page]);

  const fetchFilterVehicles = async (guest, finalQueryString, page, clear) => {
    try {
      setLoading(true);

      const response = await httpClient.get(
        guest
          ? `vehicles/guest/${page}/30?${finalQueryString}`
          : `vehicles/${page}/30?${finalQueryString}`
      );

      console.log(response, "yh response aya");
      const fetchedVehicles = Array.isArray(response.data) ? response.data : [];

      if (clear) {
        dispatch(setVehicles(fetchedVehicles));
      } else {
        let updatedVehicles = [];
        // Append new data to the existing vehicles array
        if (!hasMore) {
          updatedVehicles = [...vehicles];
        } else {
          updatedVehicles = [...vehicles, ...fetchedVehicles];
        }
        dispatch(setVehicles(updatedVehicles));
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      setLoading(false);
    }
  };

  // console.log("page ", page);
  // console.log("vehicles", vehicles);

  const onFinish = (values) => {
    dispatch(setPage(1)); // Reset to the first page on search
    dispatch(setVehicles([])); // Clear current vehicles list

    const queryString = new URLSearchParams();
    for (const [key, value] of Object.entries(values)) {
      if (key === "yearRange") {
        queryString.append(
          "fromYear",
          value?.length >= 1 ? new Date(value[0])?.getFullYear() : ""
        );
        queryString.append(
          "toYear",
          value?.length === 2 ? new Date(value[1])?.getFullYear() : ""
        );
      } else {
        queryString.append(key, value);
      }
    }

    const finalQueryString = queryString.toString();
    setFinalQueryString(finalQueryString);
    fetchFilterVehicles(true, finalQueryString, 1, true); // Fetch the first page with clear flag
  };

  const handleCardClick = () => {
    dispatch(setScrollPosition(window.scrollY));
  };

  const [form] = Form.useForm();

  return (
    <>
      <PageLayout
        title="Browse Vehicles"
        type="website"
        imageWidth="1920"
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <div
          className="hero-header"
          style={{ backgroundImage: `url(${BrowseVehiclesHeroImg})` }}
        >
          <div className="background-overlay"></div>
          <div className="main-title">CARS FOR SALE</div>
          <div className="sub-title">Discover the Perfect Ride</div>
          <Link
            to="/list-your-vehicle"
            className="btn btn-theme mb-3 cta-button"
            style={{ zIndex: "2" }}
          >
            List a Vehicle
          </Link>
        </div>
        <Spin tip="Loading..." spinning={loading} size="large">
          <div className="sec-pad">
            <div className="container">
              <div className="row mb-4 mb-md-5">
                <Form
                  form={form}
                  onFinish={onFinish}
                  style={{
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  layout="inline"
                  initialValues={{ fromAmount: null, toAmount: null }}
                >
                  <Form.Item name="make">
                    <Input className="form-control fc" placeholder="Make" />
                  </Form.Item>
                  <Form.Item name="model">
                    <Input className="form-control fc" placeholder="Model" />
                  </Form.Item>
                  <Form.Item name="yearRange">
                    <RangePicker
                      style={{
                        fontFamily: "Futura Bold",
                        fontWeight: "normal",
                        height: "42px",
                      }}
                      picker="year"
                    />
                  </Form.Item>
                  <div className="browse-filter-buttons">
                    <Form.Item>
                      <Space>
                        <Button
                          onClick={() => form.resetFields()}
                          style={{
                            marginRight: "10px",
                            fontFamily: "Futura Bold",
                            fontWeight: "normal",
                          }}
                          danger
                        >
                          Clear
                        </Button>
                        <Button
                          style={{
                            fontFamily: "Futura Bold",
                            fontWeight: "normal",
                          }}
                          type="primary"
                          htmlType="submit"
                        >
                          Search
                        </Button>
                      </Space>
                    </Form.Item>
                  </div>
                </Form>
              </div>
              <InfiniteScroll
                dataLength={vehicles.length}
                next={() => {
                  dispatch(setPage(page + 1));
                }}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={<p>No more vehicles to show</p>}
                style={{ overflow: "hidden" }}
              >
                <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4">
                  {Array.isArray(vehicles) && vehicles.length > 0 ? (
                    vehicles.map((item) => (
                      <VehiclesCard
                        key={item._id}
                        id={item._id}
                        noOfLike={item.likes}
                        isLiked={item.isLiked}
                        title={[item.year, item.make, item.model].join(" ")}
                        price={item.price}
                        year={item.year}
                        page={page}
                        noOfImage={item.carImagesId.images?.length}
                        location={item.location}
                        availbility={item.availbility}
                        onlinePaperWork={item.onlinePaperWork}
                        number={item.number}
                        certification={item.certification}
                        testing={item.testing}
                        drivenMile={item.miles}
                        videoWalkaround={item.videoWalkaround}
                        testDrive={item.testDrive}
                        delivery={item.delivery}
                        isThumbnail={item?.carImagesId?.thumbnail || false}
                        thumbnail={item.carImagesId.images[0]}
                        oldImage={item?.carImagesId?.images[0]}
                        onClick={handleCardClick}
                        newThumbnail={item?.carImagesId?.thumbnail}
                      />
                    ))
                  ) : (
                    <h2>No vehicles found.</h2>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </Spin>
        <JoinClub />
      </PageLayout>
    </>
  );
};

export default BrowseVehicles;
