import React, { useState, useEffect } from "react";
import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import BrowseVehiclesHeroImg from "../../Assets/Images/part-hero-image-reduced-height1.png";
import VehiclesCard from "../../Component/Cards/VehiclesCard";
import { Link, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { baseURL, httpClient } from "../../util/Api";
import { Spin, DatePicker } from "antd";
import PartsCard from "../../Component/Cards/PartsCard";
import axios from "axios";
const { RangePicker } = DatePicker;
const BrowseParts = () => {
  // State for vehicle data
  const [searchParams] = useSearchParams();
  const [vehicles, setParts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const videosPerPage = 9;
  const offset = currentPage * videosPerPage;
  const videoSlice = vehicles.slice(offset, offset + videosPerPage);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const token = localStorage.getItem("token");

  const fetchParts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseURL}parts/guest`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setParts(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryString = new URLSearchParams();

    if (token) {
      fetchParts(false, queryString.toString());
    } else {
      fetchParts(true, queryString.toString());
    }
  }, []);
  return (
    <>
      <PageLayout
        type="website"
        imageWidth="1920"
        title="Browse Parts"
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <div
          className="hero-header"
          style={{ backgroundImage: `url(${BrowseVehiclesHeroImg})` }}
        >
          <div className="background-overlay"></div>
          <div className="main-title">PARTS FOR SALE</div>
          <div className="sub-title">Discover the Perfect Ride</div>
          <Link
            to="/list-parts-add"
            className="btn btn-theme mb-3 cta-button"
            style={{ zIndex: "2" }}
          >
            List a Part
          </Link>
        </div>
        <Spin tip="Loading..." spinning={loading} size="large">
          <div className="sec-pad pt-0">
            <div className="container">
              <div className="row mb-4 mb-md-5"></div>

              <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4">
                {videoSlice.map((item, index) => (
                  <PartsCard
                    key={item._id} // Remember to add a unique key prop for each child element in a list
                    id={item._id}
                    noOfLike={item?.likes}
                    isLiked={item?.isLiked}
                    partName={item.partName}
                    price={item?.price}
                    noOfImage={item?.partImagesId?.images?.length}
                    location={item?.location}
                    availbility={item?.availbility}
                    onlinePaperWork={item?.onlinePaperWork}
                    number={item?.phone}
                    condition={item?.condition}
                    certification={item?.certification}
                    testing={item?.testing}
                    drivenMile={item?.miles}
                    videoWalkaround={item?.videoWalkaround}
                    testDrive={item?.testDrive}
                    delivery={item?.delivery}
                    thumbnail={item?.partImagesId?.images[0]}
                  />
                ))}

                {videoSlice.length <= 0 && <h2>No Result</h2>}
              </div>

              <div className="row mt-4 mt-lg-5">
                <div className="col-12 text-end">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(vehicles.length / videosPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={
                      "pagination d-flex justify-content-end page-item"
                    }
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>
        <JoinClub />
      </PageLayout>
    </>
  );
};

export default BrowseParts;
