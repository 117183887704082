import React, { Component } from "react";
import { NavLink } from "react-router-dom";
export class JoinClub extends Component {
  render() {
    return (
      <>
        <>
          {/* Join The Club */}
          <div className="join-club sec-pad position-relative">
            <div className="join-club-overlay" />
            <div className="container z-1">
              <div className="row">
                <div className="col-12 col-md-11 col-lg-8">
                  <p className="text-light">Join The Club</p>
                  <div className="bold title mb-4 text-white1">
                    Get first access to our content, discounts, and vehicles!
                  </div>
                  <p className="text-white1">
                    For only $19.99/month, you get unparalleled <br />
                    discounts and first access!
                  </p>
                  <div className="mt-4">
                    <NavLink to="/#" className="btn btn-theme-fill">
                      Coming soon
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default JoinClub;
