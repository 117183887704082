import React, { useEffect, useState } from "react";
import { Button, Popconfirm, message } from "antd";
import PageLayout from "../../Layout/PageLayout";
import ProfileImage from "../../Assets/Images/User-avatar.png";
import MemberImg from "../../Assets/Images/images.png";
import UserImg from "../../Assets/Images/images1.png";
import { NavLink } from "react-router-dom";
import ProfileData from "../../Data/ProfileData";
import { useAuth } from "../../authentication";
import { baseURL, httpClient } from "../../util/Api";
import ImageUploader from "../../Component/UploadImage";
import PaymentModal from "../../Component/Modal/PaymentModal";
import stateList from "../../Data/statesList";
import { NumericFormat, PatternFormat } from "react-number-format";
import ReSubscribeModal from "../../Component/Modal/ReSubscribeModal";
import MemberShipModel from "../../Component/Modal/MemberShipModal";
import CanadaList from "../../Data/canadaList";
const Profile = () => {
  const [paymentModal, setPaymentModal] = useState(false);
  const [rePaymentModal, setRePaymentModal] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [cencelLoading, setCancelLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    location: "",
    locationTwo: "",
    city: "",
    country: "",
    state: "",
    postCode: "",
    phone: "",
    email: "",
  });
  const [errors, setErrors] = useState({});

  const {
    isLoadingUser,
    isLoading,
    getAuthUser,
    authUser,
    getSub,
    reSubscribe,
  } = useAuth();

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
    setRePaymentModal(false);
  };

  const handleOk = (cardVal, clearForm, paymentLoading) => {
    console.log(cardVal);
    if (authUser?.customer_payment_id) {
      getSub(authUser._id);
    } else {
      getSub(authUser._id, cardVal, closePaymentModal, paymentLoading);
    }
  };
  const handleSubscribeAgain = () => {
    setRePaymentModal(true);
  };
  const validateForm = () => {
    const {
      firstName,
      lastName,
      companyName,
      location,
      country,
      city,
      state,
      postCode,
      phone,
      email,
    } = formData;
    let errors = {};

    if (!firstName.trim()) {
      errors.firstName = "First Name is required";
    }
    if (!lastName.trim()) {
      errors.lastName = "Last Name is required";
    }
    if (!location.trim()) {
      errors.location = "Address is required";
    }
    if (!country.trim()) {
      errors.country = "Country is required";
    }
    if (!city.trim()) {
      errors.city = "City is required";
    }
    if (!state.trim()) {
      errors.state = "State is required";
    }
    if (!postCode.trim()) {
      errors.postCode = "Post Code is required";
    } else if (postCode.length !== 5) {
      errors.postCode = "Please write Correct Postcode";
    }
    const phoneRegex = /^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
    if (!phone.trim()) {
      errors.phone = "Phone is required";
    } else if (!phoneRegex.test(phone)) {
      errors.phone = "Invalid phone number";
    }
    if (!email.trim()) {
      errors.email = "Email Address is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      httpClient
        .put("users/profile", formData)
        .then(({ data }) => {
          getAuthUser();
          message.success("User updated");
        })
        .catch(function (error) {
          message.error(error.response.data.message);
        });
    } else {
      message.error("Please correct the errors in the form.");
    }
  };
  const handleReSubscribeSubmit = (cardVal, clearForm, paymentLoading) => {
    reSubscribe(authUser._id, cardVal, closePaymentModal, paymentLoading);
  };

  const handlePayment = (cardVal) => {
    // if (authUser?.customer_payment_id) {
    //   setPaymentPopup(true);
    // } else {
    setPaymentModal(true);
    // }
  };

  const handleCancelSub = (cardVal) => {
    setCancelLoading(true);
    httpClient
      .post("auth/subscription-cancel")
      .then(({ data }) => {
        getAuthUser();
        message.success("your subscription is canceled now");
        setCancelLoading(false);
      })
      .catch(function (error) {
        setCancelLoading(false);
        message.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if (!isLoadingUser && authUser && !isLoading) {
      setFormData(authUser);
      console.log(authUser);
    }
  }, [isLoadingUser, authUser, isLoading]);
  return (
    <>
      <PageLayout
        type="website"
        imageWidth="1920"
        title="Profile"
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <>
          {/* content */}
          <div className="sec-pad pt-0 pt-lg-4">
            <div className="container">
              <div className="row g-4">
                <div className="col-md-12 mb-2">
                  <h2 className="h3-title mb-2 bold">My Profile</h2>
                  <p className="mb-0">Hello, {authUser?.firstName}</p>
                </div>
                <div className="col-md-5 col-lg-4">
                  <div className="billing-box text-center">
                    {authUser && authUser?.profile?.src !== "" ? (
                      <div className="profile-container mb-3">
                        <img
                          src={
                            baseURL +
                            "files/get-profile/" +
                            authUser?.profile?.src
                          }
                          className="profile-img-big"
                          alt="img"
                        />
                        <div className="edit-icon">
                          <ImageUploader />
                        </div>
                      </div>
                    ) : (
                      <div className="profile-container mb-3">
                        <img
                          src={ProfileImage}
                          className="profile-img-big mb-3"
                          alt="img"
                        />
                        <div className="edit-icon">
                          <ImageUploader />
                        </div>
                      </div>
                    )}
                    <h3 className="h3-title mb2">{authUser?.firstName}</h3>
                    <p className="mb-4">Phone - {authUser?.phone}</p>
                    <div className="text-center row g-2">
                      <div className="col-12">
                        <NavLink
                          to="/list-your-vehicle"
                          className="btn btn-theme"
                        >
                          Add Vehicle
                        </NavLink>
                      </div>
                      <div className="col-12">
                        <NavLink
                          to="/listed-vehicle"
                          className="btn btn-theme-fill"
                        >
                          Listed Vehicles
                        </NavLink>
                      </div>
                      <div className="col-12">
                        <NavLink
                          to="/listed-parts"
                          className="btn btn-theme-fill"
                        >
                          Listed Parts
                        </NavLink>
                      </div>
                    </div>
                  </div>
                  {/* <div className="billing-box mt-4">
                    <h2 className="h3-title mb-3 bold">Purchased</h2>
                    <ul className="pur-box list-unstyled mb-0">
                      {ProfileData[0].purchased.map((item, index) => (
                        <li key={index}>
                          <div className="ft-18 mb-1 bold">
                            {item.packageName}
                          </div>
                          <p className="small mb-0">{item.date}</p>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  <div className="billing-box mt-4">
                    <h2 className="h3-title mb-3 bold text-center">
                      Membership Status
                    </h2>
                    {authUser?.role === "member" ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          alt="member-icon"
                          src={MemberImg}
                          style={{ height: "80px" }}
                        />
                      </div>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          alt="member-icon"
                          src={UserImg}
                          style={{ height: "80px" }}
                        />
                      </div>
                    )}
                    <div className="d-flex align-items-center justify-content-center">
                      Status: {authUser?.role}
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      {authUser?.role === "user" && (
                        <Popconfirm
                          title="Become a Member"
                          description="$19.99 per month"
                          open={paymentPopup}
                          onConfirm={handleOk}
                          onCancel={() => setPaymentPopup(false)}
                        >
                          <div className="d-flex align-items-center justify-content-center mt-3">
                            <button
                              onClick={handlePayment}
                              className="btn btn-theme-fill"
                            >
                              Become a Member
                            </button>
                          </div>
                        </Popconfirm>
                      )}
                    </div>
                    <div className="">
                      {authUser?.role === "member" &&
                        (!authUser?.billing_schedule_id ? (
                          <>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                              Subscription Canceled
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                              <button
                                onClick={handleSubscribeAgain}
                                className="btn btn-theme-fill"
                              >
                                Subscribe Again
                              </button>
                            </div>
                          </>
                        ) : (
                          <Popconfirm
                            title="Are you sure?"
                            description="Do you really want to cancel your subscription?"
                            onConfirm={handleCancelSub}
                          >
                            <div className="d-flex align-items-center justify-content-center mt-3">
                              <Button danger loading={cencelLoading}>
                                Cancel Subscription
                              </Button>
                            </div>
                          </Popconfirm>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-lg-8">
                  <div className="billing-box">
                    <h2 className="h3-title mb-2 bold">Edit the details</h2>
                    <form className="mt-4 mb-4">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-1">
                              First Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control fc"
                              placeholder=""
                              name="firstName"
                              value={formData?.firstName}
                              onChange={handleInputChange}
                            />
                            {errors.firstName && (
                              <div className="text-danger">
                                {errors.firstName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-1">
                              Last Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control fc"
                              placeholder=""
                              name="lastName"
                              value={formData?.lastName}
                              onChange={handleInputChange}
                            />
                            {errors.lastName && (
                              <div className="text-danger">
                                {errors.lastName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-1">
                              Company Name (Optional)
                            </label>
                            <input
                              type="text"
                              className="form-control fc"
                              placeholder=""
                              name="companyName"
                              value={formData?.companyName}
                              onChange={handleInputChange}
                            />
                            {errors.companyName && (
                              <div className="text-danger">
                                {errors.companyName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="mb-1">
                              Street Address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control fc mb-2"
                              placeholder="House number and street name"
                              name="location"
                              value={formData?.location}
                              onChange={handleInputChange}
                            />
                            <input
                              type="text"
                              className="form-control fc"
                              placeholder="Apartment, suite, unit, etc. (optional)"
                              name="locationTwo"
                              value={formData?.locationTwo}
                              onChange={handleInputChange}
                            />
                            {errors.location && (
                              <div className="text-danger">
                                {errors.location}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-1">
                              Country <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control fc form-select"
                              name="country"
                              value={formData?.country}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Country</option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="Canada">Canada</option>
                            </select>
                            {errors.country && (
                              <div className="text-danger">
                                {errors.country}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-1">
                              State/Province{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control fc form-select"
                              name="state"
                              value={formData?.state}
                              onChange={handleInputChange}
                            >
                              {formData?.country == "Canada"
                                ? CanadaList.map((item, index) => (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))
                                : stateList.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.abbreviation}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                            </select>
                            {errors.state && (
                              <div className="text-danger">{errors.state}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-1">
                              Postal Code <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control fc"
                              placeholder=""
                              name="postCode"
                              value={formData?.postCode}
                              onChange={handleInputChange}
                            />
                            {errors.postCode && (
                              <div className="text-danger">
                                {errors.postCode}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-1">
                              Phone <span className="text-danger">*</span>
                            </label>
                            <PatternFormat
                              format="+1 (###) ###-####"
                              mask="_"
                              value={formData?.phone}
                              onValueChange={(value) => {
                                setFormData({
                                  ...formData,
                                  phone: value.formattedValue,
                                });
                              }}
                              className="form-control fc"
                            />
                            {errors.phone && (
                              <div className="text-danger">{errors.phone}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-1">
                              City <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control fc"
                              placeholder=""
                              name="city"
                              value={formData?.city}
                              onChange={handleInputChange}
                            />
                            {errors.city && (
                              <div className="text-danger">{errors.city}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-1">
                              Email Address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control fc"
                              placeholder=""
                              name="email"
                              value={formData?.email}
                              onChange={handleInputChange}
                            />
                            {errors.email && (
                              <div className="text-danger">{errors.email}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-3">
                        <button
                          className="btn btn-theme-red"
                          onClick={handleSubmit}
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </PageLayout>
      <MemberShipModel
        visible={paymentModal}
        onCancel={() => {
          setPaymentModal(false);
        }}
        onOk={handleOk}
      />
      <MemberShipModel
        visible={rePaymentModal}
        onCancel={() => {
          setRePaymentModal(false);
        }}
        onOk={handleReSubscribeSubmit}
      />
    </>
  );
};

export default Profile;
