import React, { Component } from "react";
import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import VideoHeroImg from "../../Assets/Images/videos-hero-img.jpg";
import Rjays from "../../Assets/Images/vendor-benefits/Rjays-speedshop-logo.jpeg";
import precision from "../../Assets/Images/vendor-benefits/precision-hotrods-logo.png";
import ricks from "../../Assets/Images/vendor-benefits/ricks-logo.png";
import california from "../../Assets/Images/vendor-benefits/california.jpg";
import vintagepro from "../../Assets/Images/vendor-benefits/vintagepro.png";
import greeningauto from "../../Assets/Images/vendor-benefits/greeningauto.jpg";
import aceofshades from "../../Assets/Images/vendor-benefits/aceofshades.jpg";
import sweetpatina from "../../Assets/Images/vendor-benefits/sweetpatina.jpg";
import speedshop from "../../Assets/Images/vendor-benefits/socal-speedshop-logo.png";
import scotts from "../../Assets/Images/vendor-benefits/scotts-hotrods-n-customs.jpg";
import hotrod from "../../Assets/Images/vendor-benefits/hotrod-haven-logo.png";
import wheelhub from "../../Assets/Images/vendor-benefits/wheelhub.jpg";
import truckhub from "../../Assets/Images/vendor-benefits/truckhub.png";
import Mustang from "../../Assets/Images/vendor-benefits/Mustang.png";
import chevy from "../../Assets/Images/vendor-benefits/chevy.png";
import delomos from "../../Assets/Images/vendor-benefits/delomos.jpg";
import loman from "../../Assets/Images/vendor-benefits/loman.jpg";

import bitchin from "../../Assets/Images/vendor-benefits/bitchin.jpg";
import murray from "../../Assets/Images/vendor-benefits/murray.jpg";
import speedanddesign from "../../Assets/Images/vendor-benefits/speedanddesign.jpg";

import VideoCard from "../../Component/Cards/VideoCard";
import ReactPaginate from "react-paginate";
import { baseURL, httpClient } from "../../util/Api";
import { Link } from "react-router-dom";
import "./index.css";
import axios from "axios";

export class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      currentPage: 0,
      videosPerPage: 20,
    };
  }
  handlePageChange = ({ selected }) => {
    this.setState({ currentPage: selected });
  };

  // Function to fetch video data
  fetchVideos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        !token ? `${baseURL}files/videos/guest` : `${baseURL}files/videos`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.setState({ videos: response.data });
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  componentDidMount() {
    this.fetchVideos();
  }
  render() {
    const { currentPage, videosPerPage } = this.state;
    const offset = currentPage * videosPerPage;

    const videoSlice = this.state.videos.slice(offset, offset + videosPerPage);
    return (
      <>
        <PageLayout
          type="website"
          imageWidth="1920"
          title="Benefits"
          url="https://opposingcylinders.com/"
          description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
          image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
        >
          <div
            className="hero-header"
            style={{ backgroundImage: `url(${VideoHeroImg})` }}
          >
            <div className="background-overlay"></div>
            <div className="main-title">Membership Benefit Partners</div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <br />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4">
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a href="https://www.est1946.com" target="_blank">
                    <img src={speedshop} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">So-Cal Speed Shop</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://www.est1946.com"
                    target="_blank"
                  >
                    https://www.est1946.com
                  </a>
                </div>
                <ul>
                  <li>10% off on all Apparel</li>
                  <li>$250 off on any perimeter chassis</li>
                  <li>$500 of any complete roller chassis</li>
                </ul>
              </div>
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a href="http://precisionhotrodsandfab.com" target="_blank">
                    <img src={precision} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">
                  Precision Hot Rods and Fabrication
                </h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="http://precisionhotrodsandfab.com"
                    target="_blank"
                  >
                    http://precisionhotrodsandfab.com
                  </a>
                </div>
                <ul>
                  <li>5% off on all new chassis orders</li>
                </ul>
              </div>
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a href="https://rjays.com" target="_blank">
                    {" "}
                    <img src={Rjays} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">RJay’s Speed Shop</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://rjays.com"
                    target="_blank"
                  >
                    https://rjays.com
                  </a>
                </div>
                <ul>
                  <li>
                    10% off all parts with free shipping over $100 (except
                    chassis).
                  </li>
                  <li>$250 off any perimeter chassis</li>
                  <li> $500 off full rolling chassis</li>
                </ul>
              </div>
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a href="https://rickstanks.com" target="_blank">
                    <img src={ricks} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Rick’s Tanks</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://rickstanks.com"
                    target="_blank"
                  >
                    https://rickstanks.com
                  </a>
                </div>
                <ul>
                  <li>10% off on all gas tank items</li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="https://www.calcarcover.com/partner?n=opposing-cylinders
"
                    target="_blank"
                  >
                    <img src={california} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">California Car Cover</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://www.calcarcover.com/partner?n=opposing-cylinders
"
                    target="_blank"
                  >
                    https://www.calcarcover.com/partner?n=opposing-cylinders
                  </a>
                </div>
                <ul>
                  <li>Use Code: OPCYL10 for 10% off on any order</li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="https://sweetpatina.com/

"
                    target="_blank"
                  >
                    <img
                      style={{ height: "200px" }}
                      src={sweetpatina}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Sweet Patina</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://sweetpatina.com/

"
                    target="_blank"
                  >
                    https://sweetpatina.com/
                  </a>
                </div>
                <ul>
                  <li>Use code OC5 for 5% off on any order</li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="

"
                    target="_blank"
                  >
                    <img src={aceofshades} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Ace of Shades</h3>
                <div className="mt-2 mb-2 bold"></div>
                <ul>
                  <li>Use code OC5 for 5% off on any order</li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href=" https://vintageproseries.com/

"
                    target="_blank"
                  >
                    <img src={vintagepro} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Vintage Pro</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href=" https://vintageproseries.com/

"
                    target="_blank"
                  >
                    https://vintageproseries.com/
                  </a>
                </div>
                <ul>
                  <li>Use code OC5 for 5% off on any order</li>
                </ul>
              </div>
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="  https://www.greeningautocompany.com


"
                    target="_blank"
                  >
                    <img src={greeningauto} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Greening Auto Company</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="  https://www.greeningautocompany.com


"
                    target="_blank"
                  >
                    https://www.greeningautocompany.com
                  </a>
                </div>
                <ul>
                  <li>10% off on all products</li>
                </ul>
              </div>
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="  https://scottshotrods.com/


"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={scotts}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">
                  Scott's Hotrods 'N Customs
                </h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="  https://scottshotrods.com/
"
                    target="_blank"
                  >
                    https://scottshotrods.com/
                  </a>
                </div>
                <ul>
                  <li>5% OFF Suspension Kits & Chassis.</li>
                </ul>
              </div>
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href=" richard.j@hrhtransportation.com


"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={hotrod}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Hotrod Haven LLC</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="  richard.j@hrhtransportation.com
"
                    target="_blank"
                  >
                    richard.j@hrhtransportation.com
                  </a>
                </div>
                <ul>
                  <li>
                    10 off transport/enclosed shipping, up to $200" for all Crew
                    Members only. Call 469-631-5154 or email Richard at
                    richard.j@hrhtransportation.com
                  </li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href=" www.wheelhubmag.com/deals



"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={wheelhub}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Wheel Hub Magazine</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href=" www.wheelhubmag.com/deals
"
                    target="_blank"
                  >
                    www.wheelhubmag.com/deals
                  </a>
                </div>
                <ul>
                  <li>
                    Members receive 20% off any purchase using discount code
                    "CYLINDERS"
                  </li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href=" 
https://www.truckhubmag.com/



"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={truckhub}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">TruckHub Magazine</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="   https://www.truckhubmag.com/
"
                    target="_blank"
                  >
                    https://www.truckhubmag.com/
                  </a>
                </div>
                <ul>
                  <li>
                    Members will receive 20% off any purchase using the code.
                  </li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="https://www.mustanghubmag.com/



"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={Mustang}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Mustang Hub Magazine</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="  https://www.mustanghubmag.com/

"
                    target="_blank"
                  >
                    https://www.mustanghubmag.com/
                  </a>
                </div>
                <ul>
                  <li>
                    Members will receive 20% off any purchase using the code.
                  </li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="https://www.chevyhubmag.com/


"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={chevy}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Chevy Hub</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://www.chevyhubmag.com/

"
                    target="_blank"
                  >
                    https://www.chevyhubmag.com/
                  </a>
                </div>
                <ul>
                  <li>
                    Members will receive 20% off any purchase using the code.
                  </li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="https://delmospeed.com/discount/OPPOSINGCYLINDERS10


"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={delomos}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Delmo's Speed</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://delmospeed.com/discount/OPPOSINGCYLINDERS10

"
                    target="_blank"
                  >
                    https://delmospeed.com/discount/OPPOSINGCYLINDERS10
                  </a>
                </div>
                <ul>
                  <li>
                    Members can use code OPPOSINGCYLINDERS10 for 10% off their
                    entire order. Link to site with the discount code is below.
                  </li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="https://absolutesheetmetal.com/


"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={bitchin}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Bitchin' Parts</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://absolutesheetmetal.com/
"
                    target="_blank"
                  >
                    https://absolutesheetmetal.com/
                  </a>
                  <p>Order Line: 928-420-1254</p>
                </div>
                <ul>
                  <li>
                    5% discount on all parts. Use code Opposing Cylinders.
                  </li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="https://www.murraykustomrods.com/index.php



"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={murray}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Murray Kustom Rods</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://www.murraykustomrods.com/index.php

"
                    target="_blank"
                  >
                    https://www.murraykustomrods.com/index.php
                  </a>
                </div>
                <ul>
                  <li>Use code Opposing Cylinders for 5% off all parts. </li>
                  <li>Email: info@murraykustomrods.com</li>
                  <li> Phone: (817) 468-7637</li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="



"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={speedanddesign}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Speed And Design</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="

"
                    target="_blank"
                  ></a>
                </div>
                <ul>
                  <li>
                    Call or email for discounts on full interiors, seat
                    coverings, custom key chains, and other specialty items.{" "}
                  </li>
                  <li>Email: speedanddesign@comcast.net</li>
                  <li> Call: (505) 480-3461</li>
                  <li>Instagram: @speed_design</li>
                </ul>
              </div>

              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a
                    href="

https://lo-manrods.com/

"
                    target="_blank"
                  >
                    <img
                      style={{ width: "300px", height: "250px" }}
                      src={loman}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Lo-Man Rods</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="
https://lo-manrods.com/
"
                    target="_blank"
                  >
                    https://lo-manrods.com/
                  </a>
                </div>
                <ul>
                  <li>
                    Call (937) 679-5140 and let them know you are an Opposing
                    Cylinders member for 5% off their custom exhaust brackets on
                    discounts on other products.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <JoinClub />
        </PageLayout>
      </>
    );
  }
}

export default Video;
