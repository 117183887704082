import React, { Component } from "react";
import PageLayout from "../../Layout/PageLayout";
import { NavLink } from "react-router-dom";
import ListingFeatures from "../../Data/ListingFeature";
export class ListVehicle extends Component {
  render() {
    return (
      <>
        <PageLayout
          type="website"
          imageWidth="1920"
          title="List Vehicle"
          url="https://opposingcylinders.com/"
          description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
          image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
        >
          {/* content */}
          <div className="sec-pad pt-0">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="text-center mb-4">
                    <h2 className="h2-title mb-2 bold">LIST YOUR VEHICLE</h2>
                    <p>Welcome back ! List your vehicles here.</p>
                  </div>
                  <div className="">
                    <div className=" price-table-section">
                      <div className="row justify-content-center">
                        <div className="col-lg-12 col-xl-4 col-xxl-4 col-xs-12 pricetable-label">
                          <div className="price-table pt-radius1">
                            <div className="price-header">
                              <h5 className="pricetype bold  text-white1 text-start">
                                Listing Features
                              </h5>
                            </div>
                            <div className="price-details">
                              <ul className="price-list text-start">
                                {ListingFeatures.map((feature, index) => (
                                  <li key={index} className="lh-2">
                                    <div className="d-flex align-items-start">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12.91"
                                        height="11.912"
                                        className="me-2 mt-1"
                                        viewBox="0 0 15.91 15.912"
                                      >
                                        <g
                                          id="arrow-left-square"
                                          transform="translate(22.096 22.098) rotate(180)"
                                        >
                                          <path
                                            id="Path_24"
                                            data-name="Path 24"
                                            d="M18.883,25.7a1.136,1.136,0,0,0,0-1.609l-6.015-6.013,6.016-6.014a1.138,1.138,0,1,0-1.609-1.609l-6.818,6.818a1.136,1.136,0,0,0,0,1.609L17.275,25.7a1.136,1.136,0,0,0,1.609,0Z"
                                            transform="translate(-3.937 -3.937)"
                                            fill="#d9e4ee"
                                            fillRule="evenodd"
                                          />
                                          <path
                                            id="Path_25"
                                            data-name="Path 25"
                                            d="M26.011,18.011a1.136,1.136,0,0,0-1.136-1.136H13.511a1.136,1.136,0,0,0,0,2.273H24.874A1.136,1.136,0,0,0,26.011,18.011Z"
                                            transform="translate(-3.914 -3.869)"
                                            fill="#d9e4ee"
                                            fillRule="evenodd"
                                          />
                                        </g>
                                      </svg>
                                      <div>{feature.title}</div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-2 col-xs-12">
                          <div className="price-table bg-success h-100">
                            <div className="price-header">
                              <h5 className="pricetype bold  text-theme">
                                Premium Plus
                              </h5>
                            </div>
                            <div className="price-details">
                              <ul className="price-list">
                                {ListingFeatures.map((feature, index) => (
                                  <li key={index} className="text-success lh-2">
                                    <span>{feature.title}</span>
                                    {feature.PremiumPlus}
                                    {feature.PremiumPlus === true && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="20"
                                        width="24"
                                        viewBox="0 0 448 512"
                                        fill="#09b715"
                                      >
                                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                      </svg>
                                    )}
                                    {feature.PremiumPlus === false && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16.827"
                                        height="16.771"
                                        viewBox="0 0 16.827 16.771"
                                      >
                                        <path
                                          id="close"
                                          d={
                                            "M16.827,5.72l-6.753,6.7,6.753,6.7L15.139,20.8l-6.753-6.7-6.7,6.7L0,19.114l6.7-6.7L0,5.72,1.689,4.031l6.7,6.7,6.753-6.7Z"
                                          }
                                          transform="translate(0 -4.031)"
                                          fill="#d81919"
                                        />
                                      </svg>
                                    )}
                                  </li>
                                ))}

                                <li className="pricing-t d-block">
                                  <div className="price-value h3-title bold">
                                    $200.00
                                  </div>
                                  <NavLink
                                    to="/list-vehicle-step?type=premium_plus"
                                    className="btn btn-theme-fill"
                                  >
                                    Purchase
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-2 col-xs-12">
                          <div className="price-table">
                            <div className="price-header">
                              <h5 className="pricetype bold  text-theme">
                                Premium
                              </h5>
                            </div>
                            <div className="price-details">
                              <ul className="price-list">
                                {ListingFeatures.map((feature, index) => (
                                  <li className=" lh-2">
                                    <span>{feature.title}</span>
                                    {feature.Premium}
                                    {feature.Premium === true && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="20"
                                        width="24"
                                        viewBox="0 0 448 512"
                                        fill="#09b715"
                                      >
                                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                      </svg>
                                    )}
                                    {feature.Premium === false && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16.827"
                                        height="16.771"
                                        viewBox="0 0 16.827 16.771"
                                      >
                                        <path
                                          id="close"
                                          d={
                                            "M16.827,5.72l-6.753,6.7,6.753,6.7L15.139,20.8l-6.753-6.7-6.7,6.7L0,19.114l6.7-6.7L0,5.72,1.689,4.031l6.7,6.7,6.753-6.7Z"
                                          }
                                          transform="translate(0 -4.031)"
                                          fill="#d81919"
                                        />
                                      </svg>
                                    )}
                                  </li>
                                ))}

                                <li className="pricing-t d-block">
                                  <div className="price-value h3-title bold">
                                    $150.00
                                  </div>
                                  <NavLink
                                    to="/list-vehicle-step?type=premium"
                                    className="btn btn-theme"
                                  >
                                    Purchase
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-2 col-xs-12">
                          <div className="price-table pt-radius mb-0">
                            <div className="price-header">
                              <h5 className="pricetype bold text-theme">
                                Basic
                              </h5>
                            </div>
                            <div className="price-details">
                              <ul className="price-list">
                                {ListingFeatures.map((feature, index) => (
                                  <li className=" lh-2">
                                    <span>{feature.title}</span>
                                    {feature.Basic}
                                    {feature.Basic === true && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="20"
                                        width="24"
                                        viewBox="0 0 448 512"
                                        fill="#09b715"
                                      >
                                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                      </svg>
                                    )}
                                    {feature.Basic === false && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16.827"
                                        height="16.771"
                                        viewBox="0 0 16.827 16.771"
                                      >
                                        <path
                                          id="close"
                                          d={
                                            "M16.827,5.72l-6.753,6.7,6.753,6.7L15.139,20.8l-6.753-6.7-6.7,6.7L0,19.114l6.7-6.7L0,5.72,1.689,4.031l6.7,6.7,6.753-6.7Z"
                                          }
                                          transform="translate(0 -4.031)"
                                          fill="#d81919"
                                        />
                                      </svg>
                                    )}
                                  </li>
                                ))}

                                <li className="pricing-t d-block">
                                  <div className="price-value h3-title bold">
                                    $99.00
                                  </div>
                                  <NavLink
                                    to="/list-vehicle-step?type=basic"
                                    className="btn btn-theme"
                                  >
                                    Purchase{" "}
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageLayout>
      </>
    );
  }
}

export default ListVehicle;
