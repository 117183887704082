import React, { Component } from "react";
import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import VideoHeroImg from "../../Assets/Images/videos-hero-img.jpg";
import VideoCard from "../../Component/Cards/VideoCard";
import ReactPaginate from "react-paginate";
import { httpClient } from "../../util/Api";
import { Link } from "react-router-dom";

export class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      currentPage: 0,
      videosPerPage: 20,
    };
  }
  handlePageChange = ({ selected }) => {
    this.setState({ currentPage: selected });
  };

  // Function to fetch video data
  fetchVideos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await httpClient.get(
        !token ? "files/videos/guest" : "files/videos"
      );
      this.setState({ videos: response.data });
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  componentDidMount() {
    this.fetchVideos();
  }
  render() {
    const { currentPage, videosPerPage } = this.state;
    const offset = currentPage * videosPerPage;

    const videoSlice = this.state.videos.slice(offset, offset + videosPerPage);
    return (
      <>
        <PageLayout
          type="website"
          imageWidth="1920"
          title="Videos"
          url="https://opposingcylinders.com/"
          description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
          image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
        >
          <div
            className="hero-header"
            style={{ backgroundImage: `url(${VideoHeroImg})` }}
          >
            <div className="background-overlay"></div>
            <div className="main-title">VIDEOS</div>
          </div>
          <div className="sec-pad pt-0 mt-5">
            <div className="container">
              <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4">
                {videoSlice.map((item, index) => (
                  <VideoCard key={index} video={item} />
                ))}
              </div>

              <div className="row mt-4 mt-lg-5">
                <div className="col-12 text-end">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(
                      this.state.videos.length / videosPerPage
                    )}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageChange}
                    containerClassName={
                      "pagination d-flex justify-content-end page-item"
                    }
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>

          <JoinClub />
        </PageLayout>
      </>
    );
  }
}

export default Video;
