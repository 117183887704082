import React, { Component } from "react";
import PageLayout from "../../Layout/PageLayout";
import JoinClub from "../../Layout/JoinClub";
import VideoHeroImg from "../../Assets/Images/videos-hero-img.jpg";
import Rjays from "../../Assets/Images/vendor-benefits/Rjays-speedshop-logo.jpeg";
import precision from "../../Assets/Images/vendor-benefits/precision-hotrods-logo.png";
import ricks from "../../Assets/Images/vendor-benefits/ricks-logo.png";
import speedshop from "../../Assets/Images/vendor-benefits/socal-speedshop-logo.png";
import VideoCard from "../../Component/Cards/VideoCard";
import ReactPaginate from "react-paginate";
import { httpClient } from "../../util/Api";
import { Link } from "react-router-dom";
import "./index.css";

export class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      currentPage: 0,
      videosPerPage: 20,
    };
  }
  handlePageChange = ({ selected }) => {
    this.setState({ currentPage: selected });
  };

  // Function to fetch video data
  fetchVideos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await httpClient.get(
        !token ? "files/videos/guest" : "files/videos"
      );
      this.setState({ videos: response.data });
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  componentDidMount() {
    this.fetchVideos();
  }
  render() {
    const { currentPage, videosPerPage } = this.state;
    const offset = currentPage * videosPerPage;

    const videoSlice = this.state.videos.slice(offset, offset + videosPerPage);
    return (
      <>
        <PageLayout
          type="website"
          imageWidth="1920"
          title="Benefits"
          url="https://opposingcylinders.com/"
          description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
          image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
        >
          <div
            className="hero-header"
            style={{ backgroundImage: `url(${VideoHeroImg})` }}
          >
            <div className="background-overlay"></div>
            <div className="main-title">Membership Benefit Partners</div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <br />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4">
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a href="https://www.est1946.com" target="_blank">
                    <img src={speedshop} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">So-Cal Speed Shop</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://www.est1946.com"
                    target="_blank"
                  >
                    https://www.est1946.com
                  </a>
                </div>
                <ul>
                  <li>10% off on all Apparel</li>
                  <li>$250 off on any perimeter chassis</li>
                  <li>$500 of any complete roller chassis</li>
                </ul>
              </div>
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a href="http://precisionhotrodsandfab.com" target="_blank">
                    <img src={precision} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">
                  Precision Hot Rods and Fabrication
                </h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="http://precisionhotrodsandfab.com"
                    target="_blank"
                  >
                    http://precisionhotrodsandfab.com
                  </a>
                </div>
                <ul>
                  <li>5% off on all new chassis orders</li>
                </ul>
              </div>
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a href="https://rjays.com" target="_blank">
                    {" "}
                    <img src={Rjays} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">RJay’s Speed Shop</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://rjays.com"
                    target="_blank"
                  >
                    https://rjays.com
                  </a>
                </div>
                <ul>
                  <li>
                    10% off all parts with free shipping over $100 (except
                    chassis).
                  </li>
                  <li>$250 off any perimeter chassis</li>
                  <li> $500 off full rolling chassis</li>
                </ul>
              </div>
              <div className="col">
                <div className="vendor-logo-img-div mb-4">
                  <a href="https://rickstanks.com" target="_blank">
                    <img src={ricks} className="img-fluid" alt="img" />
                  </a>
                </div>
                <h3 className="h3-title text-theme">Rick’s Tanks</h3>
                <div className="mt-2 mb-2 bold">
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    href="https://rickstanks.com"
                    target="_blank"
                  >
                    https://rickstanks.com
                  </a>
                </div>
                <ul>
                  <li>10% off on all gas tank items</li>
                </ul>
              </div>
            </div>
          </div>

          <JoinClub />
        </PageLayout>
      </>
    );
  }
}

export default Video;
