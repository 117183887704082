// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vendor-logo-img-div {
    border: 1px solid #ebebeb;
    padding: 10px 20px;
    height: 220px; /* Default height for smaller screens */
    display: flex;
    align-items: center; /* Aligns items vertically */
    justify-content: center;
    overflow: hidden; /* Prevents content from overflowing */
  }
  
  .vendor-logo-img-div img {
    object-fit: contain; /* Ensures the image scales without distortion */
    max-width: 100%; /* Ensures the image fits within the container */
    max-height: 100%    ; /* Ensures the image does not exceed the container height */
  }
  
  @media screen and (min-width: 1200px) {
    .vendor-logo-img-div {
      height: 260px; /* Adjust height for large screens */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Benifits/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa,EAAE,uCAAuC;IACtD,aAAa;IACb,mBAAmB,EAAE,4BAA4B;IACjD,uBAAuB;IACvB,gBAAgB,EAAE,sCAAsC;EAC1D;;EAEA;IACE,mBAAmB,EAAE,gDAAgD;IACrE,eAAe,EAAE,gDAAgD;IACjE,oBAAoB,EAAE,2DAA2D;EACnF;;EAEA;IACE;MACE,aAAa,EAAE,oCAAoC;IACrD;EACF","sourcesContent":[".vendor-logo-img-div {\n    border: 1px solid #ebebeb;\n    padding: 10px 20px;\n    height: 220px; /* Default height for smaller screens */\n    display: flex;\n    align-items: center; /* Aligns items vertically */\n    justify-content: center;\n    overflow: hidden; /* Prevents content from overflowing */\n  }\n  \n  .vendor-logo-img-div img {\n    object-fit: contain; /* Ensures the image scales without distortion */\n    max-width: 100%; /* Ensures the image fits within the container */\n    max-height: 100%    ; /* Ensures the image does not exceed the container height */\n  }\n  \n  @media screen and (min-width: 1200px) {\n    .vendor-logo-img-div {\n      height: 260px; /* Adjust height for large screens */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
