import React, { useState, useEffect } from "react";
import PageLayout from "../../Layout/PageLayout";
import ListedCard from "../../Component/Cards/ListedCard";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { baseURL, httpClient } from "../../util/Api";
import axios from "axios";

const ListedVehicle = () => {
  const [vehicles, setVehicles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const videosPerPage = 2;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * videosPerPage;
  const videoSlice = vehicles.slice(offset, offset + videosPerPage);

  const fetchVehicles = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(`${baseURL}vehicles/user-listed`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVehicles(response.data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, []);
  return (
    <>
      <PageLayout
        type="website"
        imageWidth="1920"
        title="Listed Vehicles"
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <>
          {/* content */}
          <div className="sec-pad pt-0 pt-lg-4">
            <div className="container">
              <div className="row align-items-center mb-4">
                <div className="col-md-8 mb-3 mb-lg-0">
                  <h2 className="h3-title mb-2 bold">Your Listed Vehicles</h2>
                  <p className="mb-0">Here are your listed vehicles</p>
                </div>
                <div className="col-md-4 text-start text-md-end">
                  <NavLink
                    to="/list-vehicle-step"
                    className="btn btn-theme-fill"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.987"
                      height="13.987"
                      className="me-2 plus-icon"
                      viewBox="0 0 13.987 13.987"
                    >
                      <path
                        id="plus"
                        d="M21.187,8.98v3.228h-5.38v5.38H12.58v-5.38H7.2V8.98h5.38V3.6h3.228V8.98Z"
                        transform="translate(-7.2 -3.6)"
                        fill="#fbfdff"
                      />
                    </svg>{" "}
                    Add Vehicle
                  </NavLink>
                </div>
              </div>
              <div className="row g-4">
                {videoSlice.map((item, index) => (
                  <ListedCard
                    id={item._id}
                    noOfLike={item.likes}
                    title={[item.year, item.make, item.model].join(" ")}
                    price={item.price}
                    status={item.active}
                    year={item.year}
                    location={item.location}
                    availbility={item.availbility}
                    onlinePaperWork={item.onlinePaperWork}
                    certification={item.certification}
                    miles={item.miles}
                    videoWalkaround={item.videoWalkaround}
                    testDrive={item.testDrive}
                    isPaid={item.isPaid}
                    isSold={item.isSold}
                    delivery={item.delivery}
                    isThumbnail={item?.carImagesId?.thumbnail || false}
                    thumbnail={item.carImagesId.images[0]}
                    newThumbnail={item?.carImagesId?.thumbnail}
                    fetchVehicles={fetchVehicles}
                    selectedPackage={item?.selectedPackage || null}
                  />
                ))}
              </div>
              <div className="row mt-4 mt-lg-5">
                <div className="col-12 text-end">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(vehicles.length / videosPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={
                      "pagination d-flex justify-content-end page-item"
                    }
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </PageLayout>
    </>
  );
};

export default ListedVehicle;
