import React, { useState, useEffect } from "react";
import VehiclesCard from "../../Component/Cards/VehiclesCard";
import VehicleList from "../../Data/VehicleList";
import { NavLink } from "react-router-dom";
import { baseURL, httpClient } from "../../util/Api";
import PartsCard from "../../Component/Cards/PartsCard";
import axios from "axios";

const RecentVehicleListing = () => {
  const [vehicles, setVehicles] = useState([]);
  const [parts, setParts] = useState([]);
  const token = localStorage.getItem("token");

  const fetchVehicles = async (guest) => {
    try {
      const response = await axios.get(
        guest
          ? `${baseURL}premiumVehiceParts/premium-guest`
          : `${baseURL}premiumVehiceParts/premium`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ); // Replace with your API endpoint
      setParts(response?.data?.parts);
      setVehicles(response?.data?.vehicles);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };
  useEffect(() => {
    if (token) {
      fetchVehicles();
    } else {
      fetchVehicles(true);
    }
  }, []);
  return (
    <>
      {/* Recent Vehicle Listing */}
      <div className="sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="bold h2-title">
                PREMIUM VEHICLE AND PART LISTINGS
              </h2>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 g-4">
            <>
              {vehicles.map((item, index) => (
                <VehiclesCard
                  from="premium"
                  key={item._id} // Remember to add a unique key prop for each child element in a list
                  id={item._id}
                  noOfLike={item.likes}
                  isLiked={item.isLiked}
                  title={[item.year, item.make, item.model].join(" ")}
                  price={item.price}
                  year={item.year}
                  noOfImage={item.carImagesId.images?.length}
                  location={item.location}
                  availbility={item.availbility}
                  onlinePaperWork={item.onlinePaperWork}
                  number={item.number}
                  certification={item.certification}
                  testing={item.testing}
                  drivenMile={item.miles}
                  videoWalkaround={item.videoWalkaround}
                  testDrive={item.testDrive}
                  delivery={item.delivery}
                  thumbnail={item.carImagesId.images[0]}
                  isThumbnail={item?.carImagesId?.thumbnail || false}
                  oldImage={item?.carImagesId?.images[0]}
                  newThumbnail={item?.carImagesId?.thumbnail}
                />
              ))}
            </>
            <>
              {parts?.length > 0 &&
                parts.slice(0, 6).map((item, index) => (
                  <PartsCard
                    key={item._id} // Remember to add a unique key prop for each child element in a list
                    id={item._id}
                    noOfLike={item?.likes}
                    isLiked={item?.isLiked}
                    partName={item.partName}
                    price={item?.price}
                    noOfImage={item?.partImagesId?.images?.length}
                    location={item?.location}
                    availbility={item?.availbility}
                    onlinePaperWork={item?.onlinePaperWork}
                    number={item?.phone}
                    condition={item?.condition}
                    certification={item?.certification}
                    testing={item?.testing}
                    drivenMile={item?.miles}
                    videoWalkaround={item?.videoWalkaround}
                    testDrive={item?.testDrive}
                    delivery={item?.delivery}
                    thumbnail={item?.partImagesId?.images[0]}
                  />
                ))}
            </>
          </div>
          <div className="row mt-5">
            <div className="text-center col-12">
              <NavLink to="/browse-parts" className="btn btn-theme">
                View All
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentVehicleListing;
