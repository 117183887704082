import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../Layout/PageLayout";
import MembershipFeatures from "../../Data/MembershipFeature.js";
import Slider from "react-slick"; // Import Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./hero.css";
import Rjays from "../../Assets/Images/vendor-benefits/Rjays-speedshop-logo.jpeg";
import precision from "../../Assets/Images/vendor-benefits/precision-hotrods-logo.png";
import ricks from "../../Assets/Images/vendor-benefits/ricks-logo.png";
import california from "../../Assets/Images/vendor-benefits/california.jpg";
import vintagepro from "../../Assets/Images/vendor-benefits/vintagepro.png";
import greeningauto from "../../Assets/Images/vendor-benefits/greeningauto.jpg";
import aceofshades from "../../Assets/Images/vendor-benefits/aceofshades.jpg";
import sweetpatina from "../../Assets/Images/vendor-benefits/sweetpatina.jpg";
import speedshop from "../../Assets/Images/vendor-benefits/socal-speedshop-logo.png";
import scotts from "../../Assets/Images/vendor-benefits/scotts-hotrods-n-customs.jpg";
import hotrod from "../../Assets/Images/vendor-benefits/hotrod-haven-logo.png";
import wheelhub from "../../Assets/Images/vendor-benefits/wheelhub.jpg";
import truckhub from "../../Assets/Images/vendor-benefits/truckhub.png";
import Mustang from "../../Assets/Images/vendor-benefits/Mustang.png";
import chevy from "../../Assets/Images/vendor-benefits/chevy.png";
import delomos from "../../Assets/Images/vendor-benefits/delomos.jpg";
import loman from "../../Assets/Images/vendor-benefits/loman.jpg";
import MemberShipModel from "../../Component/Modal/MemberShipModal.js";
import { useAuth } from "../../authentication/index.js";
import TShirt from "../../Component/Modal/ShirtModal.js";
import { baseURL, httpClient } from "../../util/Api.js";

import bitchin from "../../Assets/Images/vendor-benefits/bitchin.jpg";
import murray from "../../Assets/Images/vendor-benefits/murray.jpg";
import speedanddesign from "../../Assets/Images/vendor-benefits/speedanddesign.jpg";
import axios from "axios";
import ShirtModal from "../../Component/Modal/ShirtModal.js";
const MemberShipPackages = () => {
  const {
    isLoadingUser,
    isLoading,
    getAuthUser,
    authUser,
    upgradeSub,
    getSub,
    reSubscribe,
  } = useAuth();

  const navigate = useNavigate();
  const [paymentModal, setPaymentModal] = useState(false);
  const [Tshirt, setTshirt] = useState("");

  const [shirtModal, setshirtModal] = useState(false);

  const handlePackageSelection = (packageType) => {
    // Store the selected package type in localStorage
    localStorage.setItem("selectedPackage", packageType);
    // Check if the user is logged in (this assumes you have a way to check)
    const isLoggedIn = localStorage.getItem("token"); // Replace with actual auth check

    if (isLoggedIn) {
      if (authUser?.role === "user") {
        handlePayment();
      } else {
        navigate("/profile");
      }
    } else {
      console.log("not logged in");
      // If not logged in, redirect to the sign-up page
      navigate("/Membership-Test");
    }
  };

  const onTshitChange = async (values) => {
    setTshirt(values.shirtSize);
    const response = await httpClient.put(
      `users/updateshirtsize/${authUser._id}`,
      { shirtSize: values.shirtSize }
    );
    if (response.status === 200) {
      setshirtModal(false); // Close the modal
      window.location.href = "/thanksforcrewmembership";
    }
    console.log(response, "response");
  };
  const handleClosesShirt = () => {
    setshirtModal(false);
  };
  const handlePayment = (cardVal) => {
    // if (authUser?.customer_payment_id) {
    //   setPaymentPopup(true);
    // } else {
    setPaymentModal(true);
    // }
  };
  const token = localStorage.getItem("token");
  const getUser = async () => {
    const response = await axios.get(`${baseURL}auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response, "response");
    if (response.status === 200) {
      if (response.data.user.status === "Crew Member") {
        setshirtModal(true);
      }
    }
  };
  const closePaymentModal = () => {
    setPaymentModal(false);
    getUser();
  };
  const handleOk = (cardVal, clearForm, paymentLoading) => {
    if (authUser?.customer_payment_id) {
      getSub(authUser._id);
    } else {
      getSub(authUser._id, cardVal, closePaymentModal, paymentLoading);
    }
  };
  // Slick settings for the vendor carousel
  const settings = {
    dots: false, // Disable dots
    infinite: true, // Infinite loop
    speed: 500, // Speed of the transition
    slidesToShow: 5, // Show 4 items at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Autoplay
    autoplaySpeed: 3000, // Autoplay interval (3 seconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <PageLayout
      type="website"
      imageWidth="1920"
      title="List Vehicle"
      url="https://opposingcylinders.com/"
      description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
      image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
    >
      <div className="sec-pad pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center mb-1">
                <h2 className="h2-title mb-2 bold">Become a Member</h2>
                <p>
                  Sign up as a Club Member or Crew Member and get exclusive
                  benefits
                  <br /> from being a part of the Opposing Cylinders community!
                </p>
              </div>
              <div className="">
                <div className=" price-table-section">
                  <div className="row justify-content-center">
                    {/* Membership benefits */}
                    <div className="col-lg-12 col-xl-4 col-xxl-4 col-xs-12 pricetable-label">
                      <div className="price-table pt-radius1">
                        <div className="price-header">
                          <h5 className="pricetype bold text-white1 text-start">
                            Membership Benefits
                          </h5>
                        </div>
                        <div className="price-details">
                          <ul className="price-list text-start">
                            {MembershipFeatures.map((feature, index) => (
                              <li key={index} className="lh-2">
                                <div className="d-flex align-items-start">
                                  {/* SVG icon */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12.91"
                                    height="11.912"
                                    className="me-2 mt-1"
                                    viewBox="0 0 15.91 15.912"
                                  >
                                    <g
                                      id="arrow-left-square"
                                      transform="translate(22.096 22.098) rotate(180)"
                                    >
                                      <path
                                        id="Path_24"
                                        data-name="Path 24"
                                        d="M18.883,25.7a1.136,1.136,0,0,0,0-1.609l-6.015-6.013,6.016-6.014a1.138,1.138,0,1,0-1.609-1.609l-6.818,6.818a1.136,1.136,0,0,0,0,1.609L17.275,25.7a1.136,1.136,0,0,0,1.609,0Z"
                                        transform="translate(-3.937 -3.937)"
                                        fill="#d9e4ee"
                                        fillRule="evenodd"
                                      />
                                      <path
                                        id="Path_25"
                                        data-name="Path 25"
                                        d="M26.011,18.011a1.136,1.136,0,0,0-1.136-1.136H13.511a1.136,1.136,0,0,0,0,2.273H24.874A1.136,1.136,0,0,0,26.011,18.011Z"
                                        transform="translate(-3.914 -3.869)"
                                        fill="#d9e4ee"
                                        fillRule="evenodd"
                                      />
                                    </g>
                                  </svg>
                                  <div>{feature.title}</div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* Pricing tables */}
                    <div className="col-lg-12 col-xl-2 col-xs-12">
                      <div className="price-table bg-success h-100">
                        <div className="price-header">
                          <h5 className="pricetype bold text-theme">
                            Crew Members
                          </h5>
                        </div>
                        <div className="price-details">
                          <ul className="price-list">
                            {MembershipFeatures.map((feature, index) => (
                              <li key={index} className="text-success lh-2">
                                <span>{feature.title}</span>
                                {feature.crew}
                                {feature.crew === true && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="20"
                                    width="24"
                                    viewBox="0 0 448 512"
                                    fill="#09b715"
                                  >
                                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                  </svg>
                                )}
                                {feature.crew === false && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.827"
                                    height="16.771"
                                    viewBox="0 0 16.827 16.771"
                                  >
                                    <path
                                      id="close"
                                      d={
                                        "M16.827,5.72l-6.753,6.7,6.753,6.7L15.139,20.8l-6.753-6.7-6.7,6.7L0,19.114l6.7-6.7L0,5.72,1.689,4.031l6.7,6.7,6.753-6.7Z"
                                      }
                                      transform="translate(0 -4.031)"
                                      fill="#d81919"
                                    />
                                  </svg>
                                )}
                              </li>
                            ))}

                            <li className="pricing-t d-block">
                              <div className="price-value h3-title bold">
                                $219.89 <br /> per year
                              </div>
                              <button
                                onClick={() =>
                                  handlePackageSelection("premium_plus")
                                }
                                className="btn btn-theme-fill"
                              >
                                Purchase
                              </button>
                            </li>
                          </ul>
                          <div className="text-center align-self-center mb-4">
                            Upgrade to Crew Membership at Any Time{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-xl-2 col-xs-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h5 className="pricetype bold text-theme">
                            Club Members
                          </h5>
                        </div>
                        <div className="price-details">
                          <ul className="price-list">
                            {MembershipFeatures.map((feature, index) => (
                              <li className=" lh-2" key={index}>
                                <span>{feature.title}</span>
                                {feature.club}
                                {feature.club === true && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="20"
                                    width="24"
                                    viewBox="0 0 448 512"
                                    fill="#09b715"
                                  >
                                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                  </svg>
                                )}
                                {feature.club === false && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.827"
                                    height="16.771"
                                    viewBox="0 0 16.827 16.771"
                                  >
                                    <path
                                      id="close"
                                      d={
                                        "M16.827,5.72l-6.753,6.7,6.753,6.7L15.139,20.8l-6.753-6.7-6.7,6.7L0,19.114l6.7-6.7L0,5.72,1.689,4.031l6.7,6.7,6.753-6.7Z"
                                      }
                                      transform="translate(0 -4.031)"
                                      fill="#d81919"
                                    />
                                  </svg>
                                )}
                              </li>
                            ))}

                            <li className="pricing-t d-block">
                              <div className="price-value h3-title bold">
                                $19.99 <br /> per month
                              </div>
                              <button
                                onClick={() =>
                                  handlePackageSelection("premium")
                                }
                                className="btn btn-theme"
                              >
                                Purchase
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h3
          className="text-center"
          style={{ textAlign: "center", marginTop: "24px" }}
        >
          Our Vendor Partners with Member Benefits
        </h3>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <br />
          </div>
        </div>
        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4">
          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a href="https://www.est1946.com" target="_blank">
                <img src={speedshop} className="img-fluid" alt="img" />
              </a>
            </div>
            <h3 className="h3-title text-theme">So-Cal Speed Shop</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="https://www.est1946.com"
                target="_blank"
              >
                https://www.est1946.com
              </a>
            </div>
            <ul>
              <li>10% off on all Apparel</li>
              <li>$250 off on any perimeter chassis</li>
              <li>$500 of any complete roller chassis</li>
            </ul>
          </div>
          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a href="http://precisionhotrodsandfab.com" target="_blank">
                <img src={precision} className="img-fluid" alt="img" />
              </a>
            </div>
            <h3 className="h3-title text-theme">
              Precision Hot Rods and Fabrication
            </h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="http://precisionhotrodsandfab.com"
                target="_blank"
              >
                http://precisionhotrodsandfab.com
              </a>
            </div>
            <ul>
              <li>5% off on all new chassis orders</li>
            </ul>
          </div>
          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a href="https://rjays.com" target="_blank">
                {" "}
                <img src={Rjays} className="img-fluid" alt="img" />
              </a>
            </div>
            <h3 className="h3-title text-theme">RJay’s Speed Shop</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="https://rjays.com"
                target="_blank"
              >
                https://rjays.com
              </a>
            </div>
            <ul>
              <li>
                10% off all parts with free shipping over $100 (except chassis).
              </li>
              <li>$250 off any perimeter chassis</li>
              <li> $500 off full rolling chassis</li>
            </ul>
          </div>
          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a href="https://rickstanks.com" target="_blank">
                <img src={ricks} className="img-fluid" alt="img" />
              </a>
            </div>
            <h3 className="h3-title text-theme">Rick’s Tanks</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="https://rickstanks.com"
                target="_blank"
              >
                https://rickstanks.com
              </a>
            </div>
            <ul>
              <li>10% off on all gas tank items</li>
            </ul>
          </div>

          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="https://www.calcarcover.com/partner?n=opposing-cylinders
"
                target="_blank"
              >
                <img src={california} className="img-fluid" alt="img" />
              </a>
            </div>
            <h3 className="h3-title text-theme">California Car Cover</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="https://www.calcarcover.com/partner?n=opposing-cylinders
"
                target="_blank"
              ></a>
            </div>
            <ul>
              <li>10% off on any order</li>
            </ul>
          </div>

          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="https://sweetpatina.com/

"
                target="_blank"
              >
                <img
                  style={{ height: "200px" }}
                  src={sweetpatina}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Sweet Patina</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="https://sweetpatina.com/

"
                target="_blank"
              >
                https://sweetpatina.com/
              </a>
            </div>
            <ul>
              <li>5% off on any order</li>
            </ul>
          </div>

          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="

"
                target="_blank"
              >
                <img src={aceofshades} className="img-fluid" alt="img" />
              </a>
            </div>
            <h3 className="h3-title text-theme">Ace of Shades</h3>
            <div className="mt-2 mb-2 bold"></div>
            <ul>
              <li>5% off on any order</li>
            </ul>
          </div>

          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href=" https://vintageproseries.com/

"
                target="_blank"
              >
                <img src={vintagepro} className="img-fluid" alt="img" />
              </a>
            </div>
            <h3 className="h3-title text-theme">Vintage Pro</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href=" https://vintageproseries.com/

"
                target="_blank"
              >
                https://vintageproseries.com/
              </a>
            </div>
            <ul>
              <li> 5% off on any order</li>
            </ul>
          </div>
          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="  https://www.greeningautocompany.com


"
                target="_blank"
              >
                <img src={greeningauto} className="img-fluid" alt="img" />
              </a>
            </div>
            <h3 className="h3-title text-theme">Greening Auto Company</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="  https://www.greeningautocompany.com


"
                target="_blank"
              >
                https://www.greeningautocompany.com
              </a>
            </div>
            <ul>
              <li>10% off on all products</li>
            </ul>
          </div>
          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="  https://scottshotrods.com/


"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={scotts}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Scott's Hotrods 'N Customs</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="  https://scottshotrods.com/
"
                target="_blank"
              >
                https://scottshotrods.com/
              </a>
            </div>
            <ul>
              <li>5% OFF Suspension Kits & Chassis.</li>
            </ul>
          </div>
          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href=" richard.j@hrhtransportation.com


"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={hotrod}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Hotrod Haven LLC</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="  richard.j@hrhtransportation.com
"
                target="_blank"
              ></a>
            </div>
            <ul>
              <li>
                10 off transport/enclosed shipping, up to $200 for all Crew
                Members only.
              </li>
            </ul>
          </div>

          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="https://www.wheelhubmag.com/deals



"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={wheelhub}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Wheel Hub Magazine</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href=" https://www.wheelhubmag.com
"
                target="_blank"
              >
                https://www.wheelhubmag.com
              </a>
            </div>
            <ul>
              <li>
                Members receive 20% off any purchase using our discount code.
              </li>
            </ul>
          </div>

          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href=" 
https://www.truckhubmag.com/



"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={truckhub}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">TruckHub Magazine</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="   https://www.truckhubmag.com/
"
                target="_blank"
              >
                https://www.truckhubmag.com/
              </a>
            </div>
            <ul>
              <li>
                Members receive 20% off any purchase using our discount code.
              </li>
            </ul>
          </div>

          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="https://www.mustanghubmag.com/



"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={Mustang}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Mustang Hub Magazine</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="  https://www.mustanghubmag.com/

"
                target="_blank"
              >
                https://www.mustanghubmag.com/
              </a>
            </div>
            <ul>
              <li>
                Members receive 20% off any purchase using our discount code.
              </li>
            </ul>
          </div>

          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="https://www.chevyhubmag.com/


"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={chevy}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Chevy Hub</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="https://www.chevyhubmag.com/

"
                target="_blank"
              >
                https://www.chevyhubmag.com/
              </a>
            </div>
            <ul>
              <li>
                Members receive 20% off any purchase using our discount code.
              </li>
            </ul>
          </div>

          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="https://delmospeed.com


"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={delomos}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Delmo's Speed</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="https://delmospeed.com

"
                target="_blank"
              >
                https://delmospeed.com{" "}
              </a>
            </div>
            <ul>
              <li>
                Members can use our discount code for 10% off their entire
                order.
              </li>
            </ul>
          </div>

          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="https://absolutesheetmetal.com/


"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={bitchin}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Bitchin' Parts</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="https://absolutesheetmetal.com/
"
                target="_blank"
              >
                https://absolutesheetmetal.com/
              </a>
            </div>
            <ul>
              <li>Members receive a 5% discount on all parts.</li>
            </ul>
          </div>
          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="https://www.murraykustomrods.com/index.php



"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={murray}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Murray Kustom Rods</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="https://www.murraykustomrods.com/index.php

"
                target="_blank"
              >
                https://www.murraykustomrods.com/index.php
              </a>
            </div>
            <ul>
              <li>Members get 5% off on all parts.</li>
            </ul>
          </div>
          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="



"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={speedanddesign}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Speed And Design</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="

"
                target="_blank"
              ></a>
            </div>
            <ul>
              <li>
                Members get various discount rates on full interiors, seat
                coverings, custom key chains, and other specialty items.
              </li>
            </ul>
          </div>
          <div className="col">
            <div className="vendor-logo-img-div mb-4">
              <a
                href="



"
                target="_blank"
              >
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={loman}
                  className="img-fluid"
                  alt="img"
                />
              </a>
            </div>
            <h3 className="h3-title text-theme">Lo-Man Rods</h3>
            <div className="mt-2 mb-2 bold">
              <a
                style={{ color: "blue", cursor: "pointer" }}
                href="

"
                target="_blank"
              ></a>
            </div>
            <ul>
              <li>
                5% off custom exhaust brackets and discounts on other products.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <MemberShipModel
        visible={paymentModal}
        onCancel={() => {
          setPaymentModal(false);
        }}
        onOk={handleOk}
      />
      <ShirtModal
        visible={shirtModal}
        // values={}
        onCancel={() => {
          handleClosesShirt(null);
        }}
        onOk={(values) => {
          onTshitChange(values);
        }}
      />
    </PageLayout>
  );
};

export default MemberShipPackages;
