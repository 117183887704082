import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Divider,
  Spin,
  message,
  Checkbox,
} from "antd";
import { useAuth } from "../../authentication";
import { PatternFormat } from "react-number-format";
import { NavLink } from "react-router-dom";

const PaymentModal = ({
  visible,
  onCancel,
  user,
  onOk,
  showSkipButton,
  forAddVehicle,
  amount,
  membershipAmount,
}) => {
  const [loading, setLoading] = useState(false);
  const [checked, setCheck] = useState(false);
  const { authUser } = useAuth();
  const [form] = Form.useForm();
  function clearForm() {
    form.resetFields();
  }
  const laodingFunctions = {
    start: () => {
      setLoading(true);
    },
    success: () => {
      setLoading(false);
    },
    error: (error) => {
      setLoading(false);
      message.error("Please use valid payment source.");
    },
  };
  const handleOk = async () => {
    try {
      console.log(checked);
      if (!checked) {
        message.error("please input all values");
        return;
      }
      await form.validateFields().then((values) => {
        console.log(values);

        if (authUser.country === "Canada" && values.zip.length === 3) {
          // Append two zeros to the ZIP code
          values.zip = `${values.zip}00`;
        }
        if (forAddVehicle) {
          onOk(
            {
              ...values,
              amount,
              // type: !authUser?.customer_payment_id ? "card" : "customerId",
              type: "card",
              user_role: authUser?.role === "user",
            },
            clearForm,
            laodingFunctions
          );
        } else {
          onOk(values, clearForm, laodingFunctions);
        }
      });
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  return (
    <Spin tip="Loading..." spinning={loading} delay={200} size="large">
      <Modal
        title="Card Payment"
        visible={visible}
        onCancel={onCancel}
        onOk={handleOk}
        footer={null}
      >
        {forAddVehicle && (
          <div>
            <b>Amount: </b>$ {amount}
          </div>
        )}
        {membershipAmount && (
          <div>
            <b>Amount: </b>$ {membershipAmount}
          </div>
        )}
        <Form form={form} layout="vertical">
          {forAddVehicle ? (
            // (!authUser || !authUser?.customer_payment_id) && (
            <>
              <Form.Item
                label="Cardholder Name"
                name="cardholder"
                rules={[
                  {
                    required: true,
                    message: "Please enter the cardholder name",
                  },
                ]}
              >
                <Input placeholder="Card Holder Name" />
              </Form.Item>
              <Form.Item
                label="Card Number"
                name="cardNumber"
                rules={[
                  { required: true, message: "Please enter the card number" },
                ]}
              >
                <PatternFormat
                  placeholder="0000 0000 0000 0000"
                  type="tel"
                  className="ant-input"
                  format="#### #### #### ####"
                  mask="_"
                />
              </Form.Item>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  style={{ width: "48%" }}
                  label="Expiration Date"
                  name="expiry"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the expiration date",
                    },
                  ]}
                >
                  <PatternFormat
                    placeholder="MM/YY"
                    type="tel"
                    className="ant-input"
                    format="##/##"
                    mask="_"
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: "48%" }}
                  label="CVC"
                  name="cvc"
                  rules={[{ required: true, message: "Please enter the CVC" }]}
                >
                  <PatternFormat
                    placeholder="000"
                    type="tel"
                    className="ant-input"
                    format="###"
                    mask="_"
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  color: "red",
                  marginBottom: "8px",
                  fontWeight: "bold",
                }}
              >
                Enter the three digits from your postal code
              </div>
              <Form.Item
                label="ZIP Code"
                name="zip"
                rules={[
                  { required: true, message: "Please enter the ZIP code" },
                ]}
              >
                <PatternFormat
                  type="tel"
                  className="ant-input"
                  format={authUser?.country === "Canada" ? "###" : "#####"}
                  mask="_"
                  // maxLength={authUser?.country === "Canada" ? 3 : 5}
                />
              </Form.Item>
            </>
          ) : (
            // )
            <>
              <Form.Item
                label="Cardholder Name"
                name="cardholder"
                rules={[
                  {
                    required: true,
                    message: "Please enter the cardholder name",
                  },
                ]}
              >
                <Input placeholder="Card Holder Name" />
              </Form.Item>
              <Form.Item
                label="Card Number"
                name="cardNumber"
                // initialValue="4242 4242 4242 4242"
                rules={[
                  { required: true, message: "Please enter the card number" },
                ]}
              >
                <PatternFormat
                  placeholder="0000 0000 0000 0000"
                  type="tel"
                  className="ant-input"
                  format="#### #### #### ####"
                  mask="_"
                />
              </Form.Item>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  style={{ width: "48%" }}
                  label="Expiration Date"
                  name="expiry"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the expiration date",
                    },
                  ]}
                >
                  <PatternFormat
                    placeholder="MM/YY"
                    type="tel"
                    className="ant-input"
                    format="##/##"
                    mask="_"
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: "48%" }}
                  label="CVC"
                  name="cvc"
                  rules={[{ required: true, message: "Please enter the CVC" }]}
                >
                  <PatternFormat
                    placeholder="000"
                    type="tel"
                    className="ant-input"
                    format="###"
                    mask="_"
                  />
                </Form.Item>
              </div>

              <Form.Item
                label="ZIP Code"
                name="zip"
                rules={[
                  { required: true, message: "Please enter the ZIP code" },
                ]}
              >
                <PatternFormat
                  type="tel"
                  className="ant-input"
                  format="#####"
                  mask="_"
                />
              </Form.Item>
            </>
          )}

          <Checkbox onChange={(e) => setCheck(true)}>
            <div>
              I have read and understand the{" "}
              <NavLink
                to="/terms-and-conditions"
                target="_blank"
                className="text-theme underline"
              >
                Terms and Conditions
              </NavLink>
              ,
              <NavLink
                to="/privacy-policy"
                target="_blank"
                className="text-theme underline"
              >
                Privacy Policy
              </NavLink>
              , and{" "}
              <NavLink
                to="/checkout-agreement"
                target="_blank"
                className="text-theme underline"
              >
                Checkout Agreement
              </NavLink>
              , and agree to be bound by them in full. I understand this
              agreement does not create any agency, partnership, or joint
              venture between me and Opposing Cylinders.{" "}
            </div>
          </Checkbox>

          <Divider />

          <div style={{ textAlign: "right" }}>
            {showSkipButton && (
              <Button
                loading={loading}
                danger
                onClick={onCancel}
                className="me-3"
              >
                Skip Payment
              </Button>
            )}
            <Button loading={loading} type="primary" onClick={handleOk}>
              Pay Now
            </Button>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
};

export default PaymentModal;
