import { useState, useEffect } from "react";

import VideoCard from "../../Component/Cards/VideoCard";
import { baseURL, httpClient } from "../../util/Api";
import { NavLink, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import SoldVehiclesCard from "../../Component/Cards/SoldVehiclesCard";
import HeroCard from "../../Component/Cards/HeroCard";
import getDeviceType from "../../util/deviceType";
import "./hero.css";
import BecomeMember from "../BecomeMember";
import axios from "axios";

const { RangePicker } = DatePicker;

const Hero = () => {
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [soldVehicles, setSoldVehicles] = useState([]);
  const [vehiclesCount, setVehiclesCount] = useState(null);
  const [deviceType, setDeviceType] = useState("Unknown");
  const [searchQuery, setSearchQuery] = useState({
    // fromAmount: null,
    // toAmount: null,
    fromYear: null,
    toYear: null,
    make: "",
    model: "",
  });

  const handleSearchChange = (e) => {
    setSearchQuery({ ...searchQuery, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    const queryString = new URLSearchParams();

    // Add each key-value pair to the query string
    for (const [key, value] of Object.entries(searchQuery)) {
      queryString.append(key, value);
    }

    // Get the final query string
    const finalQueryString = queryString.toString();

    // Navigate to the other screen with the searchQuery as a query parameter
    navigate(`/browse-vehicles?${finalQueryString}`);
  };
  const fetchVehicles = async (guest) => {
    const token = localStorage.getItem("token");

    setTimeout(async () => {
      try {
        const response = await axios.get(
          !token ? `${baseURL}vehicles/home-guest` : `${baseURL}vehicles/home`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ); // Replace with your API endpoint
        setVehicles(response.data.vehicles);
        setVehiclesCount(response.data.vehiclesCount);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    }, 500);
  };

  const fetchSoldVehicles = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(`${baseURL}vehicles/sold`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSoldVehicles(response.data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  useEffect(() => {
    setDeviceType(getDeviceType(deviceType));
  }, []);

  useEffect(() => {
    fetchVehicles();
    fetchSoldVehicles();
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      {/* hero */}
      <div className="hero-section">
</div>
 <BecomeMember></BecomeMember>
      <div className="frosted-bar">
        <div className="title text-center mb-2">FIND YOUR NEXT CAR</div>
        <div className="filter-feilds">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    value={searchQuery.make}
                    type="text"
                    onChange={handleSearchChange}
                    name="make"
                    className="form-control fc"
                    placeholder="Make"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    value={searchQuery.model}
                    type="text"
                    onChange={handleSearchChange}
                    name="model"
                    className="form-control fc"
                    placeholder="Model"
                  />
                </div>
              </div>
              {/* <div className="col-md-3">
                <div className="form-group">
                  <input
                    value={searchQuery.fromAmount}
                    type="number"
                    min={0}
                    name="fromAmount"
                    onChange={handleSearchChange}
                    className="form-control fc"
                    placeholder="Price (from)"
                  />
                </div>
              </div> */}
              {/* <div className="col-md-3">
                <div className="form-group">
                  <input
                    value={searchQuery.toAmount}
                    type="number"
                    min={0}
                    name="toAmount"
                    onChange={handleSearchChange}
                    className="form-control fc"
                    placeholder="Price (to)"
                  />
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="form-group">
                  <RangePicker
                    style={{ height: "50px" }}
                    picker="year"
                    onChange={(e) =>
                      setSearchQuery({
                        ...searchQuery,
                        fromYear:
                          e?.length >= 1 ? new Date(e[0]).getFullYear() : "",
                        toYear:
                          e?.length === 2 ? new Date(e[1]).getFullYear() : "",
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center" style={{ margin: "auto" }}>
          <button
            onClick={handleSearch}
            className="btn btn-theme-fill rounded mb-4 px-2"
          >
            Find It Now!
          </button>
        </div>
      </div>
      <div className="sec-pad">
        <div className="custom-container">
          <div className="row">
            <div className="hero-card-container">
              <h3 className="h2-title bold mb-3 mt-4">OUR NEWEST LISTINGS</h3>
              <div>
                {vehicles.map((vehicle, i) => (
                  <div
                    className={
                      deviceType === "iPhone/iOS"
                        ? "heroCardBoxOnDMobile"
                        : "heroCardBoxOnDesktop"
                    }
                  >
                    <HeroCard
                      deviceType={deviceType}
                      id={vehicle._id}
                      noOfLike={vehicle.likes}
                      isLiked={vehicle.isLiked}
                      title={[vehicle.year, vehicle.make, vehicle.model].join(
                        " "
                      )}
                      price={vehicle.price}
                      year={vehicle.year}
                      noOfImage={vehicle.noOfImage}
                      location={vehicle.location}
                      isThumbnail={vehicle?.carImagesId?.thumbnail || false}
                      oldImage={vehicle?.carImagesId?.images[0]}
                      thumbnail={vehicle?.carImagesId?.thumbnail}
                    />
                  </div>
                ))}
              </div>

              <div
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 0",
                }}
              >
                <div
                  style={{
                    background:
                      "transparent linear-gradient(90deg, #0a2b5c 0%, #43679f 100%) 0% 0% no-repeat padding-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fbfdff",
                    borderRadius: "7px",
                    padding: "15px 20px",
                  }}
                >
                  <NavLink to={"browse-vehicles"}>
                    View All {vehiclesCount} Vehicles
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="hero-vedio-container" style={{}}>
              <h3 className="h2-title bold mb-3 mt-4">RECENT VIDEOS</h3>
              <div
                className="elfsight-app-224be465-79ea-490d-8ef2-d120c5227a91"
                data-elfsight-app-lazy
              ></div>
            </div>

            {/* {vehicles.length >= 6 && (
              <div
                className="col-4"
                style={{
                  background:
                    "transparent linear-gradient(90deg, #0a2b5c 0%, #43679f 100%) 0% 0% no-repeat padding-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fbfdff",
                  borderRadius: "7px",
                }}
              >
              
              </div>
            )} */}
          </div>
          <div className="row">
            <h3 className="h2-title bold mb-3 mt-4">RECENTLY SOLD VEHICLES</h3>
            <div className="container">
              <div className="row">
                {soldVehicles.length > 0 && (
                  <>
                    {soldVehicles.slice(0, 4).map((vehicle, i) => (
                      <div className="col-12 col-lg-3 col-md-6 g-4">
                        <SoldVehiclesCard
                          id={vehicle._id}
                          title={[
                            vehicle.year,
                            vehicle.make,
                            vehicle.model,
                          ].join(" ")}
                          price={vehicle.price}
                          // thumbnail={vehicle?.carImagesId?.images[0]}
                          isThumbnail={vehicle?.carImagesId?.thumbnail || false}
                          thumbnail={vehicle.carImagesId.images[0]}
                          oldImage={vehicle?.carImagesId?.images[0]}
                          newThumbnail={vehicle?.carImagesId?.thumbnail}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "40px",
                }}
              >
                <div
                  style={{
                    background:
                      "linear-gradient(90deg, #6e6f70 0%, #9b9c9d 100%) 0% 0% no-repeat padding-box padding-box transparent",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fbfdff",
                    borderRadius: "7px",
                    padding: "15px 20px",
                  }}
                >
                  <NavLink to="/all-sold-vehicles">
                    View All Sold Vehicles
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div className="row g-3">
                {vehicles.length > 0 && (
                  <>

                    {vehicles.slice(0, 5).map((vehicle) => (
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <HeroCard
                          id={vehicle?._id}
                          noOfLike={vehicle.likes}
                          isLiked={vehicle.isLiked}
                          title={vehicle.title}
                          price={vehicle.price}
                          year={vehicle.year}
                          noOfImage={vehicle.noOfImage}
                          location={vehicle.location}
                          thumbnail={vehicle.carImagesId.images[0]}
                        />
                      </div>
                    ))}

                    {vehicles.length >= 6 && (
                      <div
                        className="col-4"
                        style={{
                          background:
                            "transparent linear-gradient(90deg, #0a2b5c 0%, #43679f 100%) 0% 0% no-repeat padding-box",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fbfdff",
                          borderRadius: "7px",
                        }}
                      >
                        <div>
                          <NavLink to={"browse-vehicles"}>
                            View All {vehicles?.length} Vehicles
                          </NavLink>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mt-5 mt-lg-0">
              {videos.length > 0 && (
                <>
                  <h3 className="h3-title bold">Our Most Recent Video!</h3>
                  <div className="row row-cols-1 row-cols-md-1 g-4">
                    {videos?.slice(0, 1).map((video) => (
                      <VideoCard video={video} />
                    ))}
                    <div className="row mt-2">
                      <div className="text-center col-12">
                        <NavLink
                          to="/video"
                          className="btn btn-theme-fill btn-small"
                        >
                          View All Videos
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {soldVehicles.length > 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h3 className="h3-title bold mt-3">Most Recently Sold!</h3>
                    <div>
                      <NavLink
                        to="/recent-sold-vehicle"
                        className="btn btn-theme-fill btn-small"
                      >
                        View All
                      </NavLink>
                    </div>
                  </div>
                  <div className="row row-cols-1 row-cols-md-1 g-4">
                    {soldVehicles?.slice(0, 1).map((vehicle) => (
                      <SoldVehiclesCard
                        title={vehicle.title}
                        thumbnail={vehicle.carImagesId.images[0]}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Hero;
